// react
import React, { useState, useEffect, useCallback } from "react";

// router
import { useNavigate } from "react-router-dom";

// material
import { Box, Toolbar, Hidden, Button, Badge, Tooltip } from "@mui/material";

// icons
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import AddIcon from "@mui/icons-material/Add";
import CakeIcon from "@mui/icons-material/Cake";
import NotificationsIcon from "@mui/icons-material/Notifications";
import DashboardIcon from "@mui/icons-material/Dashboard";

// components
import { Avatar } from "../../Avatar";
import { AppBar } from "../styles";
import { ContentPermission } from "../../Content/Permission";

// util
import util from "../util";

export const AppBarMain = (props) => {
  // router
  const navigate = useNavigate();

  // props
  const { action, toggle, profile } = props;

  // states
  const [permissoes, setPermissoes] = useState([]);

  // effects
  const load = useCallback(async () => {
    let unmounted = false;

    await util.UserInfo().then(async (userResponse) => {
      let { result } = await userResponse;

      if (result.id_profissional) {
        const { id_profissional } = result;

        await util
          .PermissoesProfissional(id_profissional)
          .then(async (response) => {
            let { result } = response;

            if (!unmounted) {
              setPermissoes(result);
            }
          });
      }
    });

    return () => (unmounted = true);
  }, []);

  useEffect(() => {
    let unmounted = false;

    if (!unmounted) load();

    return () => (unmounted = true);
  }, [load]);

  // redirect
  const goHome = () => {
    navigate(`/inicio`);
  };

  const goNotifications = () => {
    navigate(`/residente/notificacoes`);
  };

  const goBirthday = () => {
    navigate(`/residente/aniversario`);
  };

  const goSettings = () => {
    navigate(`/configuracoes`);
  };

  return (
    <AppBar open={true} position="relative" color="inherit">
      <Toolbar>
        <Box flexGrow={1}>
          <Hidden smDown>
            <Button
              color="primary"
              variant="text"
              onClick={action}
              startIcon={<AddIcon />}
            >
              Adicionar
            </Button>
          </Hidden>
        </Box>

        {profile ? (
          <Hidden smDown>
            <Tooltip title="Início" placement="bottom">
              <IconButton
                aria-label="show checks"
                sx={{ mr: 3 }}
                onClick={goHome}
              >
                <Badge badgeContent={0} color="warning">
                  <DashboardIcon />
                </Badge>
              </IconButton>
            </Tooltip>

            <ContentPermission
              authorization="6fbf3df28cc4"
              accept_manager={true}
              permissions={permissoes}
            >
              <Tooltip title="Notificações" placement="bottom">
                <IconButton
                  onClick={goNotifications}
                  aria-label="show notifications"
                  sx={{ mr: 3 }}
                >
                  <Badge badgeContent={0} color="error">
                    <NotificationsIcon />
                  </Badge>
                </IconButton>
              </Tooltip>
            </ContentPermission>

            <ContentPermission
              authorization="6fbf3df28cc5"
              accept_manager={true}
              permissions={permissoes}
            >
              <Tooltip title="Aniversariantes" placement="bottom">
                <IconButton
                  aria-label="show checks"
                  sx={{ mr: 4 }}
                  onClick={goBirthday}
                >
                  <Badge badgeContent={0} color="warning">
                    <CakeIcon />
                  </Badge>
                </IconButton>
              </Tooltip>
            </ContentPermission>

            <Tooltip title={profile.nome} placement="bottom">
              <Box onClick={goSettings} sx={{ cursor: "pointer", mr: 3 }}>
                <Avatar name={profile.nome} picture={profile.foto} />
              </Box>
            </Tooltip>
          </Hidden>
        ) : (
          <React.Fragment></React.Fragment>
        )}

        <Hidden smUp>
          <Box>
            <IconButton onClick={toggle} edge="start" sx={{ mr: 1, ml: 0 }}>
              <MenuIcon />
            </IconButton>
          </Box>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};
