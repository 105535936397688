// react
import React, { useState, useEffect, useCallback } from "react";

// router
import { useNavigate } from "react-router-dom";

// material
import {
  Box,
  Avatar,
  Typography,
  Divider,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";

// icons
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import VaccinesIcon from "@mui/icons-material/Vaccines";
import InventoryIcon from "@mui/icons-material/Inventory";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";

// util
import util from "../util";
import util_permission from "../../../../util/allowed";

export const AddDialog = (props) => {
  // router
  const navigate = useNavigate();

  // states
  const [itemsMain, setItemsMain] = useState(null);

  // effects
  const load = useCallback(async () => {
    let unmounted = false;

    let result_user_data = util.UserData();

    let menu = [];

    if (result_user_data && result_user_data.gestor) {
      menu.push(
        {
          slug: "residente",
          label: "Residente",
          icon: PersonAddIcon,
        },
        {
          slug: "profissional",
          label: "Profissional",
          icon: GroupAddIcon,
        },
        {
          slug: "unidade",
          label: "Unidade",
          icon: HomeWorkIcon,
        },
        {
          slug: "medicamento",
          label: "Medicamento",
          icon: VaccinesIcon,
        },
        {
          slug: "insumo",
          label: "Insumo",
          icon: InventoryIcon,
        },
        {
          slug: "relatorio",
          label: "Relatório",
          icon: LeaderboardIcon,
        }
      );
    } else {
      await util.UserInfo().then(async (user_response) => {
        let { result } = await user_response;

        if (result.id_profissional) {
          const { id_profissional } = result;

          await util
            .PermissoesProfissional(id_profissional)
            .then(async (response) => {
              let { result } = await response;

              if (!unmounted) {
                if (util_permission.allowed("6fbf3df28cc1", true, result)) {
                  menu.push({
                    slug: "residente",
                    label: "Residente",
                    icon: PersonAddIcon,
                  });
                }

                if (util_permission.allowed("6fbf3df28c34", true, result)) {
                  menu.push({
                    slug: "profissional",
                    label: "Profissional",
                    icon: GroupAddIcon,
                  });
                }

                if (util_permission.allowed("6fbf3df28c38", true, result)) {
                  menu.push({
                    slug: "unidade",
                    label: "Unidade",
                    icon: HomeWorkIcon,
                  });
                }

                if (util_permission.allowed("6fbf3df28c42", true, result)) {
                  menu.push({
                    slug: "medicamento",
                    label: "Medicamento",
                    icon: VaccinesIcon,
                  });
                }

                if (util_permission.allowed("6fbf3df28c46", true, result)) {
                  menu.push({
                    slug: "insumo",
                    label: "Insumo",
                    icon: InventoryIcon,
                  });
                }

                if (util_permission.allowed("6fbf3df28c50", true, result)) {
                  menu.push({
                    slug: "relatorio",
                    label: "Relatório",
                    icon: LeaderboardIcon,
                  });
                }
              }
            });
        }
      });
    }

    setItemsMain(menu);

    return () => (unmounted = true);
  }, []);

  useEffect(() => {
    let unmounted = false;

    if (!unmounted) load();

    return () => (unmounted = true);
  }, [load]);

  // props
  const { onClose, open } = props;

  // functions
  const handleClose = () => {
    onClose(true);
  };

  // redirect
  const goPage = (page) => {
    let url = "";

    let residencia = ["profissional", "unidade", "medicamento", "insumo"];

    url =
      residencia.indexOf(page) > -1
        ? `/residencia/${page}/novo`
        : `/${page}/novo`;

    navigate(url);
    onClose(true);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Novo...</DialogTitle>
      <Divider />
      <List sx={{ py: 2, width: "100%", minWidth: "285px" }}>
        {itemsMain ? (
          itemsMain.map(function (item, index) {
            return (
              <ListItem
                button
                autoFocus
                onClick={() => goPage(`${item.slug}`)}
                key={index}
              >
                <ListItemAvatar>
                  <Avatar
                    sx={{
                      bgcolor: (theme) => theme.palette.primary.dark,
                    }}
                  >
                    <item.icon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={item.label} />
              </ListItem>
            );
          })
        ) : (
          <React.Fragment></React.Fragment>
        )}
      </List>
      <Divider />
      <Box textAlign="center" my={2}>
        <Typography
          onClick={handleClose}
          variant="body1"
          sx={{ cursor: "pointer" }}
        >
          Voltar
        </Typography>
      </Box>
    </Dialog>
  );
};
