// react
import React, { useState, useEffect, useCallback } from "react";

// router
// import { useNavigate } from "react-router-dom";

// material ui
import { Box, Paper, InputBase, Divider, Typography } from "@mui/material";

// icons
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";

// components
import { LoadingSquare } from "../../../components/app/Loading/Square";
import { Sidebar } from "../../../components/app/Sidebar";
import { ContentPaper } from "../../../components/app/Content/Paper";
import { ContentMain } from "../../../components/app/Content/Main";

// assets
import imgNoTask from "../../../assets/img/no-task.png";

export const Notifications = () => {
  // router
  // const navigate = useNavigate();

  // states
  const [row, setRow] = useState(null);
  const [search, setSearch] = useState("");
  const [tempSearch, setTempSearch] = useState("");
  // const [qtd, setQtd] = useState(0);
  // const [page, setPage] = useState(1);
  // const [limit, setLimit] = useState(25);

  // effects
  const load = useCallback(async () => {
    let unmounted = false;

    setRow(null);

    setTimeout(async () => {
      if (!unmounted) {
        tmpSearch(search);
        setRow([]);
        // setQtd(0);
      }
    }, 500);

    return () => (unmounted = true);
  }, [
    // page,
    // limit,
    search,
  ]);

  useEffect(() => {
    let unmounted = false;

    if (!unmounted) load();

    return () => (unmounted = true);
  }, [
    load,
    // page,
    // limit,
    search,
  ]);

  // functions
  const tmpSearch = (tmp) => {
    return tmp;
  };
  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage + 1);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setLimit(parseInt(event.target.value));

  //   setPage(1);
  // };

  const handleChangeSearch = (event) => {
    setTempSearch(event.target.value);
  };

  const deleteSearch = () => {
    setTempSearch("");
    setSearch("");
    // setPage(1);
  };

  const submitSearch = () => {
    setSearch(tempSearch);
    // setPage(1);
  };

  return (
    <Sidebar>
      {row ? (
        <ContentMain>
          <Box mb={3}>
            <Box
              display="flex"
              sx={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box width="100%">
                <Paper
                  sx={{
                    p: "6px 4px",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Pesquisar..."
                    inputProps={{
                      "aria-label": "Pesquisar...",
                    }}
                    value={tempSearch}
                    onChange={handleChangeSearch}
                    onKeyPress={(ev) => {
                      if (ev.key === "Enter") {
                        ev.preventDefault();
                        submitSearch();
                      }
                    }}
                  />

                  <IconButton
                    onClick={submitSearch}
                    type="submit"
                    sx={{ p: "10px" }}
                    aria-label="search"
                  >
                    <SearchIcon />
                  </IconButton>

                  {tempSearch !== "" ? (
                    <React.Fragment>
                      <Divider
                        sx={{ height: 28, m: 0.5 }}
                        orientation="vertical"
                      />

                      <IconButton
                        onClick={deleteSearch}
                        color="secondary"
                        sx={{ p: "10px" }}
                        aria-label="Delete"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </React.Fragment>
                  ) : (
                    <React.Fragment></React.Fragment>
                  )}
                </Paper>
              </Box>
            </Box>
          </Box>

          <ContentPaper>
            {row.length > 0 ? (
              <React.Fragment></React.Fragment>
            ) : (
              <Box
                py={4}
                px={2}
                textAlign="center"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Box
                    mb={1.5}
                    component="img"
                    src={imgNoTask}
                    sx={{ maxHeight: "70px" }}
                  />

                  <Typography
                    component="h3"
                    variant="h5"
                    fontWeight={600}
                    color="secondary"
                  >
                    Nenhuma notificação encontrada
                  </Typography>
                </Box>
              </Box>
            )}
          </ContentPaper>
        </ContentMain>
      ) : (
        <Box
          px={2}
          textAlign="center"
          sx={{
            height: "calc(100vh - 65px - 38px)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoadingSquare />
        </Box>
      )}
    </Sidebar>
  );
};
