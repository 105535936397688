// components
import { Input, TextArea } from "./Input";
import { Select } from "./Select";
import { File } from "./File";
import { Checkbox } from "./Checkbox";

export const Controls = {
  Input,
  TextArea,
  Select,
  File,
  Checkbox,
};
