// react
import React from "react";

// material
import { Avatar as MuiAvatar } from "@mui/material";

export const Avatar = (props) => {
  const { name, picture, width, height } = props;

  // functions
  const stringToColor = (string) => {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.substr(-2);
    }

    return color;
  };

  const stringAvatar = (name, picture, width, height) => {
    let props = {};

    if (picture) {
      props = {
        src: `${picture}`,
      };
    } else {
      if (name) {
        props = {
          children: `${String(name).split(" ")[0][0].toUpperCase()}`,
          sx: {
            bgcolor: stringToColor(name),
            height: height ? height : "40px",
            width: width ? width : "40px",
          },
        };
      }
    }

    return props;
  };

  return (
    <MuiAvatar
      alt={name}
      {...stringAvatar(name || "", picture, width, height)}
    />
  );
};
