// react
import React from "react";

// material
import { Box } from "@mui/material";

export const FormControl = (props) => {
  const { children, ...other } = props;

  return (
    <Box
      component="form"
      autoComplete="off"
      sx={{
        width: "100%",
      }}
      {...other}
    >
      {children}
    </Box>
  );
};
