// react
import React, { useState, useEffect } from "react";

// util
import util_permission from "../../../../util/allowed";

export const ContentPermission = (props) => {
  const { children } = props;

  const [show, set_show] = useState(false);

  useEffect(() => {
    set_show(
      util_permission.allowed(
        props.authorization,
        props.accept_manager,
        props.permissions
      )
    );
  }, [props.authorization, props.accept_manager, props.permissions]);

  return show === true ? children : <React.Fragment></React.Fragment>;
};
