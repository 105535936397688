// react
import React, { useState, useEffect, useCallback } from "react";

// router
import { useNavigate } from "react-router-dom";

// material
import { Box, Typography, Divider, Grid, Button } from "@mui/material";

// icons
import VideoSettingsIcon from "@mui/icons-material/VideoSettings";

// components
import { Sidebar } from "../../../components/app/Sidebar";
import { ContentMain } from "../../../components/app/Content/Main";
import { ContentPaper } from "../../../components/app/Content/Paper";
import { LoadingSquare } from "../../../components/app/Loading/Square";
import { CardRedirect } from "../../../components/app/Card/Redirect";
import { ContentPermission } from "../../../components/app/Content/Permission";

// util
import util from "./util";

// assets
import imgNoTask from "../../../assets/img/no-task.png";

export const Home = () => {
  // router
  const navigate = useNavigate();

  // states
  const [row, set_row] = useState(null);
  const [qtd_tmp, set_qtd_tmp] = useState(null);
  const [permissoes, setPermissoes] = useState([]);

  // effects
  useEffect(() => {
    setTimeout(async () => {
      set_row([]);
    }, 500);
  }, []);

  useEffect(() => {
    setTimeout(async () => {
      set_qtd_tmp(0);
    }, 2000);
  }, []);

  const load = useCallback(async () => {
    let unmounted = false;

    await util.UserInfo().then(async (userResponse) => {
      let { result } = await userResponse;

      if (result.id_profissional) {
        const { id_profissional } = result;

        await util
          .PermissoesProfissional(id_profissional)
          .then(async (response) => {
            let { result } = response;

            if (!unmounted) {
              setPermissoes(result);
            }
          });
      }
    });

    return () => (unmounted = true);
  }, []);

  useEffect(() => {
    let unmounted = false;

    if (!unmounted) load();

    return () => (unmounted = true);
  }, [load]);

  // redirect
  const goRedirect = (url) => {
    navigate(url);
  };

  return (
    <Sidebar>
      {row ? (
        <ContentMain>
          <ContentPaper>
            <Box textAlign="center" py={3}>
              <Box
                mb={1.5}
                component="img"
                src={imgNoTask}
                sx={{ maxHeight: "70px" }}
              />
              <Typography
                component="h1"
                variant="h4"
                fontWeight={600}
                color="secondary"
                sx={{ mb: 2 }}
              >
                Bem vindo(a)
              </Typography>
              <Typography
                component="h2"
                variant="h6"
                fontWeight={400}
                color="secondary"
                sx={{ mb: 3 }}
              >
                Pronto para começar?
              </Typography>
              <Button
                type="button"
                onClick={() => goRedirect("/treinamento")}
                color="primary"
                variant="contained"
                startIcon={<VideoSettingsIcon />}
                sx={{
                  whiteSpace: "nowrap",
                  fontWeight: 400,
                  fontSize: "11pt",
                  textTransform: "inherit",
                  boxShadow: "none",
                }}
              >
                Conheça nossos treinamentos
              </Button>
            </Box>

            <Divider sx={{ py: 2 }} />

            <Box py={4}>
              <Grid container spacing={2}>
                <ContentPermission
                  authorization="6fbf3df28cc0"
                  accept_manager={true}
                  permissions={permissoes}
                >
                  <Grid item xs={12} md={4} lg={3}>
                    <CardRedirect
                      title="Residentes"
                      qtd={qtd_tmp}
                      redirect="/residente"
                    />
                  </Grid>
                </ContentPermission>

                <ContentPermission
                  authorization="6fbf3df28cc4"
                  accept_manager={true}
                  permissions={permissoes}
                >
                  <Grid item xs={12} md={4} lg={3}>
                    <CardRedirect
                      title="Alertas"
                      qtd={qtd_tmp}
                      redirect="/residente/notificacoes"
                    />
                  </Grid>
                </ContentPermission>

                <ContentPermission
                  authorization="6fbf3df28cc5"
                  accept_manager={true}
                  permissions={permissoes}
                >
                  <Grid item xs={12} md={4} lg={3}>
                    <CardRedirect
                      title="Aniversariantes"
                      qtd={qtd_tmp}
                      redirect="/residente/aniversario"
                    />
                  </Grid>
                </ContentPermission>

                <ContentPermission
                  authorization="6fbf3df28c29"
                  accept_manager={true}
                  permissions={permissoes}
                >
                  <Grid item xs={12} md={4} lg={3}>
                    <CardRedirect
                      title="Ocupações"
                      qtd={qtd_tmp}
                      redirect="/residencia?type=apps-occupation"
                    />
                  </Grid>
                </ContentPermission>

                <ContentPermission
                  authorization="6fbf3df28c33"
                  accept_manager={true}
                  permissions={permissoes}
                >
                  <Grid item xs={12} md={4} lg={3}>
                    <CardRedirect
                      title="Funcionários"
                      qtd={qtd_tmp}
                      redirect="/residencia?type=apps-employee"
                    />
                  </Grid>
                </ContentPermission>

                <ContentPermission
                  authorization="6fbf3df28c37"
                  accept_manager={true}
                  permissions={permissoes}
                >
                  <Grid item xs={12} md={4} lg={3}>
                    <CardRedirect
                      title="Unidades"
                      qtd={qtd_tmp}
                      redirect="/residencia?type=apps-unit"
                    />
                  </Grid>
                </ContentPermission>

                <ContentPermission
                  authorization="6fbf3df28c41"
                  accept_manager={true}
                  permissions={permissoes}
                >
                  <Grid item xs={12} md={4} lg={3}>
                    <CardRedirect
                      title="Medicamentos"
                      qtd={qtd_tmp}
                      redirect="/residencia?type=apps-medicament"
                    />
                  </Grid>
                </ContentPermission>

                <ContentPermission
                  authorization="6fbf3df28c45"
                  accept_manager={true}
                  permissions={permissoes}
                >
                  <Grid item xs={12} md={4} lg={3}>
                    <CardRedirect
                      title="Insumos"
                      qtd={qtd_tmp}
                      redirect="/residencia?type=apps-product"
                    />
                  </Grid>
                </ContentPermission>

                <ContentPermission
                  authorization="6fbf3df28c49"
                  accept_manager={true}
                  permissions={permissoes}
                >
                  <Grid item xs={12} md={4} lg={3}>
                    <CardRedirect
                      title="Relatórios"
                      qtd={qtd_tmp}
                      redirect="/relatorio"
                    />
                  </Grid>
                </ContentPermission>
              </Grid>
            </Box>
          </ContentPaper>
        </ContentMain>
      ) : (
        <Box
          px={2}
          textAlign="center"
          sx={{
            height: "calc(100vh - 65px - 38px)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoadingSquare />
        </Box>
      )}
    </Sidebar>
  );
};
