// material ui
import { styled } from "@mui/material/styles";

import { Select as MuiSelect } from "@mui/material/";
import { TextField as MuiTextField } from "@mui/material/";

export const Label = styled("label")(({ theme }) => ({
  fontSize: "11pt",
  fontWeight: 400,
  lineHeight: 1.5,
  marginBottom: "0.25rem",
  display: "block",

  "&.thumbnail": {
    borderRadius: ".5rem !important",
    border: `1px dashed #ced4da`,
    backgroundSize: "cover",
    cursor: "pointer",
    height: "180px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "1.75rem",
  },

  "&.thumbnail input": {
    display: "none",
  },
}));

export const InputField = styled("input")(({ theme }) => ({
  padding: "0.575rem 1.25rem",
  fontSize: "12pt",
  fontWeight: 400,
  lineHeight: 1.5,

  borderRadius: "30px !important",
  border: `1px solid #ced4da`,

  width: "100%",
  display: "block",

  outline: "none !important",
}));

export const InputFile = styled("input")(({ theme }) => ({}));

export const SelectField = styled(MuiSelect)(({ theme }) => ({
  padding: "0.575rem 1.25rem",
  fontSize: "12pt",
  fontWeight: 400,
  lineHeight: 1.5,

  borderRadius: "30px !important",
  outline: "none !important",

  "& .MuiSelect-select": {
    padding: 0,
  },

  "& .MuiOutlinedInput-notchedOutline": {
    border: `1px solid #ced4da !important`,
  },
}));

export const TextAreaField = styled(MuiTextField)(({ theme }) => ({
  fontSize: "12pt",
  fontWeight: 400,
  lineHeight: 1.5,

  width: "100%",
  display: "block",

  outline: "none !important",

  "& .MuiOutlinedInput-notchedOutline": {
    borderRadius: "10px !important",
    border: `1px solid #ced4da !important`,
  },
}));
