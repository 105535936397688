// react
import React, { useState } from "react";

// router
import { useSearchParams, useNavigate } from "react-router-dom";

// material
import { Box, Typography, Grid, Hidden, Alert, Tooltip } from "@mui/material";

// icons
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

// components
import { Sidebar } from "../../../../../../components/app/Sidebar";
import { TitleMain } from "../../../../../../components/app/Title/Main";
import { FormControl } from "../../../../../../components/app/Form/FormControl";
import { Controls } from "../../../../../../components/app/Form/Controls";
import { ContentMain } from "../../../../../../components/app/Content/Main";
import { ContentPaper } from "../../../../../../components/app/Content/Paper";

// util
// import util from "../util";

import { tipo_sanguineo_itens } from "../../../../../../util/itens";

// style
import { StepForm } from "../../../../../../components/app/Step/Form";

const steps = ["Anamnese"];

export const CreateResidentAnamnese = () => {
  // router
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  // state
  const [messageError, setMessageError] = useState("");

  // state-form
  const [data, set_data] = useState(new Date().toISOString().substring(0, 10));
  const [fuma, set_fuma] = useState(false);
  const [fuma_detalhes, set_fuma_detalhes] = useState("");
  const [bebe, set_bebe] = useState(false);
  const [bebe_detalhes, set_bebe_detalhes] = useState("");
  const [pratica_atividades, set_pratica_atividades] = useState(false);
  const [pratica_atividades_detalhes, set_pratica_atividades_detalhes] =
    useState("");
  const [pratica_atividades_detalhes_qtd, set_pratica_atividades_detalhes_qtd] =
    useState("");
  const [doador_de_sangue, set_doador_de_sangue] = useState(false);
  const [tipo_sanguineo, set_tipo_sanguineo] = useState("");
  const [fez_cirurgia, set_fez_cirurgia] = useState(false);
  const [fez_cirurgia_detalhes, set_fez_cirurgia_detalhes] = useState("");
  const [problema_de_saude, set_problema_de_saude] = useState(false);
  const [problema_de_saude_detalhes, set_problema_de_saude_detalhes] =
    useState("");
  const [medicacao_continua, set_medicacao_continua] = useState(false);
  const [medicacao_continua_detalhes, set_medicacao_continua_detalhes] =
    useState("");
  const [alergia, set_alergia] = useState(false);
  const [alergia_detalhes, set_alergia_detalhes] = useState("");
  const [queixa_de_saude, set_queixa_de_saude] = useState(false);
  const [queixa_de_saude_detalhes, set_queixa_de_saude_detalhes] = useState("");
  const [antecedente_medico, set_antecedente_medico] = useState(false);
  const [antecedente_medico_detalhes, set_antecedente_medico_detalhes] =
    useState("");
  const [observacoes, set_observacoes] = useState("");

  // state-step
  const [activeStep, setActiveStep] = useState(0);
  const [actionStepButton, setActionStepButton] = useState(false);

  // functions
  const handleSubmit = async (event) => {
    event.preventDefault();

    setActionStepButton(true);
    setMessageError("");

    document.getElementById("page").scrollTo(0, 0);

    if (validate()) {
      // await util
      //   .Create({
      //     // produto
      //     titulo: titulo,
      //     qtd: qtd,
      //     dt_reposicao: dt_reposicao,
      //     observacoes: observacoes,
      //   })
      //   .catch((error) => {
      //     setActionStepButton(false);
      //     setMessageError("Ocorreu um erro ao salvar.");
      //   })
      //   .finally(() => {
      goList();
      //   });
    } else {
      setActionStepButton(false);
      setMessageError("Por favor, corrija os campos em vermelho.");
    }
  };

  const validate = () => {
    return true;
  };

  // redirect
  const goList = () => {
    const id_residente = searchParams.get("residente");

    navigate(`/residente/preview/${id_residente}?type=apps-medical-records`);
  };

  const goRefresh = () => {
    window.location.reload();
  };

  // components
  const MenuIcons = () => {
    return (
      <Box my={2}>
        <Tooltip title="Voltar">
          <IconButton aria-label="voltar" size="large" onClick={goList}>
            <ArrowBackIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title="Salvar">
          <IconButton aria-label="salvar" size="large" onClick={handleSubmit}>
            <SaveIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title="Limpar formulário">
          <IconButton aria-label="limpar" size="large" onClick={goRefresh}>
            <RestartAltIcon />
          </IconButton>
        </Tooltip>
      </Box>
    );
  };

  return (
    <Sidebar>
      <ContentMain>
        <Hidden mdUp>
          <Box textAlign="end" mb={3}>
            <MenuIcons />
          </Box>
        </Hidden>

        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <ContentPaper>
              <Box sx={{ width: "100%" }} mt={5}>
                <StepForm
                  steps={steps}
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                  action={handleSubmit}
                  actionLabel="Salvar"
                  actionStepButton={actionStepButton}
                >
                  <FormControl>
                    {activeStep === 0 ? (
                      <Grid
                        container
                        spacing={1}
                        sx={{
                          paddingBottom: 4,
                        }}
                      >
                        <Grid item xs={12} sx={{ mb: 2 }}>
                          <Controls.Input
                            label="Data do registro"
                            id="data"
                            name="data"
                            type="date"
                            value={data}
                            onChange={(event) => set_data(event.target.value)}
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Controls.Checkbox
                            label="O(A) residente fuma?"
                            name="fuma"
                            value={fuma}
                            onChange={(event) => set_fuma(event.target.value)}
                          />
                        </Grid>

                        {fuma ? (
                          <Grid item xs={12}>
                            <Controls.Input
                              label="Quantos cigarros por dia? (frequência de uso)"
                              placeholder="..."
                              id="fuma_detalhes"
                              name="fuma_detalhes"
                              type="text"
                              length={150}
                              value={fuma_detalhes}
                              onChange={(event) =>
                                set_fuma_detalhes(event.target.value)
                              }
                              style={{ marginBottom: "1.25rem" }}
                            />
                          </Grid>
                        ) : (
                          <React.Fragment></React.Fragment>
                        )}

                        <Grid item xs={12}>
                          <Controls.Checkbox
                            label="O(A) residente bebe?"
                            name="bebe"
                            value={bebe}
                            onChange={(event) => set_bebe(event.target.value)}
                          />
                        </Grid>

                        {bebe ? (
                          <Grid item xs={12}>
                            <Controls.Input
                              label="Quantas vezes por semana? (frequência)"
                              placeholder="..."
                              id="bebe_detalhes"
                              name="bebe_detalhes"
                              type="text"
                              length={150}
                              value={bebe_detalhes}
                              onChange={(event) =>
                                set_bebe_detalhes(event.target.value)
                              }
                              style={{ marginBottom: "1.25rem" }}
                            />
                          </Grid>
                        ) : (
                          <React.Fragment></React.Fragment>
                        )}

                        <Grid item xs={12}>
                          <Controls.Checkbox
                            label="Pratica atividade física?"
                            name="pratica_atividades"
                            value={pratica_atividades}
                            onChange={(event) =>
                              set_pratica_atividades(event.target.value)
                            }
                          />
                        </Grid>

                        {pratica_atividades ? (
                          <React.Fragment>
                            <Grid item xs={12}>
                              <Controls.Input
                                label="Qual atividade?"
                                placeholder="..."
                                id="pratica_atividades_detalhes"
                                name="pratica_atividades_detalhes"
                                type="text"
                                length={150}
                                value={pratica_atividades_detalhes}
                                onChange={(event) =>
                                  set_pratica_atividades_detalhes(
                                    event.target.value
                                  )
                                }
                                style={{ marginBottom: "1.25rem" }}
                              />
                            </Grid>

                            <Grid item xs={12}>
                              <Controls.Input
                                label="Quantas vezes por semana? (frequência)"
                                placeholder="..."
                                id="pratica_atividades_detalhes_qtd"
                                name="pratica_atividades_detalhes_qtd"
                                type="text"
                                length={150}
                                value={pratica_atividades_detalhes_qtd}
                                onChange={(event) =>
                                  set_pratica_atividades_detalhes_qtd(
                                    event.target.value
                                  )
                                }
                                style={{ marginBottom: "1.25rem" }}
                              />
                            </Grid>
                          </React.Fragment>
                        ) : (
                          <React.Fragment></React.Fragment>
                        )}

                        <Grid item xs={12}>
                          <Controls.Checkbox
                            label="Já fez alguma cirurgia?"
                            name="fez_cirurgia"
                            value={fez_cirurgia}
                            onChange={(event) =>
                              set_fez_cirurgia(event.target.value)
                            }
                          />
                        </Grid>

                        {fez_cirurgia ? (
                          <Grid item xs={12}>
                            <Controls.Input
                              label="Qual cirurgia foi realizada?"
                              placeholder="..."
                              id="fez_cirurgia_detalhes"
                              name="fez_cirurgia_detalhes"
                              type="text"
                              length={150}
                              value={fez_cirurgia_detalhes}
                              onChange={(event) =>
                                set_fez_cirurgia_detalhes(event.target.value)
                              }
                              style={{ marginBottom: "1.25rem" }}
                            />
                          </Grid>
                        ) : (
                          <React.Fragment></React.Fragment>
                        )}

                        <Grid item xs={12}>
                          <Controls.Checkbox
                            label="Tem algum problema de saúde?"
                            name="problema_de_saude"
                            value={problema_de_saude}
                            onChange={(event) =>
                              set_problema_de_saude(event.target.value)
                            }
                          />
                        </Grid>

                        {problema_de_saude ? (
                          <Grid item xs={12}>
                            <Controls.Input
                              label="Qual problema?"
                              placeholder="..."
                              id="problema_de_saude_detalhes"
                              name="problema_de_saude_detalhes"
                              type="text"
                              length={150}
                              value={problema_de_saude_detalhes}
                              onChange={(event) =>
                                set_problema_de_saude_detalhes(
                                  event.target.value
                                )
                              }
                              style={{ marginBottom: "1.25rem" }}
                            />
                          </Grid>
                        ) : (
                          <React.Fragment></React.Fragment>
                        )}

                        <Grid item xs={12}>
                          <Controls.Checkbox
                            label="Toma alguma medicação de uso contínuo?"
                            name="medicacao_continua"
                            value={medicacao_continua}
                            onChange={(event) =>
                              set_medicacao_continua(event.target.value)
                            }
                          />
                        </Grid>

                        {medicacao_continua ? (
                          <Grid item xs={12}>
                            <Controls.Input
                              label="Qual medicação?"
                              placeholder="..."
                              id="medicacao_continua_detalhes"
                              name="medicacao_continua_detalhes"
                              type="text"
                              length={150}
                              value={medicacao_continua_detalhes}
                              onChange={(event) =>
                                set_medicacao_continua_detalhes(
                                  event.target.value
                                )
                              }
                              style={{ marginBottom: "1.25rem" }}
                            />
                          </Grid>
                        ) : (
                          <React.Fragment></React.Fragment>
                        )}

                        <Grid item xs={12}>
                          <Controls.Checkbox
                            label="Tem alguma alergia?"
                            name="alergia"
                            value={alergia}
                            onChange={(event) =>
                              set_alergia(event.target.value)
                            }
                          />
                        </Grid>

                        {alergia ? (
                          <Grid item xs={12}>
                            <Controls.Input
                              label="Qual alergia?"
                              placeholder="..."
                              id="alergia_detalhes"
                              name="alergia_detalhes"
                              type="text"
                              length={150}
                              value={alergia_detalhes}
                              onChange={(event) =>
                                set_alergia_detalhes(event.target.value)
                              }
                              style={{ marginBottom: "1.25rem" }}
                            />
                          </Grid>
                        ) : (
                          <React.Fragment></React.Fragment>
                        )}

                        <Grid item xs={12}>
                          <Controls.Checkbox
                            label="Tem alguma queixa de saúde no momento?"
                            name="queixa_de_saude"
                            value={queixa_de_saude}
                            onChange={(event) =>
                              set_queixa_de_saude(event.target.value)
                            }
                          />
                        </Grid>

                        {queixa_de_saude ? (
                          <Grid item xs={12}>
                            <Controls.Input
                              label="Qual queixa?"
                              placeholder="..."
                              id="queixa_de_saude_detalhes"
                              name="queixa_de_saude_detalhes"
                              type="text"
                              length={150}
                              value={queixa_de_saude_detalhes}
                              onChange={(event) =>
                                set_queixa_de_saude_detalhes(event.target.value)
                              }
                              style={{ marginBottom: "1.25rem" }}
                            />
                          </Grid>
                        ) : (
                          <React.Fragment></React.Fragment>
                        )}

                        <Grid item xs={12}>
                          <Controls.Checkbox
                            label="Tem algum antecedente na família com câncer, pressão alta ou diabetes?"
                            name="antecedente_medico"
                            value={antecedente_medico}
                            onChange={(event) =>
                              set_antecedente_medico(event.target.value)
                            }
                          />
                        </Grid>

                        {antecedente_medico ? (
                          <Grid item xs={12}>
                            <Controls.Input
                              label="Qual antecedente/doença?"
                              placeholder="..."
                              id="antecedente_medico_detalhes"
                              name="antecedente_medico_detalhes"
                              type="text"
                              length={150}
                              value={antecedente_medico_detalhes}
                              onChange={(event) =>
                                set_antecedente_medico_detalhes(
                                  event.target.value
                                )
                              }
                              style={{ marginBottom: "1.25rem" }}
                            />
                          </Grid>
                        ) : (
                          <React.Fragment></React.Fragment>
                        )}

                        <Grid item xs={12}>
                          <Controls.Checkbox
                            label="É doador de sangue?"
                            name="doador_de_sangue"
                            value={doador_de_sangue}
                            onChange={(event) =>
                              set_doador_de_sangue(event.target.value)
                            }
                          />
                        </Grid>

                        <Grid item xs={12} sx={{ mt: 2, mb: 1 }}>
                          <Controls.Select
                            label="Qual o tipo sanguíneo?"
                            placeholder="..."
                            name="tipo_sanguineo"
                            id="tipo_sanguineo"
                            items={tipo_sanguineo_itens}
                            value={tipo_sanguineo}
                            onChange={(event) =>
                              set_tipo_sanguineo(event.target.value)
                            }
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Controls.TextArea
                            label="Observações"
                            placeholder="..."
                            id="observacoes"
                            rows={4}
                            name="observacoes"
                            value={observacoes}
                            onChange={(event) =>
                              set_observacoes(event.target.value)
                            }
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>
                      </Grid>
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}
                  </FormControl>
                </StepForm>
              </Box>
            </ContentPaper>
          </Grid>

          <Grid item xs={12} md={4}>
            <Hidden mdDown>
              <Box mt={5}>
                <TitleMain>Cadastrar Anamnese</TitleMain>
                <Typography component="h2" variant="body2">
                  Preencha o formulário para continuar.
                </Typography>

                {messageError ? (
                  <Box
                    sx={{
                      pr: {
                        xs: 2,
                        sm: 4,
                      },
                    }}
                  >
                    <Alert
                      severity="error"
                      sx={{
                        my: 2,
                      }}
                    >
                      {messageError}
                    </Alert>
                  </Box>
                ) : (
                  <React.Fragment></React.Fragment>
                )}

                <MenuIcons />
              </Box>
            </Hidden>
          </Grid>
        </Grid>
      </ContentMain>
    </Sidebar>
  );
};
