import { styled } from "@mui/material/styles";

const PREFIX = "OAuth";

export const classes = {
  banner: `${PREFIX}-banner`,
};

export const Page = styled("div")(({ theme }) => ({
  height: "100vh",
  overflow: "hidden",

  [`.${classes.banner}`]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    backgroundColor: theme.palette.primary.main,
  },
}));
