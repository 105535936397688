// react
import React, { useState } from "react";

// router
import { useSearchParams, useNavigate } from "react-router-dom";

// material
import { Box, Typography, Grid, Hidden, Alert, Tooltip } from "@mui/material";

// icons
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

// components
import { Sidebar } from "../../../../../../components/app/Sidebar";
import { TitleMain } from "../../../../../../components/app/Title/Main";
import { FormControl } from "../../../../../../components/app/Form/FormControl";
import { Controls } from "../../../../../../components/app/Form/Controls";
import { ContentMain } from "../../../../../../components/app/Content/Main";
import { ContentPaper } from "../../../../../../components/app/Content/Paper";

// util
// import util from "../util";

// style
import { StepForm } from "../../../../../../components/app/Step/Form";

const steps = ["Evolução"];

export const CreateResidentEvolution = () => {
  // router
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  // state
  const [messageError, setMessageError] = useState("");

  // state-form
  const [data, set_data] = useState(new Date().toISOString().substring(0, 10));
  const [evolucao, set_evolucao] = useState("");
  const [observacoes, set_observacoes] = useState("");

  // state-step
  const [activeStep, setActiveStep] = useState(0);
  const [actionStepButton, setActionStepButton] = useState(false);

  // functions
  const handleSubmit = async (event) => {
    event.preventDefault();

    setActionStepButton(true);
    setMessageError("");

    document.getElementById("page").scrollTo(0, 0);

    if (validate()) {
      // await util
      //   .Create({
      //     // produto
      //     titulo: titulo,
      //     qtd: qtd,
      //     dt_reposicao: dt_reposicao,
      //     observacoes: observacoes,
      //   })
      //   .catch((error) => {
      //     setActionStepButton(false);
      //     setMessageError("Ocorreu um erro ao salvar.");
      //   })
      //   .finally(() => {
      goList();
      //   });
    } else {
      setActionStepButton(false);
      setMessageError("Por favor, corrija os campos em vermelho.");
    }
  };

  const validate = () => {
    return true;
  };

  // redirect
  const goList = () => {
    const id_residente = searchParams.get("residente");

    navigate(`/residente/preview/${id_residente}?type=apps-medical-records`);
  };

  const goRefresh = () => {
    window.location.reload();
  };

  // components
  const MenuIcons = () => {
    return (
      <Box my={2}>
        <Tooltip title="Voltar">
          <IconButton aria-label="voltar" size="large" onClick={goList}>
            <ArrowBackIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title="Salvar">
          <IconButton aria-label="salvar" size="large" onClick={handleSubmit}>
            <SaveIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title="Limpar formulário">
          <IconButton aria-label="limpar" size="large" onClick={goRefresh}>
            <RestartAltIcon />
          </IconButton>
        </Tooltip>
      </Box>
    );
  };

  return (
    <Sidebar>
      <ContentMain>
        <Hidden mdUp>
          <Box textAlign="end" mb={3}>
            <MenuIcons />
          </Box>
        </Hidden>

        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <ContentPaper>
              <Box sx={{ width: "100%" }} mt={5}>
                <StepForm
                  steps={steps}
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                  action={handleSubmit}
                  actionLabel="Salvar"
                  actionStepButton={actionStepButton}
                >
                  <FormControl>
                    {activeStep === 0 ? (
                      <Grid
                        container
                        spacing={1}
                        sx={{
                          paddingBottom: 4,
                        }}
                      >
                        <Grid item xs={12} sx={{ mb: 2 }}>
                          <Controls.Input
                            label="Data do registro"
                            id="data"
                            name="data"
                            type="date"
                            value={data}
                            onChange={(event) => set_data(event.target.value)}
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Controls.TextArea
                            label="Evolução"
                            placeholder="..."
                            id="evolucao"
                            rows={10}
                            name="evolucao"
                            value={evolucao}
                            onChange={(event) =>
                              set_evolucao(event.target.value)
                            }
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Controls.TextArea
                            label="Observações"
                            placeholder="..."
                            id="observacoes"
                            rows={4}
                            name="observacoes"
                            value={observacoes}
                            onChange={(event) =>
                              set_observacoes(event.target.value)
                            }
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>
                      </Grid>
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}
                  </FormControl>
                </StepForm>
              </Box>
            </ContentPaper>
          </Grid>

          <Grid item xs={12} md={4}>
            <Hidden mdDown>
              <Box mt={5}>
                <TitleMain>Cadastrar sinais vitais</TitleMain>
                <Typography component="h2" variant="body2">
                  Preencha o formulário para continuar.
                </Typography>

                {messageError ? (
                  <Box
                    sx={{
                      pr: {
                        xs: 2,
                        sm: 4,
                      },
                    }}
                  >
                    <Alert
                      severity="error"
                      sx={{
                        my: 2,
                      }}
                    >
                      {messageError}
                    </Alert>
                  </Box>
                ) : (
                  <React.Fragment></React.Fragment>
                )}

                <MenuIcons />
              </Box>
            </Hidden>
          </Grid>
        </Grid>
      </ContentMain>
    </Sidebar>
  );
};
