// react
import React, { useState, useEffect, useCallback } from "react";

// router
import { useNavigate } from "react-router-dom";

// material
import {
  Box,
  Typography,
  Grid,
  Hidden,
  Alert,
  Tooltip,
  Button,
} from "@mui/material";

// icons
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

// components
import { Sidebar } from "../../../../components/app/Sidebar";
import { TitleMain } from "../../../../components/app/Title/Main";
import { FormControl } from "../../../../components/app/Form/FormControl";
import { Controls } from "../../../../components/app/Form/Controls";
import { ContentPaper } from "../../../../components/app/Content/Paper";
import { ContentMain } from "../../../../components/app/Content/Main";
import { StepForm } from "../../../../components/app/Step/Form";

// util
import util from "../util";

// img
import imgFile from "../../../../assets/img/file.png";

const steps = ["Relatório"];

export const CreateReport = () => {
  // router
  const navigate = useNavigate();

  // state
  const [messageError, setMessageError] = useState("");

  // state-form
  const [file, setFile] = useState(null);
  const [titulo, setTitulo] = useState("");
  const [descricao, setDescricao] = useState("");
  const [id_profissional, setId_profissional] = useState("");
  const [id_empresa, setId_empresa] = useState("");
  const [id_unidade, setId_unidade] = useState("");

  // state-util
  const [unidade, setUnidade] = useState([]);
  const [profissional, setProfissional] = useState([]);

  // state-step
  const [activeStep, setActiveStep] = useState(0);
  const [actionStepButton, setActionStepButton] = useState(false);

  const load = useCallback(async () => {
    let unmounted = false;

    try {
      await util.UserInfo().then(async (userResponse) => {
        let { result } = await userResponse;

        if (result.id_gestor) {
          const { id_empresa } = result;

          setId_empresa(id_empresa);

          await util.ListEmployee(id_empresa).then((response) => {
            let result = response;

            if (!unmounted) {
              let objValue = result.length > 0 ? result[0].value : "";

              setId_profissional(objValue);
              setProfissional(result);
            }
          });

          await util.ListUnity(id_empresa).then((response) => {
            let result = response;

            if (!unmounted) {
              let objValue = result.length > 0 ? result[0].value : "";

              setId_unidade(objValue);
              setUnidade(result);
            }
          });
        }
      });
    } catch (error) {
      setMessageError(error.message);
    }

    return () => (unmounted = true);
  }, []);

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) load();

    return () => (unmounted = true);
  }, [load]);

  // functions
  const handleSubmit = async (event) => {
    event.preventDefault();

    setActionStepButton(true);
    setMessageError("");

    document.getElementById("page").scrollTo(0, 0);

    if (validate()) {
      const data = new FormData();

      data.append("titulo", titulo);
      data.append("descricao", descricao);
      data.append("nome_arquivo", file);
      data.append("id_profissional", id_profissional);
      data.append("id_empresa", id_empresa);
      data.append("id_unidade", id_unidade);

      await util
        .Create(data)
        .catch((error) => {
          setActionStepButton(false);
          setMessageError("Ocorreu um erro ao salvar.");
        })
        .finally(() => {
          goList();
        });
    } else {
      setActionStepButton(false);
      setMessageError("Por favor, corrija os campos em vermelho.");
    }
  };

  const validate = () => {
    return true;
  };

  // redirect
  const goList = () => {
    navigate(`/relatorio`);
  };

  const goRefresh = () => {
    window.location.reload();
  };

  // components
  const MenuIcons = () => {
    return (
      <Box my={2}>
        <Tooltip title="Voltar">
          <IconButton aria-label="voltar" size="large" onClick={goList}>
            <ArrowBackIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title="Salvar">
          <IconButton aria-label="salvar" size="large" onClick={handleSubmit}>
            <SaveIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title="Limpar formulário">
          <IconButton aria-label="limpar" size="large" onClick={goRefresh}>
            <RestartAltIcon />
          </IconButton>
        </Tooltip>
      </Box>
    );
  };

  return (
    <Sidebar>
      <ContentMain>
        <Hidden mdUp>
          <Box textAlign="end" mb={3}>
            <MenuIcons />
          </Box>
        </Hidden>

        <Grid container spacing={0}>
          <Grid item xs={12} md={8}>
            <ContentPaper>
              <Box sx={{ width: "100%" }} mt={5}>
                <StepForm
                  steps={steps}
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                  action={handleSubmit}
                  actionLabel="Salvar"
                  actionStepButton={actionStepButton}
                >
                  <FormControl>
                    {activeStep === 0 ? (
                      <Grid
                        container
                        spacing={1}
                        sx={{
                          py: 4,
                        }}
                      >
                        {file ? (
                          <Box
                            py={4}
                            sx={{
                              width: "100%",
                              borderRadius: ".5rem !important",
                              border: `1px dashed #ced4da`,
                              backgroundSize: "cover",
                              cursor: "pointer",
                              height: "180px",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              marginBottom: "1.75rem",
                            }}
                          >
                            <Box component="img" src={imgFile} alt="file" />

                            <Typography fontWeight={500} sx={{ my: 1 }}>
                              {file.name}
                            </Typography>

                            <Button
                              color="error"
                              component="text"
                              onClick={() => setFile(null)}
                            >
                              Remover arquivo
                            </Button>
                          </Box>
                        ) : (
                          <Grid item xs={12}>
                            <Controls.File
                              label="Clique para selecione um arquivo"
                              id="file"
                              accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*"
                              onChange={(event) =>
                                setFile(event.target.files[0])
                              }
                            />
                          </Grid>
                        )}

                        <Grid item xs={12}>
                          <Controls.Input
                            label="Título"
                            placeholder="..."
                            id="titulo"
                            name="titulo"
                            type="text"
                            length={150}
                            value={titulo}
                            onChange={(event) => setTitulo(event.target.value)}
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Controls.TextArea
                            label="Descrição"
                            placeholder="..."
                            id="descricao"
                            rows={8}
                            name="descricao"
                            value={descricao}
                            onChange={(event) =>
                              setDescricao(event.target.value)
                            }
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Controls.Select
                            label="Responsável"
                            placeholder="..."
                            name="id_profissional"
                            id="id_profissional"
                            items={profissional}
                            value={id_profissional}
                            onChange={(event) =>
                              setId_profissional(event.target.value)
                            }
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Controls.Select
                            label="Unidade"
                            placeholder="..."
                            name="id_unidade"
                            id="id_unidade"
                            items={unidade}
                            value={id_unidade}
                            onChange={(event) =>
                              setId_unidade(event.target.value)
                            }
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>
                      </Grid>
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}
                  </FormControl>
                </StepForm>
              </Box>
            </ContentPaper>
          </Grid>

          <Grid item xs={12} md={4} px={4}>
            <Hidden mdDown>
              <Box mt={5}>
                <TitleMain>Novo Relatório</TitleMain>
                <Typography component="h2" variant="body2">
                  Preencha o formulário para continuar.
                </Typography>

                {messageError ? (
                  <Alert
                    severity="error"
                    sx={{
                      my: 2,
                    }}
                  >
                    {messageError}
                  </Alert>
                ) : (
                  <React.Fragment></React.Fragment>
                )}

                <MenuIcons />
              </Box>
            </Hidden>
          </Grid>
        </Grid>
      </ContentMain>
    </Sidebar>
  );
};
