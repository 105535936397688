import React, { createContext, useCallback, useEffect, useState } from "react";

import { Box, CssBaseline, ThemeProvider } from "@mui/material";

import { DarkTheme } from "../../themes/DarkTheme";
import { LightTheme } from "../../themes/LightTheme";

const DEFAULT_THEME = {
  name: "light",
  file: LightTheme,
};

export const ThemeContext = createContext();

export const AppThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(LightTheme);

  // set local theme
  const setThemeLocalStorage = (value) => {
    localStorage.setItem("theme", value);
  };

  // get local theme
  const getThemeLocalStorage = useCallback(() => {
    let defaultTheme = localStorage.getItem("theme");

    if (!defaultTheme) {
      setThemeLocalStorage(DEFAULT_THEME.name);
      return getThemeLocalStorage();
    } else {
      return defaultTheme;
    }
  }, []);

  // update default theme
  const handleTheme = useCallback((themeStyles) => {
    setThemeLocalStorage(themeStyles);

    switch (themeStyles) {
      case "light":
        setTheme(LightTheme);
        break;

      case "dark":
        setTheme(DarkTheme);
        break;

      default:
        setTheme(DEFAULT_THEME.file);
        break;
    }
  }, []);

  // initialize default theme
  useEffect(() => {
    handleTheme(getThemeLocalStorage());
  }, [getThemeLocalStorage, handleTheme]);

  return (
    <React.Fragment>
      {theme ? (
        <ThemeContext.Provider
          value={{
            ...theme,
            handleTheme,
          }}
        >
          <CssBaseline />

          <ThemeProvider theme={theme}>
            <Box
              height="100vh"
              width="100vw"
              overflow="auto"
              bgcolor={theme.palette.background.default}
            >
              {children}
            </Box>
          </ThemeProvider>
        </ThemeContext.Provider>
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </React.Fragment>
  );
};
