// react
import React, { useState, useEffect, useCallback } from "react";

// router
import { useNavigate } from "react-router-dom";

// material
import {
  Box,
  Typography,
  TableRow as MuiTableRow,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  Divider,
  List,
  ListItemText,
  ListItem,
  Avatar,
  ListItemAvatar,
} from "@mui/material";

// Icons
import AppsIcon from "@mui/icons-material/Apps";
import AssignmentIcon from "@mui/icons-material/Assignment";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
import WorkIcon from "@mui/icons-material/Work";
import FaceIcon from "@mui/icons-material/Face";

import util from "../../util";
import util_permission from "../../../../../util/allowed";

// components
import {
  classesTable,
  StylesMuiTableCell as MuiTableCellBody,
} from "../../../../../components/app/Table";

// const data = [

// ];

export const TableRow = (props) => {
  const navigate = useNavigate();

  // props
  const { row } = props;

  // states
  const [open, setOpen] = useState(false);
  const [itemsMain, setItemsMain] = useState(null);

  // effects
  const load = useCallback(async () => {
    let unmounted = false;

    let result_user_data = util.UserData();

    let menu = [];

    if (result_user_data && result_user_data.gestor) {
      menu.push(
        {
          slug: "apps-details",
          action: "menu",
          label: "Informações Gerais",
          icon: AssignmentIcon,
        },
        {
          slug: "apps-medical-records",
          action: "menu",
          label: "Prontuário Médico",
          icon: InsertEmoticonIcon,
        },
        {
          slug: "apps-affective-records",
          action: "menu",
          label: "Prontuário Afetivo",
          icon: InsertEmoticonIcon,
        },
        {
          slug: "apps-workplan",
          action: "menu",
          label: "Plano de Trabalho",
          icon: WorkIcon,
        },
        {
          slug: "apps-family",
          action: "menu",
          label: "Familiares",
          icon: FaceIcon,
        }
      );
    } else {
      await util.UserInfo().then(async (user_response) => {
        let { result } = await user_response;

        if (result.id_profissional) {
          const { id_profissional } = result;

          await util
            .PermissoesProfissional(id_profissional)
            .then(async (response) => {
              let { result } = await response;

              if (!unmounted) {
                if (util_permission.allowed("6fbf3df28cc0", true, result)) {
                  menu.push({
                    slug: "apps-details",
                    action: "menu",
                    label: "Informações Gerais",
                    icon: AssignmentIcon,
                  });
                }

                if (util_permission.allowed("6fbf3df28cc6", true, result)) {
                  menu.push({
                    slug: "apps-medical-records",
                    action: "menu",
                    label: "Prontuário Médico",
                    icon: InsertEmoticonIcon,
                  });
                }

                if (util_permission.allowed("6fbf3df28c15", true, result)) {
                  menu.push({
                    slug: "apps-affective-records",
                    action: "menu",
                    label: "Prontuário Afetivo",
                    icon: InsertEmoticonIcon,
                  });
                }

                if (util_permission.allowed("6fbf3df28c19", true, result)) {
                  menu.push({
                    slug: "apps-workplan",
                    action: "menu",
                    label: "Plano de Trabalho",
                    icon: WorkIcon,
                  });
                }

                if (util_permission.allowed("6fbf3df28c23", true, result)) {
                  menu.push({
                    slug: "apps-family",
                    action: "menu",
                    label: "Familiares",
                    icon: FaceIcon,
                  });
                }
              }
            });
        }
      });
    }

    setItemsMain(menu);

    return () => (unmounted = true);
  }, []);

  useEffect(() => {
    let unmounted = false;

    if (!unmounted) load();

    return () => (unmounted = true);
  }, [load]);

  // functions
  const handleChangeDialog = () => {
    setOpen(!open);
  };

  const goPage = (id, page, action) => {
    let url = "";

    switch (action) {
      case "menu":
        url = `/residente/preview/${id}?type=${page}`;
        break;

      default:
        break;
    }

    if (url !== "") {
      navigate(url);
    }
  };

  return (
    <React.Fragment>
      <MuiTableRow>
        {/* Nome */}
        <MuiTableCellBody className={classesTable.root} align="left">
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            width="100%"
          >
            <Box component="div" className={classesTable.containerCellActive}>
              <Typography
                sx={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  width: "280px",
                  display: "block",
                  overflow: "hidden",
                }}
              >
                {row.nome}
              </Typography>
            </Box>
          </Box>
        </MuiTableCellBody>

        {/* Actions */}
        <MuiTableCellBody className={classesTable.root} align="left">
          <Box
            component="div"
            className={classesTable.containerCell}
            sx={{
              backgroundColor: "transparent !important",
              justifyContent: "center",
              width: 80,
            }}
          >
            <Tooltip title="Menu">
              <IconButton
                onClick={handleChangeDialog}
                color="secondary"
                sx={{ p: "10px" }}
                aria-label="Novo"
              >
                <AppsIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </MuiTableCellBody>
      </MuiTableRow>

      <Dialog onClose={handleChangeDialog} open={open}>
        <DialogTitle>
          <Box textAlign="center">Menu</Box>
        </DialogTitle>
        <Divider />
        <List sx={{ py: 2, minWidth: "280px" }}>
          {itemsMain ? (
            itemsMain.map(function (item, index) {
              return (
                <ListItem
                  button
                  autoFocus
                  onClick={() => goPage(row.id, item.slug, item.action)}
                  key={index}
                >
                  <ListItemAvatar>
                    <Avatar
                      sx={{
                        bgcolor: (theme) => theme.palette.primary.dark,
                      }}
                    >
                      <item.icon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={item.label} />
                </ListItem>
              );
            })
          ) : (
            <React.Fragment></React.Fragment>
          )}
        </List>
        <Divider />
        <Box textAlign="center" my={2}>
          <Typography
            onClick={handleChangeDialog}
            variant="body1"
            sx={{ cursor: "pointer" }}
          >
            Voltar
          </Typography>
        </Box>
      </Dialog>
    </React.Fragment>
  );
};
