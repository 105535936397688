import { styled } from "@mui/material/styles";

const PREFIX = "Sidebar";

export const classes = {
  content: `${PREFIX}-content`,
  drawerRoot: `${PREFIX}-drawerRoot`,
  drawerPaper: `${PREFIX}-drawerPaper`,
  listItem: `${PREFIX}-listItem`,
  active: `${PREFIX}-active`,
  listTitle: `${PREFIX}-listTitle`,
  listIcon: `${PREFIX}-listIcon`,
  listText: `${PREFIX}-listText`,
  listTot: `${PREFIX}-listTot`,
  settingsContent: `${PREFIX}-settingsContent`,
  page: `${PREFIX}-page`,
  pageContainer: `${PREFIX}-pageContainer`,
  avatar: `${PREFIX}-avatar`,
};

export const ContentPage = styled("div")(({ theme }) => ({
  [`.${classes.content}`]: {
    display: "flex",
  },
  [`.${classes.page}`]: {
    height: "calc(100vh - 65px - 38px)",
    width: "100%",
    overflow: "hidden !important",
  },
  [`.${classes.pageContainer}`]: {
    overflowY: "auto !important",
    height: "100%",
    backgroundColor: `${theme.palette.background.default} !important`,
    color: theme.palette.text.primary,
  },
  [`.${classes.avatar}`]: {
    cursor: "pointer",
    marginRight: theme.spacing(2),
  },
}));

export const ContentMenu = styled("div")(({ theme }) => ({
  [`.${classes.listTitle}`]: {
    fontSize: "8pt !important",
    fontWeight: "700 !important",
    color: theme.palette.mode === "light" ? "#e7e7e7" : "#e7e7e7",
    margin: theme.spacing(1.75),
  },
  [`.${classes.listItem}`]: {
    display: "flex",
    flexDirection: "column",
    paddingTop: "16px",
    paddingBottom: "16px",
  },
  [`.${classes.active}`]: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    backgroundImage: "none !important",

    "div svg": {
      color: "#ffffff !important",
    },
    "div p": {
      color: "#ffffff !important",
    },
    span: {
      color: "#ffffff !important",
    },
  },
  [`.${classes.listIcon}`]: {
    display: "flex",
    justifyContent: "center",
    color: `${theme.palette.drawer.contrastText} !important`,

    svg: {
      fontSize: "18pt !important",
      marginTop: "0px",
      marginBottom: "0px",
    },
  },
  [`.${classes.listText}`]: {
    color: `${theme.palette.drawer.contrastText}`,
    fontSize: "11pt !important",
    fontWeight: "400 !important",
    letterSpacing: "0px !important",
  },
  [`.${classes.listTot}`]: {
    color: `${theme.palette.drawer.contrastText}`,
    fontSize: "8pt !important",
    fontWeight: "500 !important",
    letterSpacing: "0px !important",
  },
  [`.${classes.settingsContent}`]: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "end",
    borderTop: `1px solid ${theme.palette.drawer.light}`,
  },
}));
