// react
import React, { useState } from "react";

// router
import { useNavigate } from "react-router-dom";

// material
import { Box, Typography, Grid, Hidden, Alert, Tooltip } from "@mui/material";

// icons
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

// components
import { Sidebar } from "../../../../../../components/app/Sidebar";
import { TitleMain } from "../../../../../../components/app/Title/Main";
import { FormControl } from "../../../../../../components/app/Form/FormControl";
import { Controls } from "../../../../../../components/app/Form/Controls";
import { ContentMain } from "../../../../../../components/app/Content/Main";
import { ContentPaper } from "../../../../../../components/app/Content/Paper";

// util
// import util from "../util";

import { medida_itens, unidade_itens } from "../../../../../../util/itens";

// style
import { StepForm } from "../../../../../../components/app/Step/Form";

const steps = ["Medicamento"];

export const CreateMedicament = () => {
  // router
  const navigate = useNavigate();

  // state
  const [messageError, setMessageError] = useState("");

  // state-form
  const [centro_custo, setCentro_custo] = useState("");
  const [nome, setNome] = useState("");
  const [medida, setMedida] = useState("");
  const [dose, setDose] = useState("");
  const [unidade_dose, setUnidade_dose] = useState("");
  const [qtd, setQtd] = useState(1);
  const [dt_compra, setDt_compra] = useState("");
  const [dt_validade, setDt_validade] = useState("");
  const [qtd_embalagens, setQtdEmbalagens] = useState(1);
  const [consumo, setConsumo] = useState("");
  const [dt_reposicao, setDt_reposicao] = useState("");
  const [observacoes, setObservacoes] = useState("");

  // state-step
  const [activeStep, setActiveStep] = useState(0);
  const [actionStepButton, setActionStepButton] = useState(false);

  // functions
  const handleSubmit = async (event) => {
    event.preventDefault();

    setActionStepButton(true);
    setMessageError("");

    document.getElementById("page").scrollTo(0, 0);

    if (validate()) {
      // await util
      //   .Create({
      //     // produto
      //     nome: nome,
      //     qtd: qtd,
      //     dt_reposicao: dt_reposicao,
      //     observacoes: observacoes,
      //   })
      //   .catch((error) => {
      //     setActionStepButton(false);
      //     setMessageError("Ocorreu um erro ao salvar.");
      //   })
      //   .finally(() => {
      //     goList();
      //   });
    } else {
      setActionStepButton(false);
      setMessageError("Por favor, corrija os campos em vermelho.");
    }
  };

  const validate = () => {
    return true;
  };

  // redirect
  const goList = () => {
    navigate(`/residencia?type=apps-medicament`);
  };

  const goRefresh = () => {
    window.location.reload();
  };

  // components
  const MenuIcons = () => {
    return (
      <Box my={2}>
        <Tooltip title="Voltar">
          <IconButton aria-label="voltar" size="large" onClick={goList}>
            <ArrowBackIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title="Salvar">
          <IconButton aria-label="salvar" size="large" onClick={handleSubmit}>
            <SaveIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title="Limpar formulário">
          <IconButton aria-label="limpar" size="large" onClick={goRefresh}>
            <RestartAltIcon />
          </IconButton>
        </Tooltip>
      </Box>
    );
  };

  return (
    <Sidebar>
      <ContentMain>
        <Hidden mdUp>
          <Box textAlign="end" mb={3}>
            <MenuIcons />
          </Box>
        </Hidden>

        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <ContentPaper>
              <Box sx={{ width: "100%" }} mt={5}>
                <StepForm
                  steps={steps}
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                  action={handleSubmit}
                  actionLabel="Salvar"
                  actionStepButton={actionStepButton}
                >
                  <FormControl>
                    {activeStep === 0 ? (
                      <Grid
                        container
                        spacing={1}
                        sx={{
                          paddingBottom: 4,
                        }}
                      >
                        <Grid item xs={12}>
                          <Controls.Input
                            label="Centro de custo"
                            placeholder="..."
                            id="centro_custo"
                            name="centro_custo"
                            type="text"
                            length={150}
                            value={centro_custo}
                            onChange={(event) =>
                              setCentro_custo(event.target.value)
                            }
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Controls.Input
                            label="Nome do medicamento"
                            placeholder="..."
                            id="nome"
                            name="nome"
                            type="text"
                            length={150}
                            value={nome}
                            onChange={(event) => setNome(event.target.value)}
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Controls.Select
                            label="Medida"
                            name="medida"
                            id="medida"
                            items={medida_itens}
                            value={medida}
                            onChange={(event) => setMedida(event.target.value)}
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>

                        <Grid item xs={12} lg={6}>
                          <Controls.Input
                            label="Dose"
                            placeholder="..."
                            id="dose"
                            name="dose"
                            type="text"
                            length={150}
                            value={dose}
                            onChange={(event) => setDose(event.target.value)}
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>

                        <Grid item xs={12} lg={6}>
                          <Controls.Select
                            label="Unidade da dose"
                            name="unidade_dose"
                            id="unidade_dose"
                            items={unidade_itens}
                            value={unidade_dose}
                            onChange={(event) =>
                              setUnidade_dose(event.target.value)
                            }
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>

                        <Grid item xs={12} lg={12}>
                          <Controls.Input
                            label="Data da próxima compra"
                            id="dt_reposicao"
                            name="dt_reposicao"
                            type="date"
                            value={dt_reposicao}
                            onChange={(event) =>
                              setDt_reposicao(event.target.value)
                            }
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>

                        <Grid item xs={12} lg={12}>
                          <Controls.Input
                            label="Data da última compra"
                            id="dt_compra"
                            name="dt_compra"
                            type="date"
                            value={dt_compra}
                            onChange={(event) =>
                              setDt_compra(event.target.value)
                            }
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>

                        <Grid item xs={12} lg={12}>
                          <Controls.Input
                            label="Data última validade"
                            id="dt_validade"
                            name="dt_validade"
                            type="date"
                            value={dt_validade}
                            onChange={(event) =>
                              setDt_validade(event.target.value)
                            }
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>

                        <Grid item xs={12} lg={6}>
                          <Controls.Input
                            label="Estoque mínimo"
                            placeholder="..."
                            id="qtd"
                            name="qtd"
                            type="number"
                            length={150}
                            value={qtd}
                            onChange={(event) => setQtd(event.target.value)}
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>

                        <Grid item xs={12} lg={6}>
                          <Controls.Input
                            label="Estoque (Qtd de embalagens)"
                            placeholder="..."
                            id="qtd_embalagens"
                            name="qtd_embalagens"
                            type="number"
                            length={150}
                            value={qtd_embalagens}
                            onChange={(event) =>
                              setQtdEmbalagens(event.target.value)
                            }
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Controls.Input
                            label="Consumo diário"
                            placeholder="..."
                            id="consumo"
                            name="consumo"
                            type="text"
                            length={150}
                            value={consumo}
                            onChange={(event) => setConsumo(event.target.value)}
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Controls.TextArea
                            label="Observações"
                            placeholder="..."
                            id="observacoes"
                            rows={4}
                            name="observacoes"
                            value={observacoes}
                            onChange={(event) =>
                              setObservacoes(event.target.value)
                            }
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>
                      </Grid>
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}
                  </FormControl>
                </StepForm>
              </Box>
            </ContentPaper>
          </Grid>

          <Grid item xs={12} md={4}>
            <Hidden mdDown>
              <Box mt={5}>
                <TitleMain>Novo Insumo</TitleMain>
                <Typography component="h2" variant="body2">
                  Preencha o formulário para continuar.
                </Typography>

                {messageError ? (
                  <Box
                    sx={{
                      pr: {
                        xs: 2,
                        sm: 4,
                      },
                    }}
                  >
                    <Alert
                      severity="error"
                      sx={{
                        my: 2,
                      }}
                    >
                      {messageError}
                    </Alert>
                  </Box>
                ) : (
                  <React.Fragment></React.Fragment>
                )}

                <MenuIcons />
              </Box>
            </Hidden>
          </Grid>
        </Grid>
      </ContentMain>
    </Sidebar>
  );
};
