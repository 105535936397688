import React from "react";

import { useNavigate } from "react-router-dom";

import {
  Box,
  Avatar,
  Typography,
  Divider,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";

import ApartmentIcon from "@mui/icons-material/Apartment";
import GroupIcon from "@mui/icons-material/Group";
// import FaceIcon from "@mui/icons-material/Face";

const data = [
  {
    slug: "empresa",
    label: "Sou Empresa",
    icon: ApartmentIcon,
  },
  {
    slug: "profissional",
    label: "Sou Funcionário",
    icon: GroupIcon,
  },
  // {
  //   slug: "familiar",
  //   label: "Sou Familiar",
  //   icon: FaceIcon,
  // },
];

export const OAuthDialog = (props) => {
  const navigate = useNavigate();

  const { onClose, open } = props;

  const handleClose = () => {
    onClose(true);
  };

  const goPage = (page) => {
    navigate(`/login/${page}`);
    onClose(true);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Selecione seu tipo de acesso</DialogTitle>
      <Divider />
      <List sx={{ py: 2 }}>
        {data.map(function (item, index) {
          return (
            <ListItem
              button
              autoFocus
              onClick={() => goPage(`${item.slug}`)}
              key={index}
            >
              <ListItemAvatar>
                <Avatar
                  sx={{
                    bgcolor: (theme) => theme.palette.primary.dark,
                  }}
                >
                  <item.icon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={item.label} />
            </ListItem>
          );
        })}
      </List>
      <Divider />
      <Box textAlign="center" my={2}>
        <Typography
          onClick={handleClose}
          variant="body1"
          sx={{ cursor: "pointer" }}
        >
          Voltar
        </Typography>
      </Box>
    </Dialog>
  );
};
