import React, { useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { useAuth } from "../../../contexts/AuthProvider/useAuth";

export const Logout = () => {
  const navigate = useNavigate();
  const auth = useAuth();

  useEffect(() => {
    auth.logout();

    navigate("/login/empresa");
  }, [auth, navigate]);

  return <React.Fragment></React.Fragment>;
};
