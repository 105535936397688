export const getToken = () => {
  const json = localStorage.getItem("us-token");
  const user = JSON.parse(json);

  if (user) {
    const { authorization } = user;

    if (authorization.token) return authorization.token;
  }

  return null;
};

export const getData = () => {
  const json = localStorage.getItem("us-data");
  const user = JSON.parse(json);

  if (user) {
    return user;
  } else {
    return null;
  }
};
