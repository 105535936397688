// react
import React, { useState } from "react";

// router
import { useSearchParams } from "react-router-dom";

// material
import {
  Box,
  Typography,
  TableRow as MuiTableRow,
  FormControlLabel,
  Switch,
} from "@mui/material";

// components
import {
  classesTable,
  StylesMuiTableCell as MuiTableCellBody,
} from "../../../../../../../../components/app/Table";

// functions
import util from "../../../../../util";

export const TableRow = (props) => {
  // router
  const [searchParams] = useSearchParams();

  // props
  const { row } = props;

  const ControlledSwitches = (props) => {
    // props
    const { row } = props;

    // state
    const [checked, setChecked] = useState(
      row.id_permissao_empresa_funcao !== null ? true : false
    );

    const handleChange = (
      id_empresa,
      id_permissao,
      id_permissao_empresa_funcao
    ) => {
      const id_ocupacao = searchParams.get("ocupacao");

      if (id_permissao_empresa_funcao) {
        // exclui
        util
          .DeleteOccupationsPermission(id_permissao_empresa_funcao)
          .then(async (response) => {
            if (response.success === true) {
              window.location.reload();
              setChecked(!checked);
            }
          });
      } else {
        // cadastra
        util
          .CreateOccupationsPermission({
            id_empresa,
            id_permissao,
            id_ocupacao,
          })
          .then(async (response) => {
            if (response.success === true) {
              window.location.reload();
              setChecked(!checked);
            }
          });
      }
    };

    return (
      <FormControlLabel
        control={
          <Switch
            checked={checked}
            onChange={() =>
              handleChange(
                row.id_empresa,
                row.id_permissao,
                row.id_permissao_empresa_funcao
              )
            }
            label="Autorizado"
            inputProps={{ "aria-label": "controlled" }}
          />
        }
        label="Autorizado"
      />
    );
  };

  return (
    <React.Fragment>
      <MuiTableRow>
        {/* Título */}
        <MuiTableCellBody className={classesTable.root} align="left">
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            width="100%"
          >
            <Box component="div" className={classesTable.containerCellActive}>
              <Typography
                sx={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  width: "600px",
                  display: "block",
                  overflow: "hidden",
                }}
              >
                {row.titulo}
              </Typography>
            </Box>
          </Box>
        </MuiTableCellBody>

        {/* Grupo */}
        <MuiTableCellBody className={classesTable.root} align="left">
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            width="100%"
          >
            <Box component="div" className={classesTable.containerCell}>
              <Typography
                sx={{
                  textAlign: "center",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  width: "195px",
                  display: "block",
                  overflow: "hidden",
                  textTransform: "capitalize",
                }}
              >
                {row.grupo ? row.grupo : "Não informado"}
              </Typography>
            </Box>
          </Box>
        </MuiTableCellBody>

        {/* Actions */}
        <MuiTableCellBody className={classesTable.root} align="left">
          <Box
            component="div"
            className={classesTable.containerCell}
            sx={{
              backgroundColor: "transparent !important",
              justifyContent: "center",
              width: 180,
            }}
          >
            <ControlledSwitches row={row} />
          </Box>
        </MuiTableCellBody>
      </MuiTableRow>
    </React.Fragment>
  );
};
