// react
import React from "react";

// material
import { Box, Paper } from "@mui/material";

export const ContentMain = (props) => {
  const { children } = props;

  return (
    <Box
      sx={{
        px: {
          xs: 2,
          sm: 4,
        },
        pt: {
          xs: 2,
          sm: 4,
        },
      }}
    >
      {children}
    </Box>
  );
};
