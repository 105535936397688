// react
import React from "react";

// material
import { Box, Paper } from "@mui/material";

export const ContentPaper = (props) => {
  const { children, height } = props;

  return (
    <Box>
      <Paper
        elevation={6}
        sx={{
          backgroundColor: (theme) =>
            `${theme.palette.background.document} !important`,
          backgroundImage: "none",
        }}
      >
        <Box px={3} py={4} sx={{ minHeight: height ? height : "100vh" }}>
          {children}
        </Box>
      </Paper>
    </Box>
  );
};
