import React from "react";
import ReactDOM from "react-dom/client";

import AOS from "aos";

import "./index.css";

import { App } from "./App";

AOS.init();

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
