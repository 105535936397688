// react
import React, { useState, useEffect, useCallback } from "react";

// router
import { useNavigate } from "react-router-dom";

// material
import { Box, Typography, Grid, Hidden, Alert, Tooltip } from "@mui/material";

// icons
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

// components
import { Sidebar } from "../../../../../../components/app/Sidebar";
import { TitleMain } from "../../../../../../components/app/Title/Main";
import { FormControl } from "../../../../../../components/app/Form/FormControl";
import { Controls } from "../../../../../../components/app/Form/Controls";
import { ContentPaper } from "../../../../../../components/app/Content/Paper";
import { ContentMain } from "../../../../../../components/app/Content/Main";
import { StepForm } from "../../../../../../components/app/Step/Form";

// util
import util from "../../../util";

import {
  genero_itens,
  nacionalidade_itens,
  estado_civil_itens,
  estado_itens,
  // funcao_itens,
} from "../../../../../../util/itens";

import {
  cpfMask,
  rgMask,
  phoneMask,
  numberMask,
  cepMask,
} from "../../../../../../util/mask";

const steps = [
  "Dados pessoais",
  "Endereço",
  "Contato",
  "Profissional",
  "Acesso",
];

export const CreateEmployee = () => {
  // router
  const navigate = useNavigate();

  // state
  const [messageError, setMessageError] = useState("");

  // state-form
  const [nome, set_nome] = useState("");
  const [cpf, set_cpf] = useState("");
  const [rg, set_rg] = useState("");
  const [genero, set_genero] = useState("");
  const [nacionalidade, set_nacionalidade] = useState("");
  const [estado_civil, set_estado_civil] = useState("");
  const [dt_nascimento, set_dt_nascimento] = useState(
    new Date().toISOString().substring(0, 10)
  );
  const [observacoes, set_observacoes] = useState("");
  const [email, set_email] = useState("");
  const [telefone1, set_telefone1] = useState("");
  const [telefone2, set_telefone2] = useState("");
  const [cep, set_cep] = useState("");
  const [logradouro, set_logradouro] = useState("");
  const [bairro, set_bairro] = useState("");
  const [numero, set_numero] = useState("");
  const [estado, set_estado] = useState("");
  const [cidade, set_cidade] = useState("");
  const [complemento, set_complemento] = useState("");
  const [id_funcao, set_id_funcao] = useState("");
  const [dt_admissao, set_dt_admissao] = useState(
    new Date().toISOString().substring(0, 10)
  );
  const [escala, set_escala] = useState("");
  const [turno, set_turno] = useState("");
  const [status, set_status] = useState(true);
  const [login, set_login] = useState("");
  const [password, set_password] = useState("");
  const [notificar, set_notificar] = useState("");
  const [notificar_email, set_notificar_email] = useState("");

  // state-util
  const [id_empresa, set_id_empresa] = useState("");
  const [funcao_itens, set_funcao_itens] = useState([]);

  // state-step
  const [activeStep, setActiveStep] = useState(0);
  const [actionStepButton, setActionStepButton] = useState(false);

  const load = useCallback(async () => {
    let unmounted = false;

    try {
      await util.UserInfo().then(async (user_response) => {
        let { result } = await user_response;
        set_id_empresa(result.id_empresa);

        await util.ListFunctions(result.id_empresa).then((response) => {
          let result = response;

          if (!unmounted) {
            result.unshift({
              title: "Selecione...",
              value: "",
            });

            set_funcao_itens(result);
          }
        });
      });
    } catch (error) {
      setMessageError(error.message);
    }

    return () => (unmounted = true);
  }, []);

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) load();

    return () => (unmounted = true);
  }, [load]);

  useEffect(() => {
    if (status === false) {
      set_login("");
      set_password("");
    }
  }, [status]);

  // functions
  const handleSubmit = async (event) => {
    event.preventDefault();

    setActionStepButton(true);
    setMessageError("");

    document.getElementById("page").scrollTo(0, 0);

    if (validate()) {
      await util
        .CreateEmployee({
          // dados pessoais
          nome: nome,
          cpf: cpf,
          rg: rg,
          genero: genero,
          nacionalidade: nacionalidade,
          estado_civil: estado_civil,
          dt_nascimento: dt_nascimento,
          observacoes: observacoes,

          // contato
          email: email,
          telefone1: telefone1,
          telefone2: telefone2,

          // endereco
          cep: cep,
          logradouro: logradouro,
          bairro: bairro,
          numero: numero,
          estado: estado,
          cidade: cidade,
          complemento: complemento,

          // profissional
          id_funcao: id_funcao,
          dt_admissao: dt_admissao,
          escala: escala,
          turno: turno,

          // empresa
          id_empresa: id_empresa,

          // oauth
          status: status,
          login: login,
          password: password,
          notificar: notificar,
          notificar_email: notificar_email,
        })
        .catch((error) => {
          setActionStepButton(false);
          setMessageError("Ocorreu um erro ao salvar.");
        })
        .finally(() => {
          goList();
        });
    } else {
      setActionStepButton(false);
      setMessageError("Por favor, corrija os campos em vermelho.");
    }
  };

  const validate = () => {
    return true;
  };

  // redirect
  const goList = () => {
    navigate(`/residencia?type=apps-employee`);
  };

  const goRefresh = () => {
    window.location.reload();
  };

  // components
  const MenuIcons = () => {
    return (
      <Box my={2}>
        <Tooltip title="Voltar">
          <IconButton aria-label="voltar" size="large" onClick={goList}>
            <ArrowBackIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title="Salvar">
          <IconButton aria-label="salvar" size="large" onClick={handleSubmit}>
            <SaveIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title="Limpar formulário">
          <IconButton aria-label="limpar" size="large" onClick={goRefresh}>
            <RestartAltIcon />
          </IconButton>
        </Tooltip>
      </Box>
    );
  };

  return (
    <Sidebar>
      <ContentMain>
        <Hidden mdUp>
          <Box textAlign="end" mb={3}>
            <MenuIcons />
          </Box>
        </Hidden>

        <Grid container spacing={0}>
          <Grid item xs={12} md={8}>
            <ContentPaper>
              <Box sx={{ width: "100%" }} mt={5}>
                <StepForm
                  steps={steps}
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                  action={handleSubmit}
                  actionLabel="Salvar"
                  actionStepButton={actionStepButton}
                >
                  <FormControl>
                    {activeStep === 0 ? (
                      <Grid
                        container
                        spacing={1}
                        sx={{
                          py: 4,
                        }}
                      >
                        <Grid item xs={12}>
                          <Controls.Input
                            label="Nome completo"
                            placeholder="..."
                            id="nome"
                            name="nome"
                            type="text"
                            length={150}
                            value={nome}
                            onChange={(event) => set_nome(event.target.value)}
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>

                        <Grid item xs={12} lg={6}>
                          <Controls.Input
                            label="CPF"
                            placeholder="..."
                            id="cpf"
                            name="cpf"
                            type="text"
                            length={14}
                            value={cpf}
                            onChange={(event) =>
                              set_cpf(cpfMask(event.target.value))
                            }
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>

                        <Grid item xs={12} lg={6}>
                          <Controls.Input
                            label="RG"
                            placeholder="..."
                            id="rg"
                            name="rg"
                            type="text"
                            length={15}
                            value={rg}
                            onChange={(event) =>
                              set_rg(rgMask(event.target.value))
                            }
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Controls.Select
                            label="Gênero"
                            name="genero"
                            id="genero"
                            items={genero_itens}
                            value={genero}
                            onChange={(event) => set_genero(event.target.value)}
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Controls.Select
                            label="Nacionalidade"
                            name="nacionalidade"
                            id="nacionalidade"
                            items={nacionalidade_itens}
                            value={nacionalidade}
                            onChange={(event) =>
                              set_nacionalidade(event.target.value)
                            }
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Controls.Select
                            label="Estado Civil"
                            name="estado_civil"
                            id="estado_civil"
                            items={estado_civil_itens}
                            value={estado_civil}
                            onChange={(event) =>
                              set_estado_civil(event.target.value)
                            }
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Controls.Input
                            label="Data de nascimento"
                            id="dt_nascimento"
                            name="dt_nascimento"
                            type="date"
                            value={dt_nascimento}
                            onChange={(event) =>
                              set_dt_nascimento(event.target.value)
                            }
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Controls.TextArea
                            label="Observações"
                            placeholder="..."
                            id="observacoes"
                            rows={4}
                            name="observacoes"
                            value={observacoes}
                            onChange={(event) =>
                              set_observacoes(event.target.value)
                            }
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>
                      </Grid>
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}

                    {activeStep === 1 ? (
                      <Grid
                        container
                        spacing={1}
                        sx={{
                          py: 4,
                        }}
                      >
                        <Grid item xs={12}>
                          <Controls.Input
                            label="CEP"
                            placeholder="..."
                            id="cep"
                            name="cep"
                            type="text"
                            length={9}
                            value={cep}
                            onChange={(event) =>
                              set_cep(cepMask(event.target.value))
                            }
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Controls.Input
                            label="Logradouro"
                            placeholder="..."
                            id="logradouro"
                            name="logradouro"
                            type="text"
                            length={150}
                            value={logradouro}
                            onChange={(event) =>
                              set_logradouro(event.target.value)
                            }
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>

                        <Grid item xs={8}>
                          <Controls.Input
                            label="Bairro"
                            placeholder="..."
                            id="bairro"
                            name="bairro"
                            type="text"
                            length={150}
                            value={bairro}
                            onChange={(event) => set_bairro(event.target.value)}
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>

                        <Grid item xs={4}>
                          <Controls.Input
                            label="Número"
                            placeholder="..."
                            id="numero"
                            name="numero"
                            type="text"
                            length={10}
                            value={numero}
                            onChange={(event) =>
                              set_numero(numberMask(event.target.value))
                            }
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Controls.Select
                            label="Estado"
                            placeholder="..."
                            name="estado"
                            id="estado"
                            items={estado_itens}
                            value={estado}
                            onChange={(event) => set_estado(event.target.value)}
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Controls.Input
                            label="Cidade"
                            placeholder="..."
                            id="cidade"
                            name="cidade"
                            type="text"
                            length={150}
                            value={cidade}
                            onChange={(event) => set_cidade(event.target.value)}
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Controls.Input
                            label="Complemento"
                            placeholder="..."
                            id="complemento"
                            name="complemento"
                            type="text"
                            length={150}
                            value={complemento}
                            onChange={(event) =>
                              set_complemento(event.target.value)
                            }
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>
                      </Grid>
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}

                    {activeStep === 2 ? (
                      <Grid
                        container
                        spacing={1}
                        sx={{
                          py: 4,
                        }}
                      >
                        <Grid item xs={12}>
                          <Controls.Input
                            label="E-mail"
                            placeholder="..."
                            id="email"
                            name="email"
                            type="email"
                            length={150}
                            value={email}
                            onChange={(event) => set_email(event.target.value)}
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>

                        <Grid item xs={12} lg={6}>
                          <Controls.Input
                            label="Celular"
                            placeholder="..."
                            id="telefone1"
                            name="telefone1"
                            type="text"
                            length={15}
                            value={telefone1}
                            onChange={(event) =>
                              set_telefone1(phoneMask(event.target.value))
                            }
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>

                        <Grid item xs={12} lg={6}>
                          <Controls.Input
                            label="Telefone"
                            placeholder="..."
                            id="telefone2"
                            name="telefone2"
                            type="text"
                            length={15}
                            value={telefone2}
                            onChange={(event) =>
                              set_telefone2(phoneMask(event.target.value))
                            }
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>
                      </Grid>
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}

                    {activeStep === 3 ? (
                      <Grid
                        container
                        spacing={1}
                        sx={{
                          py: 4,
                        }}
                      >
                        <Grid item xs={12}>
                          <Controls.Select
                            label="Funcao"
                            placeholder="..."
                            name="id_funcao"
                            id="id_funcao"
                            items={funcao_itens}
                            value={id_funcao}
                            onChange={(event) =>
                              set_id_funcao(event.target.value)
                            }
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Controls.Input
                            label="Data de admissão"
                            id="dt_admissao"
                            name="dt_admissao"
                            type="date"
                            value={dt_admissao}
                            onChange={(event) =>
                              set_dt_admissao(event.target.value)
                            }
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Controls.Input
                            label="Escala"
                            placeholder="..."
                            id="escala"
                            name="escala"
                            type="text"
                            length={150}
                            value={escala}
                            onChange={(event) => set_escala(event.target.value)}
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Controls.Input
                            label="Turno"
                            placeholder="..."
                            id="turno"
                            name="turno"
                            type="text"
                            length={150}
                            value={turno}
                            onChange={(event) => set_turno(event.target.value)}
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>
                      </Grid>
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}

                    {activeStep === 4 ? (
                      <Grid
                        container
                        spacing={1}
                        sx={{
                          py: 4,
                        }}
                      >
                        {status ? (
                          <Grid item xs={12}>
                            <Controls.Input
                              label="Login de acesso"
                              placeholder="..."
                              id="login"
                              name="login"
                              type="text"
                              length={150}
                              value={login}
                              onChange={(event) =>
                                set_login(event.target.value)
                              }
                              style={{ marginBottom: "1.25rem" }}
                            />

                            <Controls.Input
                              label="Senha de acesso"
                              placeholder="..."
                              id="password"
                              name="password"
                              type="text"
                              length={150}
                              value={password}
                              onChange={(event) =>
                                set_password(event.target.value)
                              }
                              style={{ marginBottom: "1.25rem" }}
                            />
                          </Grid>
                        ) : (
                          <React.Fragment></React.Fragment>
                        )}

                        <Grid item xs={12}>
                          <Controls.Checkbox
                            label="Cadastrar dados para acesso?"
                            name="status"
                            value={status}
                            onChange={(event) => set_status(event.target.value)}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Controls.Checkbox
                            label="Notificar por e-mail?"
                            name="notificar"
                            value={notificar}
                            onChange={(event) =>
                              set_notificar(event.target.value)
                            }
                          />
                        </Grid>

                        {notificar ? (
                          <Grid item xs={12}>
                            <Controls.Input
                              label="E-mail de notificação"
                              placeholder="..."
                              id="notificar_email"
                              name="notificar_email"
                              type="text"
                              length={150}
                              value={notificar_email}
                              onChange={(event) =>
                                set_notificar_email(event.target.value)
                              }
                              style={{ marginBottom: "1.25rem" }}
                            />
                          </Grid>
                        ) : (
                          <React.Fragment></React.Fragment>
                        )}
                      </Grid>
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}
                  </FormControl>
                </StepForm>
              </Box>
            </ContentPaper>
          </Grid>

          <Grid item xs={12} md={4} px={4}>
            <Hidden mdDown>
              <Box mt={5}>
                <TitleMain>Novo Profissional</TitleMain>
                <Typography component="h2" variant="body2">
                  Preencha o formulário para continuar.
                </Typography>

                {messageError ? (
                  <Alert
                    severity="error"
                    sx={{
                      my: 2,
                    }}
                  >
                    {messageError}
                  </Alert>
                ) : (
                  <React.Fragment></React.Fragment>
                )}

                <MenuIcons />
              </Box>
            </Hidden>
          </Grid>
        </Grid>
      </ContentMain>
    </Sidebar>
  );
};
