// react
import React, { useState } from "react";

// material
import { Box, Stepper, Step, StepLabel, Button } from "@mui/material";

export function StepForm(props) {
  const {
    children,
    steps,
    activeStep,
    setActiveStep,
    action,
    actionLabel,
    actionStepButton,
  } = props;

  // states
  const [skipped, setSkipped] = useState(new Set());

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);

    document.getElementById("page").scrollTo(0, 0);
  };

  const handleNext = () => {
    let newSkipped = skipped;

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);

    document.getElementById("page").scrollTo(0, 0);
  };

  return (
    <React.Fragment>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        sx={{ overflowX: "auto", paddingBottom: 2 }}
      >
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};

          return (
            <Step
              key={label}
              {...stepProps}
              onClick={() => setActiveStep(index)}
            >
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      <Box py={2} px={2}>
        {children}
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          p: 2,
        }}
      >
        {activeStep === steps.length - 1 ? (
          <React.Fragment>
            {action ? (
              <Button
                color="success"
                variant="contained"
                fullWidth
                sx={{
                  boxShadow: "none",
                  fontWeight: 400,
                  py: 1.75,
                }}
                onClick={action}
                disabled={actionStepButton}
              >
                {actionLabel}
              </Button>
            ) : (
              <React.Fragment></React.Fragment>
            )}
          </React.Fragment>
        ) : (
          <React.Fragment>
            {activeStep === steps.length - 1 ? (
              <React.Fragment></React.Fragment>
            ) : (
              <Button
                color="primary"
                variant="contained"
                fullWidth
                sx={{
                  boxShadow: "none",
                  fontWeight: 400,
                  py: 1.75,
                }}
                disabled={activeStep === steps.length - 1}
                onClick={handleNext}
              >
                Prosseguir para {steps[activeStep + 1]}
              </Button>
            )}
          </React.Fragment>
        )}

        <Button
          color="secondary"
          variant="text"
          fullWidth
          disabled={activeStep === 0}
          onClick={handleBack}
          sx={{ mt: 1, py: 1.75 }}
        >
          Voltar
        </Button>
      </Box>
    </React.Fragment>
  );
}
