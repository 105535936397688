// react
import React, { useState, useEffect, useCallback } from "react";

// router
import { useSearchParams, useParams, useNavigate } from "react-router-dom";

// material
import { Box, Table, TableBody, Tooltip, Typography } from "@mui/material";

// icons
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

// components
import { Sidebar } from "../../../../components/app/Sidebar";
import { Avatar } from "../../../../components/app/Avatar";
import { ContentMain } from "../../../../components/app/Content/Main";
import { ContentPaper } from "../../../../components/app/Content/Paper";
import { LoadingSquare } from "../../../../components/app/Loading/Square";
import { TitleMain } from "../../../../components/app/Title/Main";

// functions
import util from "../util";

// data
import {
  genero_itens,
  nacionalidade_itens,
  estado_civil_itens,
  estado_itens,
} from "../../../../util/itens";
import formatted from "../../../../util/formatted";

import {
  StyledTableCell,
  StyledTableCellTitle,
  StyledTableRow,
} from "../components/styled/Table";

export const ResidentFamilyShow = () => {
  const [searchParams] = useSearchParams();
  let { id } = useParams();

  // router
  const navigate = useNavigate();

  const [row, setRow] = useState({});

  // const [messageError, setMessageError] = useState("");

  //   effects
  const load = useCallback(async () => {
    let unmounted = false;

    setRow(null);

    let id_pessoa_residente = searchParams.get("residente");

    setTimeout(async () => {
      if (id) {
        await util.UserInfo().then(async (userResponse) => {
          let { result } = await userResponse;

          if (result.id_gestor) {
            await util
              .ShowResidentFamily(id, id_pessoa_residente)
              .then((response) => {
                const { result } = response;

                if (result) {
                  result.nacionalidade =
                    formatted.listItem(
                      result.nacionalidade,
                      nacionalidade_itens
                    ) || "";

                  result.estado_civil =
                    formatted.listItem(
                      result.estado_civil,
                      estado_civil_itens
                    ) || "";

                  result.estado =
                    formatted.listItem(result.estado, estado_itens) || "";

                  result.genero =
                    formatted.listItem(result.genero, genero_itens) || "";

                  result.dt_nascimento =
                    formatted.onlyDate(result.dt_nascimento) || "";

                  if (!unmounted) {
                    setRow(result);
                  }
                } else {
                  alert(
                    "Nenhum registro encontrado. Tente novamente mais tarde. Caso o problema persista contate nossa equipe."
                  );
                  navigate(`/residente`);
                }
              });
          } else {
            alert(
              "Nenhum registro encontrado. Tente novamente mais tarde. Caso o problema persista contate nossa equipe."
            );
            navigate(`/residente`);
          }
        });
      } else {
        alert(
          "Nenhum registro encontrado. Tente novamente mais tarde. Caso o problema persista contate nossa equipe."
        );
        navigate(`/residente`);
      }
    }, 500);

    return () => (unmounted = true);
  }, [searchParams, navigate, id]);

  useEffect(() => {
    let unmounted = false;

    if (!unmounted) load();

    return () => (unmounted = true);
  }, [load, id]);

  // redirect
  const goList = () => {
    let id_pessoa_residente = searchParams.get("residente");

    navigate(`/residente/preview/${id_pessoa_residente}?type=apps-family`);
  };

  return (
    <Sidebar>
      {row ? (
        <ContentMain>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="end"
            width="100%"
            mb={3}
          >
            <Tooltip title="Voltar">
              <IconButton
                onClick={goList}
                color="secondary"
                sx={{ p: "10px", mr: 1 }}
                aria-label="Voltar"
              >
                <ArrowBackIcon />
              </IconButton>
            </Tooltip>
          </Box>

          <ContentPaper>
            <Box
              py={4}
              px={2}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar
                name={row.nome}
                picture={row.foto}
                width="62px"
                height="62px"
              />

              <Box textAlign="center" my={2}>
                <Typography component="h1" variant="h5">
                  {row.nome}
                </Typography>

                <Typography
                  component="h2"
                  variant="body2"
                  sx={{
                    mt: 2,
                    color: "secondary.main",
                    textTransform: "uppercase",
                  }}
                >
                  {row.parentesco}
                </Typography>
              </Box>
            </Box>

            <Box mb={4}>
              <Box pb={4}>
                <TitleMain>Parentesco</TitleMain>

                <Table sx={{ minWidth: "100%", pt: 2 }}>
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCellTitle align="left">
                        Parentesco
                      </StyledTableCellTitle>

                      <StyledTableCell align="right">
                        {row.parentesco ? row.parentesco : "Não informado"}
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </Box>

              <Box pb={4}>
                <TitleMain>Dados Pessoais</TitleMain>

                <Table sx={{ minWidth: "100%", pt: 2 }}>
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCellTitle align="left">
                        Nome
                      </StyledTableCellTitle>

                      <StyledTableCell align="right">
                        {row.nome ? row.nome : "Não informado"}
                      </StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                      <StyledTableCellTitle align="left">
                        CPF
                      </StyledTableCellTitle>

                      <StyledTableCell align="right">
                        {row.cpf ? row.cpf : "Não informado"}
                      </StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                      <StyledTableCellTitle align="left">
                        RG
                      </StyledTableCellTitle>

                      <StyledTableCell align="right">
                        {row.rg ? row.rg : "Não informado"}
                      </StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                      <StyledTableCellTitle align="left">
                        Gênero
                      </StyledTableCellTitle>

                      <StyledTableCell align="right">
                        {row.genero ? row.genero : "Não informado"}
                      </StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                      <StyledTableCellTitle align="left">
                        Nacionalidade
                      </StyledTableCellTitle>

                      <StyledTableCell align="right">
                        {row.nacionalidade
                          ? row.nacionalidade
                          : "Não informado"}
                      </StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                      <StyledTableCellTitle align="left">
                        Estado Civil
                      </StyledTableCellTitle>

                      <StyledTableCell align="right">
                        {row.estado_civil ? row.estado_civil : "Não informado"}
                      </StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                      <StyledTableCellTitle align="left">
                        Data de nascimento
                      </StyledTableCellTitle>

                      <StyledTableCell align="right">
                        {row.dt_nascimento
                          ? row.dt_nascimento
                          : "Não informado"}
                      </StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                      <StyledTableCellTitle align="left">
                        Observações
                      </StyledTableCellTitle>

                      <StyledTableCell align="right">
                        {row.observacoes ? row.observacoes : "Não informado"}
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </Box>

              <Box pb={4}>
                <TitleMain>Endereço</TitleMain>

                <Table sx={{ minWidth: "100%", pt: 2 }}>
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCellTitle align="left">
                        CEP
                      </StyledTableCellTitle>

                      <StyledTableCell align="right">
                        {row.cep ? row.cep : "Não informado"}
                      </StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                      <StyledTableCellTitle align="left">
                        Logradouro
                      </StyledTableCellTitle>

                      <StyledTableCell align="right">
                        {row.logradouro ? row.logradouro : "Não informado"}
                      </StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                      <StyledTableCellTitle align="left">
                        Bairro
                      </StyledTableCellTitle>

                      <StyledTableCell align="right">
                        {row.bairro ? row.bairro : "Não informado"}
                      </StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                      <StyledTableCellTitle align="left">
                        Número
                      </StyledTableCellTitle>

                      <StyledTableCell align="right">
                        {row.numero ? row.numero : "Não informado"}
                      </StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                      <StyledTableCellTitle align="left">
                        Estado
                      </StyledTableCellTitle>

                      <StyledTableCell align="right">
                        {row.estado ? row.estado : "Não informado"}
                      </StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                      <StyledTableCellTitle align="left">
                        Cidade
                      </StyledTableCellTitle>

                      <StyledTableCell align="right">
                        {row.cidade ? row.cidade : "Não informado"}
                      </StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                      <StyledTableCellTitle align="left">
                        Complemento
                      </StyledTableCellTitle>

                      <StyledTableCell align="right">
                        {row.complemento ? row.complemento : "Não informado"}
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </Box>

              <Box pb={4}>
                <TitleMain>Contato</TitleMain>

                <Table sx={{ minWidth: "100%", pt: 2 }}>
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCellTitle align="left">
                        E-mail
                      </StyledTableCellTitle>

                      <StyledTableCell align="right">
                        {row.email ? row.email : "Não informado"}
                      </StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                      <StyledTableCellTitle align="left">
                        Celular
                      </StyledTableCellTitle>

                      <StyledTableCell align="right">
                        {row.telefone1 ? row.telefone1 : "Não informado"}
                      </StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                      <StyledTableCellTitle align="left">
                        Telefone
                      </StyledTableCellTitle>

                      <StyledTableCell align="right">
                        {row.telefone2 ? row.telefone2 : "Não informado"}
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </Box>
            </Box>
          </ContentPaper>
        </ContentMain>
      ) : (
        <Box
          px={2}
          textAlign="center"
          sx={{
            height: "calc(100vh - 65px - 38px)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoadingSquare />
        </Box>
      )}
    </Sidebar>
  );
};
