const allowed = (authorization, accept_manager, user_permission) => {
  let user_data = localStorage.getItem("us-data");
  user_data = JSON.parse(user_data);

  if (user_data) {
    let manager = user_data.gestor;

    if (manager === true && accept_manager) {
      return manager === true ? true : false;
    }

    const result = user_permission.findIndex((item) => {
      return item.codigo === authorization;
    });

    return result > -1 ? true : false;
  }

  return false;
};

const util_permission = { allowed };

export default util_permission;
