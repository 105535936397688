import { oauth } from "../../../services/oauth";

const UserInfo = async (data) => {
  try {
    let response = await oauth.get("/oauth/token");
    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};

const List = async (page, limit, search, id_empresa) => {
  try {
    let response = await oauth.get("/paginacao-relatorio/colecao", {
      headers: {
        startindex: page,
        limit: limit,
        search: search,
        id_empresa: id_empresa,
      },
    });

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};

const ListUnity = async (id_empresa) => {
  try {
    let response = null;
    let result = [];

    response = await oauth.get("/unidade-empresa/colecao", {
      headers: {
        id_empresa: id_empresa,
      },
    });

    if (response) result = response.data;

    return result;
  } catch (error) {
    return error;
  }
};

const ListEmployee = async (id_empresa) => {
  try {
    let response = null;
    let result = [];

    response = await oauth.get("/profissional-empresa/colecao", {
      headers: {
        id_empresa: id_empresa,
      },
    });

    if (response) result = response.data;

    return result;
  } catch (error) {
    return error;
  }
};

const Show = async (id, id_empresa) => {
  try {
    let response = await oauth.get("/paginacao-relatorio", {
      headers: {
        id,
        id_empresa,
      },
    });

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};

const Create = async (data) => {
  try {
    let response = await oauth.post("/relatorio", data);

    const result = response.data;

    return result;
  } catch (error) {
    return error;
  }
};

const Update = async (data) => {};

const util = {
  UserInfo,
  List,
  ListUnity,
  ListEmployee,
  Show,
  Create,
  Update,
};

export default util;
