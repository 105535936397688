import React from "react";

import { Box } from "@mui/material";

import { classes, ContentLoading } from "./styles";

export const LoadingSquare = () => {
  return (
    <ContentLoading>
      <Box component="span" className={classes.loader}>
        <Box component="span" className={classes.loaderInner}></Box>
      </Box>
    </ContentLoading>
  );
};
