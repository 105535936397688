// react
import React from "react";

// router
import { useNavigate } from "react-router-dom";

// material
import { Box, Typography, Button, Card, CircularProgress } from "@mui/material";

// icons
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

export function CardRedirect(props) {
  // router
  const navigate = useNavigate();

  // props
  const { title, qtd, redirect } = props;

  // components
  function Title(props) {
    return (
      <Typography component="h2" variant="h6" color="primary" gutterBottom>
        {props.children}
      </Typography>
    );
  }

  // redirect
  const goRedirect = (url) => {
    navigate(url);
  };

  return (
    <Card
      sx={{
        backgroundImage: "none",
        p: 2,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: 240,
        backgroundColor: (theme) => theme.palette.background.paper,
      }}
    >
      <Box>
        <Title>{title}</Title>
        <Typography
          component="div"
          variant="body2"
          color="secondary"
          sx={{ pt: 2, pb: 0.5 }}
        >
          Quantidade de registros
        </Typography>

        {qtd !== null ? (
          <Typography component="div" variant="h4" color="secondary">
            {qtd}
          </Typography>
        ) : (
          <CircularProgress size="20px" sx={{ my: 1 }} />
        )}
      </Box>

      <Box textAlign="end">
        <Button
          variant="outlined"
          endIcon={<ArrowForwardIcon />}
          onClick={() => goRedirect(redirect)}
        >
          Visualizar
        </Button>
      </Box>
    </Card>
  );
}
