const listItem = (value, list) => {
  if (value) {
    let item = list.find((item) => item.value === value);
    return item.title;
  }

  return null;
};

const onlyDate = (value) => {
  if (value) {
    return value.split("-").reverse().join("/");
  }
  return null;
};

const objDate = (date) => {
  let dateString = new Date(date)
    .toLocaleString("pt-BR", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
    .replace(/\//g, "/");

  return dateString;
};

const objDateFull = (date) => {
  let dateString = new Date(date)
    .toLocaleString("pt-BR", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    })
    .replace(/\//g, "/");

  return dateString;
};

const formatted = { listItem, onlyDate, objDate, objDateFull };

export default formatted;
