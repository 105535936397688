// react
import React from "react";

// material
import { Box } from "@mui/material";

// style
import { Label, InputFile } from "../styles";

// img
import imgFile from "../../../../../assets/img/file.png";

export function File(props) {
  const { label, accept, onChange } = props;

  return (
    <React.Fragment>
      <Label className="thumbnail">
        <InputFile type="file" onChange={onChange} accept={accept} />

        <Box textAlign="center" pt={1}>
          <Box component="img" src={imgFile} alt="file" />
          <Box my={1}>{label}</Box>
        </Box>
      </Label>
    </React.Fragment>
  );
}
