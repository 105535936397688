// react
import React, { useState, useEffect } from "react";

// router
import { useSearchParams } from "react-router-dom";

// material
import { Box, Tabs, Tab } from "@mui/material";

// components
import { Sidebar } from "../../../components/app/Sidebar";
import { ContentMain } from "../../../components/app/Content/Main";
import { a11yProps, TabPanel } from "../../../components/app/Tabs/TabPanel";

// components page
import { Home } from "./menu/Home";
import { Employee } from "./menu/Employee";
import { Unit } from "./menu/Unit";
import { Medicament } from "./menu/Medicament";
import { Product } from "./menu/Product";
import { Occupation } from "./menu/Occupation";

// styles
import { classes, ContentVerticalTabs } from "../styles";

const menuTab = [
  {
    id: 0,
    code: "apps-home",
    label: "Residência",
    element: <Home />,
  },
  {
    id: 1,
    code: "apps-occupation",
    label: "Ocupações",
    element: <Occupation />,
  },
  {
    id: 2,
    code: "apps-employee",
    label: "Profissional",
    element: <Employee />,
  },
  {
    id: 3,
    code: "apps-unit",
    label: "Unidade",
    element: <Unit />,
  },
  {
    id: 4,
    code: "apps-medicament",
    label: "Medicamentos",
    element: <Medicament />,
  },
  {
    id: 5,
    code: "apps-product",
    label: "Insumos",
    element: <Product />,
  },
];

export const Residence = () => {
  const [searchParams] = useSearchParams();

  // states
  const [menu, setMenu] = useState(0);

  // effects
  useEffect(() => {
    const redirect = searchParams.get("type");

    if (redirect) {
      var tab = 0;

      menuTab.map(function (item, index) {
        if (item.code === redirect) {
          tab = index;
          return true;
        } else {
          return false;
        }
      });

      handleChangeMenu(() => {}, tab);
    }
  }, [searchParams]);

  // functions
  const handleChangeMenu = (event, newValue) => {
    setMenu(newValue);
  };

  const clearType = () => {
    window.history.pushState({}, document.title, window.location.pathname);
  };

  return (
    <Sidebar>
      <ContentMain>
        <ContentVerticalTabs>
          <Box className={classes.root}>
            <Box>
              <Box>
                <Tabs
                  orientation="horizontal"
                  value={menu}
                  onChange={handleChangeMenu}
                  aria-label="Settings Menu"
                  classes={{
                    root: classes.tab,
                    flexContainer: classes.containerTab,
                    indicator: classes.indicatorTab,
                  }}
                >
                  {menuTab.map(function (item, index) {
                    return (
                      <Tab
                        key={index}
                        classes={{
                          selected: classes.tabSelected,
                        }}
                        label={item.label}
                        {...a11yProps(item.id)}
                        onClick={clearType}
                      />
                    );
                  })}
                </Tabs>
              </Box>
            </Box>

            <Box width="100%">
              {menuTab.map(function (item, index) {
                return (
                  <TabPanel key={index} value={menu} index={item.id}>
                    {item.element}
                  </TabPanel>
                );
              })}
            </Box>
          </Box>
        </ContentVerticalTabs>
      </ContentMain>
    </Sidebar>
  );
};
