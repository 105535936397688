// react
import React, { useState, useEffect, useCallback } from "react";

// router
import { useNavigate } from "react-router-dom";

// material
import { Box, Typography, Grid, Hidden, Alert, Tooltip } from "@mui/material";

// icons
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

// components
import { Sidebar } from "../../../../components/app/Sidebar";
import { TitleMain } from "../../../../components/app/Title/Main";
import { FormControl } from "../../../../components/app/Form/FormControl";
import { Controls } from "../../../../components/app/Form/Controls";
import { ContentPaper } from "../../../../components/app/Content/Paper";
import { ContentMain } from "../../../../components/app/Content/Main";
import { StepForm } from "../../../../components/app/Step/Form";

// util
import util from "../util";

import {
  genero_itens,
  nacionalidade_itens,
  estado_civil_itens,
  estado_itens,
} from "../../../../util/itens";

import {
  cpfMask,
  rgMask,
  phoneMask,
  numberMask,
  cepMask,
} from "../../../../util/mask";

const steps = ["Dados pessoais", "Endereço", "Contato", "Residência"];

export const CreateResident = () => {
  // router
  const navigate = useNavigate();

  // state
  const [messageError, setMessageError] = useState("");

  // state-form
  const [nome, setNome] = useState("");
  const [cpf, setCpf] = useState("");
  const [rg, setRg] = useState("");
  const [genero, setGenero] = useState("");
  const [nacionalidade, setNacionalidade] = useState("");
  const [estado_civil, setEstado_civil] = useState("");
  const [dt_nascimento, setDt_nascimento] = useState(
    new Date().toISOString().substring(0, 10)
  );
  const [observacoes, setObservacoes] = useState("");
  const [email, setEmail] = useState("");
  const [telefone1, setTelefone1] = useState("");
  const [telefone2, setTelefone2] = useState("");
  const [cep, setCep] = useState("");
  const [logradouro, setLogradouro] = useState("");
  const [bairro, setBairro] = useState("");
  const [numero, setNumero] = useState("");
  const [estado, setEstado] = useState("");
  const [cidade, setCidade] = useState("");
  const [complemento, setComplemento] = useState("");
  const [id_unidade, setId_unidade] = useState("");

  // state-util
  const [unidade, setUnidade] = useState([]);

  // state-step
  const [activeStep, setActiveStep] = useState(0);
  const [actionStepButton, setActionStepButton] = useState(false);

  const load = useCallback(async () => {
    let unmounted = false;

    try {
      await util.UserInfo().then(async (userResponse) => {
        let { result } = await userResponse;

        if (result.id_empresa) {
          const { id_empresa } = result;

          await util.ListUnity(id_empresa).then((response) => {
            let result = response;

            if (!unmounted) {
              let objValue = result.length > 0 ? result[0].value : "";

              setId_unidade(objValue);
              setUnidade(result);
            }
          });
        }
      });
    } catch (error) {
      setMessageError(error.message);
    }

    return () => (unmounted = true);
  }, []);

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) load();

    return () => (unmounted = true);
  }, [load]);

  // functions
  const handleSubmit = async (event) => {
    event.preventDefault();

    setActionStepButton(true);
    setMessageError("");

    document.getElementById("page").scrollTo(0, 0);

    if (validate()) {
      await util
        .Create({
          // dados pessoais
          nome: nome,
          cpf: cpf,
          rg: rg,
          genero: genero,
          nacionalidade: nacionalidade,
          estado_civil: estado_civil,
          dt_nascimento: dt_nascimento,
          observacoes: observacoes,

          // contato
          email: email,
          telefone1: telefone1,
          telefone2: telefone2,

          // endereco
          cep: cep,
          logradouro: logradouro,
          bairro: bairro,
          numero: numero,
          estado: estado,
          cidade: cidade,
          complemento: complemento,

          // residencia
          id_unidade: id_unidade,
        })
        .catch((error) => {
          setActionStepButton(false);
          setMessageError("Ocorreu um erro ao salvar.");
        })
        .finally(() => {
          goList();
        });
    } else {
      setActionStepButton(false);
      setMessageError("Por favor, corrija os campos em vermelho.");
    }
  };

  const validate = () => {
    return true;
  };

  // redirect
  const goList = () => {
    navigate(`/residente`);
  };

  const goRefresh = () => {
    window.location.reload();
  };

  // components
  const MenuIcons = () => {
    return (
      <Box my={2}>
        <Tooltip title="Voltar">
          <IconButton aria-label="voltar" size="large" onClick={goList}>
            <ArrowBackIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title="Salvar">
          <IconButton aria-label="salvar" size="large" onClick={handleSubmit}>
            <SaveIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title="Limpar formulário">
          <IconButton aria-label="limpar" size="large" onClick={goRefresh}>
            <RestartAltIcon />
          </IconButton>
        </Tooltip>
      </Box>
    );
  };

  return (
    <Sidebar>
      <ContentMain>
        <Hidden mdUp>
          <Box textAlign="end" mb={3}>
            <MenuIcons />
          </Box>
        </Hidden>

        <Grid container spacing={0}>
          <Grid item xs={12} md={8}>
            <ContentPaper>
              <Box sx={{ width: "100%" }} mt={5}>
                <StepForm
                  steps={steps}
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                  action={handleSubmit}
                  actionLabel="Salvar"
                  actionStepButton={actionStepButton}
                >
                  <FormControl>
                    {activeStep === 0 ? (
                      <Grid
                        container
                        spacing={1}
                        sx={{
                          py: 4,
                        }}
                      >
                        <Grid item xs={12}>
                          <Controls.Input
                            label="Nome completo"
                            placeholder="..."
                            id="nome"
                            name="nome"
                            type="text"
                            length={150}
                            value={nome}
                            onChange={(event) => setNome(event.target.value)}
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>

                        <Grid item xs={12} lg={6}>
                          <Controls.Input
                            label="CPF"
                            placeholder="..."
                            id="cpf"
                            name="cpf"
                            type="text"
                            length={14}
                            value={cpf}
                            onChange={(event) =>
                              setCpf(cpfMask(event.target.value))
                            }
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>

                        <Grid item xs={12} lg={6}>
                          <Controls.Input
                            label="RG"
                            placeholder="..."
                            id="rg"
                            name="rg"
                            type="text"
                            length={15}
                            value={rg}
                            onChange={(event) =>
                              setRg(rgMask(event.target.value))
                            }
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Controls.Select
                            label="Gênero"
                            name="genero"
                            id="genero"
                            items={genero_itens}
                            value={genero}
                            onChange={(event) => setGenero(event.target.value)}
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Controls.Select
                            label="Nacionalidade"
                            name="nacionalidade"
                            id="nacionalidade"
                            items={nacionalidade_itens}
                            value={nacionalidade}
                            onChange={(event) =>
                              setNacionalidade(event.target.value)
                            }
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Controls.Select
                            label="Estado Civil"
                            name="estado_civil"
                            id="estado_civil"
                            items={estado_civil_itens}
                            value={estado_civil}
                            onChange={(event) =>
                              setEstado_civil(event.target.value)
                            }
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Controls.Input
                            label="Data de nascimento"
                            id="dt_nascimento"
                            name="dt_nascimento"
                            type="date"
                            value={dt_nascimento}
                            onChange={(event) =>
                              setDt_nascimento(event.target.value)
                            }
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Controls.TextArea
                            label="Observações"
                            placeholder="..."
                            id="observacoes"
                            rows={4}
                            name="observacoes"
                            value={observacoes}
                            onChange={(event) =>
                              setObservacoes(event.target.value)
                            }
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>
                      </Grid>
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}

                    {activeStep === 1 ? (
                      <Grid
                        container
                        spacing={1}
                        sx={{
                          py: 4,
                        }}
                      >
                        <Grid item xs={12}>
                          <Controls.Input
                            label="CEP"
                            placeholder="..."
                            id="cep"
                            name="cep"
                            type="text"
                            length={9}
                            value={cep}
                            onChange={(event) =>
                              setCep(cepMask(event.target.value))
                            }
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Controls.Input
                            label="Logradouro"
                            placeholder="..."
                            id="logradouro"
                            name="logradouro"
                            type="text"
                            length={150}
                            value={logradouro}
                            onChange={(event) =>
                              setLogradouro(event.target.value)
                            }
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>

                        <Grid item xs={8}>
                          <Controls.Input
                            label="Bairro"
                            placeholder="..."
                            id="bairro"
                            name="bairro"
                            type="text"
                            length={150}
                            value={bairro}
                            onChange={(event) => setBairro(event.target.value)}
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>

                        <Grid item xs={4}>
                          <Controls.Input
                            label="Número"
                            placeholder="..."
                            id="numero"
                            name="numero"
                            type="text"
                            length={10}
                            value={numero}
                            onChange={(event) =>
                              setNumero(numberMask(event.target.value))
                            }
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Controls.Select
                            label="Estado"
                            placeholder="..."
                            name="estado"
                            id="estado"
                            items={estado_itens}
                            value={estado}
                            onChange={(event) => setEstado(event.target.value)}
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Controls.Input
                            label="Cidade"
                            placeholder="..."
                            id="cidade"
                            name="cidade"
                            type="text"
                            length={150}
                            value={cidade}
                            onChange={(event) => setCidade(event.target.value)}
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Controls.Input
                            label="Complemento"
                            placeholder="..."
                            id="complemento"
                            name="complemento"
                            type="text"
                            length={150}
                            value={complemento}
                            onChange={(event) =>
                              setComplemento(event.target.value)
                            }
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>
                      </Grid>
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}

                    {activeStep === 2 ? (
                      <Grid
                        container
                        spacing={1}
                        sx={{
                          py: 4,
                        }}
                      >
                        <Grid item xs={12}>
                          <Controls.Input
                            label="E-mail"
                            placeholder="..."
                            id="email"
                            name="email"
                            type="email"
                            length={150}
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>

                        <Grid item xs={12} lg={6}>
                          <Controls.Input
                            label="Celular"
                            placeholder="..."
                            id="telefone1"
                            name="telefone1"
                            type="text"
                            length={15}
                            value={telefone1}
                            onChange={(event) =>
                              setTelefone1(phoneMask(event.target.value))
                            }
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>

                        <Grid item xs={12} lg={6}>
                          <Controls.Input
                            label="Telefone"
                            placeholder="..."
                            id="telefone2"
                            name="telefone2"
                            type="text"
                            length={15}
                            value={telefone2}
                            onChange={(event) =>
                              setTelefone2(phoneMask(event.target.value))
                            }
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>
                      </Grid>
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}

                    {activeStep === 3 ? (
                      <Grid
                        container
                        spacing={1}
                        sx={{
                          py: 4,
                        }}
                      >
                        <Grid item xs={12}>
                          <Controls.Select
                            label="Unidade"
                            placeholder="..."
                            name="id_unidade"
                            id="id_unidade"
                            items={unidade}
                            value={id_unidade}
                            onChange={(event) =>
                              setId_unidade(event.target.value)
                            }
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>
                      </Grid>
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}
                  </FormControl>
                </StepForm>
              </Box>
            </ContentPaper>
          </Grid>

          <Grid item xs={12} md={4} px={4}>
            <Hidden mdDown>
              <Box mt={5}>
                <TitleMain>Novo Residente</TitleMain>
                <Typography component="h2" variant="body2">
                  Preencha o formulário para continuar.
                </Typography>

                {messageError ? (
                  <Alert
                    severity="error"
                    sx={{
                      my: 2,
                    }}
                  >
                    {messageError}
                  </Alert>
                ) : (
                  <React.Fragment></React.Fragment>
                )}

                <MenuIcons />
              </Box>
            </Hidden>
          </Grid>
        </Grid>
      </ContentMain>
    </Sidebar>
  );
};
