// react
import React, { useState } from "react";

// router
// import { useNavigate } from "react-router-dom";

// material
import { Box, Grid, Typography } from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { styled } from "@mui/material/styles";

// icons
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";

// components
import { Sidebar } from "../../../components/app/Sidebar";
import { ContentMain } from "../../../components/app/Content/Main";
import { ContentPaper } from "../../../components/app/Content/Paper";
import { TitleMain } from "../../../components/app/Title/Main";

const videos = [
  {
    id: 1,
    expanded: true,
    title: "Apresentação Geral do Sistema",
    link: "https://player.vimeo.com/video/746879130",
  },
  {
    id: 2,
    expanded: false,
    title: "Como funciona o menu de residente?",
    link: "https://player.vimeo.com/video/746873819",
  },
  {
    id: 3,
    expanded: false,
    title: "Como funciona o menu de residência?",
    link: "https://player.vimeo.com/video/746873722",
  },
  {
    id: 4,
    expanded: false,
    title: "Como funciona o menu de relatórios?",
    link: "https://player.vimeo.com/video/746873901",
  },
  {
    id: 5,
    expanded: false,
    title: "Como funciona o menu de opções?",
    link: "https://player.vimeo.com/video/746875428",
  },
];

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export const CardAccordion = (props) => {
  const { expanded, title, link } = props;

  const [open, setOpen] = useState(expanded);

  const handleChange = () => {
    setOpen(open ? false : true);
  };

  return (
    <Accordion expanded={open} onChange={handleChange}>
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <Typography>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box textAlign="center">
          <iframe
            src={link}
            style={{
              width: "100%",
              height: "300px",
            }}
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
          ></iframe>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export const Training = () => {
  return (
    <Sidebar>
      <ContentMain>
        <ContentPaper>
          <Box component="main">
            <TitleMain>Treinamentos</TitleMain>
            <Box>
              <Box my={5}>
                {videos.map((item, index) => (
                  <CardAccordion
                    key={index}
                    id={index}
                    expanded={item.expanded}
                    title={item.title}
                    link={item.link}
                  />
                ))}
              </Box>
            </Box>
          </Box>
        </ContentPaper>
      </ContentMain>
    </Sidebar>
  );
};
