// react
import React, { useState, useEffect, useCallback } from "react";

// router
import { useNavigate, useLocation } from "react-router-dom";

// material
import { Box, List, ListItem, ListItemText } from "@mui/material";

// icons
import DashboardIcon from "@mui/icons-material/Dashboard";
import FaceIcon from "@mui/icons-material/Face";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import VideoSettingsIcon from "@mui/icons-material/VideoSettings";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import ListItemIcon from "@mui/material/ListItemIcon";

// styles
import { classes } from "../../styles";

// util
import util from "../../util";
import util_permission from "../../../../../util/allowed";

export const MenuDrawer = ({ open }) => {
  // router
  const navigate = useNavigate();
  const location = useLocation();

  // states
  const [itemsMain, setItemsMain] = useState(null);

  // effects
  const load = useCallback(async () => {
    let unmounted = false;

    let result_user_data = util.UserData();

    let menu = {
      items: [],
    };

    if (result_user_data && result_user_data.gestor) {
      menu.items.push(
        {
          path: "/inicio",
          icon: DashboardIcon,
          label: "Inicio",
        },
        {
          path: "/residente",
          icon: FaceIcon,
          label: "Residentes",
        },
        {
          path: "/residencia",
          icon: HomeWorkIcon,
          label: "Residência",
        },
        {
          path: "/relatorio",
          icon: LeaderboardIcon,
          label: "Relatórios",
        },
        {
          path: "/configuracoes",
          icon: SettingsIcon,
          label: "Opções",
        },
        {
          path: "/treinamento",
          icon: VideoSettingsIcon,
          label: "Treinamentos",
        },
        {
          path: "/logout",
          icon: LogoutIcon,
          label: "Logout",
        }
      );
    } else {
      await util.UserInfo().then(async (user_response) => {
        let { result } = await user_response;

        if (result.id_profissional) {
          const { id_profissional } = result;

          await util
            .PermissoesProfissional(id_profissional)
            .then(async (response) => {
              let { result } = await response;

              if (!unmounted) {
                menu.items.push({
                  path: "/inicio",
                  icon: DashboardIcon,
                  label: "Inicio",
                });

                if (util_permission.allowed("6fbf3df28cc0", true, result)) {
                  menu.items.push({
                    path: "/residente",
                    icon: FaceIcon,
                    label: "Residentes",
                  });
                }

                if (util_permission.allowed("6fbf3df28c27", true, result)) {
                  menu.items.push({
                    path: "/residencia",
                    icon: HomeWorkIcon,
                    label: "Residência",
                  });
                }

                if (util_permission.allowed("6fbf3df28c49", true, result)) {
                  menu.items.push({
                    path: "/relatorio",
                    icon: LeaderboardIcon,
                    label: "Relatórios",
                  });
                }

                menu.items.push({
                  path: "/configuracoes",
                  icon: SettingsIcon,
                  label: "Opções",
                });

                menu.items.push({
                  path: "/treinamento",
                  icon: VideoSettingsIcon,
                  label: "Treinamentos",
                });

                menu.items.push({
                  path: "/logout",
                  icon: LogoutIcon,
                  label: "Logout",
                });
              }
            });
        }
      });
    }

    setItemsMain(menu);

    return () => (unmounted = true);
  }, []);

  useEffect(() => {
    let unmounted = false;

    if (!unmounted) load();

    return () => (unmounted = true);
  }, [load]);

  // functions
  const activeClass = (current, path) => {
    let result = current.indexOf(path) !== -1;

    return result;
  };

  return (
    <Box>
      <Box
        sx={{
          height: "calc(100vh - 38px)",
        }}
      >
        <List sx={{ paddingTop: 0, paddingBottom: 0 }}>
          {itemsMain ? (
            itemsMain.items.map(function (item, index) {
              return (
                <ListItem
                  key={index}
                  onClick={() => navigate(item.path)}
                  button
                  classes={{
                    root: classes.listItem,
                    selected: classes.active,
                  }}
                  selected={
                    activeClass(location.pathname, item.path) ? true : false
                  }
                >
                  <ListItemIcon classes={{ root: classes.listIcon }}>
                    <item.icon />
                  </ListItemIcon>

                  <ListItemText
                    classes={{ secondary: classes.listText }}
                    secondary={item.label}
                  />
                </ListItem>
              );
            })
          ) : (
            <React.Fragment></React.Fragment>
          )}
        </List>
      </Box>
    </Box>
  );
};
