import { oauth } from "../../../services/oauth";

import { getData } from "../../../util/oauth";

const UserData = () => {
  try {
    const user_data = getData();

    return user_data;
  } catch (error) {
    return error;
  }
};

const UserInfo = async (data) => {
  try {
    let response = await oauth.get("/oauth/token");
    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};

const PermissoesProfissional = async (id_profissional) => {
  try {
    let response = await oauth.get("/permissoes-profissional/colecao", {
      headers: { id_profissional },
    });

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};

const util = {
  UserData,
  UserInfo,
  PermissoesProfissional,
};

export default util;
