const genero_itens = [
  { value: "", title: "Selecione..." },
  { value: "0000", title: "Masculino" },
  { value: "0001", title: "Feminino" },
  { value: "0003", title: "Outro" },
];

const nacionalidade_itens = [
  { value: "", title: "Selecione..." },
  { title: "Brasileira", value: "0022" },
  { title: "Naturalizado Brasileiro", value: "0021" },
  { title: "Argentino", value: "0020" },
  { title: "Boliviano", value: "0019" },
  { title: "Chileno", value: "0018" },
  { title: "Paraguaio", value: "0017" },
  { title: "Uruguaio", value: "0016" },
  { title: "Alemão", value: "0015" },
  { title: "Belga", value: "0014" },
  { title: "Britânico", value: "0013" },
  { title: "Espanhol", value: "0012" },
  { title: "Norte-americano (EUA)", value: "0011" },
  { title: "Francês", value: "0010" },
  { title: "Suíço", value: "0009" },
  { title: "Italiano", value: "0008" },
  { title: "Japonês", value: "0007" },
  { title: "Chinês", value: "0006" },
  { title: "Coreano", value: "0005" },
  { title: "Português", value: "0004" },
  { title: "Angolano", value: "0003" },
  { title: "Holandesa", value: "0002" },
  { title: "Peruana", value: "0001" },
  { title: "Haitiana", value: "0000" },
];

const estado_civil_itens = [
  { value: "", title: "Selecione..." },
  { title: "União estável", value: "0006" },
  { title: "Solteiro(a)", value: "0005" },
  { title: "Casado(a)", value: "0004" },
  { title: "Separado(a) judicialmente", value: "0003" },
  { title: "Divorciado(a)", value: "0002" },
  { title: "Viúvo(a)", value: "0001" },
  { title: "Prefiro não informar", value: "0000" },
];

const estado_itens = [
  { value: "", title: "Selecione..." },
  { value: "AC", title: "Acre" },
  { value: "AL", title: "Alagoas" },
  { value: "AP", title: "Amapá" },
  { value: "AM", title: "Amazonas" },
  { value: "BA", title: "Bahia" },
  { value: "CE", title: "Ceará" },
  { value: "DF", title: "Distrito Federal" },
  { value: "ES", title: "Espírito Santo" },
  { value: "GO", title: "Goiás" },
  { value: "MA", title: "Maranhão" },
  { value: "MT", title: "Mato Grosso" },
  { value: "MS", title: "Mato Grosso do Sul" },
  { value: "MG", title: "Minas Gerais" },
  { value: "PA", title: "Pará" },
  { value: "PB", title: "Paraíba" },
  { value: "PR", title: "Paraná" },
  { value: "PE", title: "Pernambuco" },
  { value: "PI", title: "Piauí" },
  { value: "RJ", title: "Rio de Janeiro" },
  { value: "RN", title: "Rio Grande do Norte" },
  { value: "RS", title: "Rio Grande do Sul" },
  { value: "RO", title: "Rondônia" },
  { value: "RR", title: "Roraima" },
  { value: "SC", title: "Santa Catarina" },
  { value: "SP", title: "São Paulo" },
  { value: "SE", title: "Sergipe" },
  { value: "TO", title: "Tocantins" },
];

const medida_itens = [
  { value: "", title: "Selecione..." },
  { value: "0000", title: "Adesivo" },
  { value: "0001", title: "Ampola" },
  { value: "0002", title: "Aplicação" },
  { value: "0003", title: "Colher de chá" },
  { value: "0004", title: "Colher de sopa" },
  { value: "0005", title: "Comprimido" },
  { value: "0006", title: "Copo" },
  { value: "0007", title: "Frascos" },
  { value: "0008", title: "Gotas" },
];

const unidade_itens = [
  { value: "", title: "Selecione..." },
  { value: "0000", title: "Não informado" },
  { value: "0001", title: "g" },
  { value: "0002", title: "g/10ml" },
  { value: "0003", title: "g/1ml" },
  { value: "0004", title: "g/25ml" },
];

const funcao_itens = [
  { value: "", title: "Selecione..." },
  // { value: "0000", title: "Gestor" },
  { value: "0001", title: "Terapeuta ocupacional" },
  { value: "0002", title: "Nutricionista" },
  { value: "0003", title: "Psicólogo" },
  { value: "0004", title: "Enfermeiro" },
  { value: "0005", title: "Técnico de enfermagem" },
  { value: "0006", title: "Alguns tem farmacêutico e serviço social" },
  { value: "0007", title: "Cuidador de idoso" },
  { value: "0008", title: "Auxiliar de Serviços Gerais" },
  { value: "0009", title: "Cozinheira" },
  { value: "0010", title: "Fisioterapia " },
];

const tipo_sanguineo_itens = [
  { value: "", title: "Selecione..." },
  { value: "0000", title: "A+" },
  { value: "0001", title: "A-" },
  { value: "0002", title: "B+" },
  { value: "0003", title: "B-" },
  { value: "0004", title: "AB+" },
  { value: "0005", title: "AB-" },
  { value: "0006", title: "O+" },
  { value: "0007", title: "O-" },
];

export {
  genero_itens,
  nacionalidade_itens,
  estado_civil_itens,
  estado_itens,
  medida_itens,
  unidade_itens,
  funcao_itens,
  tipo_sanguineo_itens,
};
