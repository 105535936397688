import { Routes, Route, Navigate } from "react-router-dom";

import { ManagerOAuth } from "../pages/OAuth/Login/Manager";
import { EmployeeOAuth } from "../pages/OAuth/Login/Employee";
import { FamilyOAuth } from "../pages/OAuth/Login/Family";

import { Logout } from "../pages/OAuth/Logout";

import { Home } from "../pages/Dashboard/Home";

import { Resident } from "../pages/Dashboard/Resident";
import { ResidentShow } from "../pages/Dashboard/Resident/Show";
import { ResidentBirthday } from "../pages/Dashboard/Resident/Birthday";
import { CreateResident } from "../pages/Dashboard/Resident/Create";

import { CreateResidentAnamnese } from "../pages/Dashboard/Resident/Create/MedicalRecords/Anamnese";
import { CreateResidentVitalSigns } from "../pages/Dashboard/Resident/Create/MedicalRecords/VitalSigns";
import { CreateResidentPhysicalExam } from "../pages/Dashboard/Resident/Create/MedicalRecords/PhysicalExam";
import { CreateResidentEvolution } from "../pages/Dashboard/Resident/Create/MedicalRecords/Evolution";

import { ResidentFamilyShow } from "../pages/Dashboard/Resident/Show/family";
import { CreateResidentFamily } from "../pages/Dashboard/Resident/Create/family";
import { CreateResidentWorkPlan } from "../pages/Dashboard/Resident/Create/workplan";
import { ResidentWorkPlanShow } from "../pages/Dashboard/Resident/Show/workplan";

import { Residence } from "../pages/Dashboard/Residence";

import { EmployeeShow } from "../pages/Dashboard/Residence/Show/employee";
import { CreateEmployee } from "../pages/Dashboard/Residence/menu/Employee/Create";

import { CreateOccupation } from "../pages/Dashboard/Residence/menu/Occupation/Create";
import { OccupationPermission } from "../pages/Dashboard/Residence/menu/Occupation/Permission";

import { UnitShow } from "../pages/Dashboard/Residence/Show/unit";
import { CreateUnit } from "../pages/Dashboard/Residence/menu/Unit/Create";

import { CreateMedicament } from "../pages/Dashboard/Residence/menu/Medicament/Create";
import { CreateProduct } from "../pages/Dashboard/Residence/menu/Product/Create";

import { Report } from "../pages/Dashboard/Report";
import { CreateReport } from "../pages/Dashboard/Report/Create";
import { ReportShow } from "../pages/Dashboard/Report/Show";

import { Training } from "../pages/Dashboard/Training";

import { Notifications } from "../pages/Dashboard/Notifications";

import { Settings } from "../pages/Dashboard/Settings";

import { OAuthRoutes } from "../components/routes/OAuthRoutes";
import { PrivateRoutes } from "../components/routes/PrivateRoutes";

export const AppRoutes = () => {
  return (
    <Routes>
      <Route element={<OAuthRoutes />}>
        <Route path="/login/empresa" element={<ManagerOAuth />} />
        <Route path="/login/profissional" element={<EmployeeOAuth />} />
        <Route path="/login/familiar" element={<FamilyOAuth />} />
      </Route>
      <Route path="/logout" element={<Logout />} />

      <Route element={<PrivateRoutes />}>
        <Route path="/inicio" element={<Home />} />

        <Route path="/residente" element={<Resident />} />
        <Route path="/residente/preview/:id" element={<ResidentShow />} />
        <Route path="/residente/novo" element={<CreateResident />} />
        <Route
          path="/residente/familiar/novo"
          element={<CreateResidentFamily />}
        />
        <Route
          path="/residente/familiar/:id"
          element={<ResidentFamilyShow />}
        />
        <Route
          path="/residente/plano-de-trabalho/novo"
          element={<CreateResidentWorkPlan />}
        />
        <Route
          path="/residente/plano-de-trabalho/:id"
          element={<ResidentWorkPlanShow />}
        />
        <Route
          path="/residente/anamnese/novo"
          element={<CreateResidentAnamnese />}
        />
        <Route
          path="/residente/sinais-vitais/novo"
          element={<CreateResidentVitalSigns />}
        />
        <Route
          path="/residente/exame-fisico/novo"
          element={<CreateResidentPhysicalExam />}
        />
        <Route
          path="/residente/evolucao/novo"
          element={<CreateResidentEvolution />}
        />

        <Route path="/residente/aniversario" element={<ResidentBirthday />} />

        <Route path="/residencia" element={<Residence />} />

        <Route
          path="/residencia/ocupacao/novo"
          element={<CreateOccupation />}
        />
        <Route
          path="/residencia/ocupacao/permissoes"
          element={<OccupationPermission />}
        />

        <Route
          path="/residencia/profissional/preview/:id"
          element={<EmployeeShow />}
        />
        <Route
          path="/residencia/profissional/novo"
          element={<CreateEmployee />}
        />
        <Route path="/residencia/unidade/preview/:id" element={<UnitShow />} />
        <Route path="/residencia/unidade/novo" element={<CreateUnit />} />
        <Route
          path="/residencia/medicamento/novo"
          element={<CreateMedicament />}
        />
        <Route path="/residencia/insumo/novo" element={<CreateProduct />} />

        <Route path="/relatorio" element={<Report />} />
        <Route path="/relatorio/novo" element={<CreateReport />} />
        <Route path="/relatorio/preview/:id" element={<ReportShow />} />

        <Route path="/residente/notificacoes" element={<Notifications />} />

        <Route path="/treinamento" element={<Training />} />

        <Route path="/configuracoes" element={<Settings />} />
      </Route>

      <Route path="*" element={<Navigate to="/login/empresa" />} />
    </Routes>
  );
};
