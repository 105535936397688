import { AppThemeProvider } from "./contexts/ThemeProvider";
import { AppAuthProvider } from "./contexts/AuthProvider";

import { BrowserRouter } from "react-router-dom";
import { AppRoutes } from "./routes";

export const App = () => {
  return (
    <AppThemeProvider>
      <AppAuthProvider>
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      </AppAuthProvider>
    </AppThemeProvider>
  );
};
