// react
import React, { useState, useEffect, useCallback } from "react";

// router
import { useNavigate } from "react-router-dom";

// material
import { Box, Typography, Grid, Hidden, Alert, Tooltip } from "@mui/material";

// icons
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

// components
import { Sidebar } from "../../../../../../components/app/Sidebar";
import { TitleMain } from "../../../../../../components/app/Title/Main";
import { FormControl } from "../../../../../../components/app/Form/FormControl";
import { Controls } from "../../../../../../components/app/Form/Controls";
import { ContentMain } from "../../../../../../components/app/Content/Main";
import { ContentPaper } from "../../../../../../components/app/Content/Paper";

// util
import util from "../../../util";

import {
  cnpjMask,
  phoneMask,
  numberMask,
  cepMask,
} from "../../../../../../util/mask";

import { estado_itens } from "../../../../../../util/itens";

// style
import { StepForm } from "../../../../../../components/app/Step/Form";

const steps = ["Fiscal", "Endereço", "Contato"];

export const CreateUnit = () => {
  // router
  const navigate = useNavigate();

  // state
  const [messageError, setMessageError] = useState("");

  // state-form
  const [cnpj, setCnpj] = useState("");
  const [razao_social, setRazao_social] = useState("");
  const [nome_fantasia, setNome_fantasia] = useState("");
  const [registro_estadual, setRegistro_estadual] = useState("");
  const [registro_municipal, setRegistro_municipal] = useState("");
  const [dt_fundacao, setDt_fundacao] = useState(
    new Date().toISOString().substring(0, 10)
  );
  const [observacoes, setObservacoes] = useState("");
  const [email, setEmail] = useState("");
  const [telefone1, setTelefone1] = useState("");
  const [telefone2, setTelefone2] = useState("");
  const [cep, setCep] = useState("");
  const [logradouro, setLogradouro] = useState("");
  const [bairro, setBairro] = useState("");
  const [numero, setNumero] = useState("");
  const [estado, setEstado] = useState("");
  const [cidade, setCidade] = useState("");
  const [complemento, setComplemento] = useState("");

  const [id_empresa, setId_empresa] = useState("");

  // state-step
  const [activeStep, setActiveStep] = useState(0);
  const [actionStepButton, setActionStepButton] = useState(false);

  // effects
  const load = useCallback(async () => {
    let unmounted = false;

    try {
      await util.UserInfo().then(async (userResponse) => {
        let { result } = await userResponse;

        if (result.id_gestor) {
          const { id_empresa } = result;

          if (!unmounted) {
            setId_empresa(id_empresa);
          }
        }
      });
    } catch (error) {
      setMessageError(error.message);
    }

    return () => (unmounted = true);
  }, []);

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) load();

    return () => (unmounted = true);
  }, [load]);

  // functions
  const validate = () => {
    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setActionStepButton(true);
    setMessageError("");

    document.getElementById("page").scrollTo(0, 0);

    if (validate()) {
      await util
        .CreateUnit({
          cnpj: cnpj,
          razao_social: razao_social,
          nome_fantasia: nome_fantasia,
          registro_estadual: registro_estadual,
          registro_municipal: registro_municipal,
          dt_fundacao: dt_fundacao,
          observacoes: observacoes,

          email: email,
          telefone1: telefone1,
          telefone2: telefone2,

          cep: cep,
          logradouro: logradouro,
          bairro: bairro,
          numero: numero,
          estado: estado,
          cidade: cidade,
          complemento: complemento,

          id_empresa: id_empresa,
        })
        .catch((error) => {
          setActionStepButton(false);
          setMessageError("Ocorreu um erro ao salvar.");
        })
        .finally(() => {
          goList();
        });
    } else {
      setActionStepButton(false);
      setMessageError("Por favor, corrija os campos em vermelho.");
    }
  };

  // redirect
  const goList = () => {
    navigate(`/residencia?type=apps-unit`);
  };

  const goRefresh = () => {
    window.location.reload();
  };

  // components
  const MenuIcons = () => {
    return (
      <Box my={2}>
        <Tooltip title="Voltar">
          <IconButton aria-label="voltar" size="large" onClick={goList}>
            <ArrowBackIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title="Salvar">
          <IconButton aria-label="salvar" size="large" onClick={handleSubmit}>
            <SaveIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title="Limpar formulário">
          <IconButton aria-label="limpar" size="large" onClick={goRefresh}>
            <RestartAltIcon />
          </IconButton>
        </Tooltip>
      </Box>
    );
  };

  return (
    <Sidebar>
      <ContentMain>
        <Hidden mdUp>
          <Box textAlign="end" mb={3}>
            <MenuIcons />
          </Box>
        </Hidden>

        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <ContentPaper>
              <Box sx={{ width: "100%" }} mt={5}>
                <StepForm
                  steps={steps}
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                  action={handleSubmit}
                  actionLabel="Salvar"
                  actionStepButton={actionStepButton}
                >
                  <FormControl>
                    {activeStep === 0 ? (
                      <Grid
                        container
                        spacing={1}
                        sx={{
                          py: 4,
                        }}
                      >
                        <Grid item xs={12}>
                          <Controls.Input
                            label="CNPJ"
                            placeholder="..."
                            id="cnpj"
                            name="cnpj"
                            type="text"
                            length={18}
                            value={cnpj}
                            onChange={(event) =>
                              setCnpj(cnpjMask(event.target.value))
                            }
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Controls.Input
                            label="Razão social"
                            placeholder="..."
                            id="razao_social"
                            name="razao_social"
                            type="text"
                            length={150}
                            value={razao_social}
                            onChange={(event) =>
                              setRazao_social(event.target.value)
                            }
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Controls.Input
                            label="Nome fantasia"
                            placeholder="..."
                            id="nome_fantasia"
                            name="nome_fantasia"
                            type="text"
                            length={150}
                            value={nome_fantasia}
                            onChange={(event) =>
                              setNome_fantasia(event.target.value)
                            }
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>

                        <Grid item xs={12} lg={6}>
                          <Controls.Input
                            label="Registro municipal"
                            placeholder="..."
                            id="registro_municipal"
                            name="registro_municipal"
                            type="text"
                            length={150}
                            value={registro_municipal}
                            onChange={(event) =>
                              setRegistro_municipal(event.target.value)
                            }
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>

                        <Grid item xs={12} lg={6}>
                          <Controls.Input
                            label="Registro estadual"
                            placeholder="..."
                            id="registro_estadual"
                            name="registro_estadual"
                            type="text"
                            length={150}
                            value={registro_estadual}
                            onChange={(event) =>
                              setRegistro_estadual(event.target.value)
                            }
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Controls.Input
                            label="Data de fundação"
                            id="dt_fundacao"
                            name="dt_fundacao"
                            type="date"
                            value={dt_fundacao}
                            onChange={(event) =>
                              setDt_fundacao(event.target.value)
                            }
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Controls.TextArea
                            label="Observações"
                            placeholder="..."
                            id="observacoes"
                            rows={4}
                            name="observacoes"
                            value={observacoes}
                            onChange={(event) =>
                              setObservacoes(event.target.value)
                            }
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>
                      </Grid>
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}

                    {activeStep === 1 ? (
                      <Grid
                        container
                        spacing={1}
                        sx={{
                          py: 4,
                        }}
                      >
                        <Grid item xs={12}>
                          <Controls.Input
                            label="CEP"
                            placeholder="..."
                            id="cep"
                            name="cep"
                            type="text"
                            length={9}
                            value={cep}
                            onChange={(event) =>
                              setCep(cepMask(event.target.value))
                            }
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Controls.Input
                            label="Logradouro"
                            placeholder="..."
                            id="logradouro"
                            name="logradouro"
                            type="text"
                            length={150}
                            value={logradouro}
                            onChange={(event) =>
                              setLogradouro(event.target.value)
                            }
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>

                        <Grid item xs={8}>
                          <Controls.Input
                            label="Bairro"
                            placeholder="..."
                            id="bairro"
                            name="bairro"
                            type="text"
                            length={150}
                            value={bairro}
                            onChange={(event) => setBairro(event.target.value)}
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>

                        <Grid item xs={4}>
                          <Controls.Input
                            label="Número"
                            placeholder="..."
                            id="numero"
                            name="numero"
                            type="text"
                            length={10}
                            value={numero}
                            onChange={(event) =>
                              setNumero(numberMask(event.target.value))
                            }
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Controls.Select
                            label="Estado"
                            placeholder="..."
                            name="estado"
                            id="estado"
                            items={estado_itens}
                            value={estado}
                            onChange={(event) => setEstado(event.target.value)}
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Controls.Input
                            label="Cidade"
                            placeholder="..."
                            id="cidade"
                            name="cidade"
                            type="text"
                            length={150}
                            value={cidade}
                            onChange={(event) => setCidade(event.target.value)}
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Controls.Input
                            label="Complemento"
                            placeholder="..."
                            id="complemento"
                            name="complemento"
                            type="text"
                            length={150}
                            value={complemento}
                            onChange={(event) =>
                              setComplemento(event.target.value)
                            }
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>
                      </Grid>
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}

                    {activeStep === 2 ? (
                      <Grid
                        container
                        spacing={1}
                        sx={{
                          py: 4,
                        }}
                      >
                        <Grid item xs={12}>
                          <Controls.Input
                            label="E-mail"
                            placeholder="..."
                            id="email"
                            name="email"
                            type="email"
                            length={150}
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>

                        <Grid item xs={12} lg={6}>
                          <Controls.Input
                            label="Celular"
                            placeholder="..."
                            id="telefone1"
                            name="telefone1"
                            type="text"
                            length={15}
                            value={telefone1}
                            onChange={(event) =>
                              setTelefone1(phoneMask(event.target.value))
                            }
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>

                        <Grid item xs={12} lg={6}>
                          <Controls.Input
                            label="Telefone"
                            placeholder="..."
                            id="telefone2"
                            name="telefone2"
                            type="text"
                            length={15}
                            value={telefone2}
                            onChange={(event) =>
                              setTelefone2(phoneMask(event.target.value))
                            }
                            style={{ marginBottom: "1.25rem" }}
                          />
                        </Grid>
                      </Grid>
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}
                  </FormControl>
                </StepForm>
              </Box>
            </ContentPaper>
          </Grid>

          <Grid item xs={12} md={4}>
            <Hidden mdDown>
              <Box mt={5}>
                <TitleMain>Nova Unidade</TitleMain>
                <Typography component="h2" variant="body2">
                  Preencha o formulário para continuar.
                </Typography>

                {messageError ? (
                  <Box
                    sx={{
                      pr: {
                        xs: 2,
                        sm: 4,
                      },
                    }}
                  >
                    <Alert
                      severity="error"
                      sx={{
                        my: 2,
                      }}
                    >
                      {messageError}
                    </Alert>
                  </Box>
                ) : (
                  <React.Fragment></React.Fragment>
                )}

                <MenuIcons />
              </Box>
            </Hidden>
          </Grid>
        </Grid>
      </ContentMain>
    </Sidebar>
  );
};
