import React from "react";

import { Box, Typography } from "@mui/material";

export const TitleMain = (props) => {
  const { children } = props;
  return (
    <Box
      my={4}
      p={1}
      sx={{
        borderLeft: (theme) => `5px solid ${theme.palette.primary.main}`,
      }}
    >
      <Typography component="h1" variant="h5" fontWeight={500} pl={1}>
        {children}
      </Typography>
    </Box>
  );
};
