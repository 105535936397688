// react
import React, { useState, useEffect, useCallback } from "react";

// router
import { useParams, useNavigate } from "react-router-dom";

// material
import { Box, Table, TableBody, Tooltip, Typography } from "@mui/material";

// icons
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

// components
import { Sidebar } from "../../../../components/app/Sidebar";
import { ContentMain } from "../../../../components/app/Content/Main";
import { ContentPaper } from "../../../../components/app/Content/Paper";
import { LoadingSquare } from "../../../../components/app/Loading/Square";
import { TitleMain } from "../../../../components/app/Title/Main";

// functions
import util from "../util";

// data
import formatted from "../../../../util/formatted";

import {
  StyledTableCell,
  StyledTableCellTitle,
  StyledTableRow,
} from "../components/styled/Table";
import baseURL from "../../../../config/app";

export const ReportShow = () => {
  let { id } = useParams();

  // router
  const navigate = useNavigate();

  const [row, setRow] = useState({});

  // const [messageError, setMessageError] = useState("");

  //   effects
  const load = useCallback(async () => {
    let unmounted = false;

    setRow(null);

    setTimeout(async () => {
      if (id) {
        await util.UserInfo().then(async (userResponse) => {
          let { result } = await userResponse;

          if (result.id_gestor) {
            let { id_empresa } = result;

            await util.Show(id, id_empresa).then((response) => {
              const { result } = response;

              if (result) {
                result.created_at =
                  formatted.objDateFull(result.created_at) || "";

                if (!unmounted) {
                  setRow(result);
                }
              } else {
                alert(
                  "Nenhum registro encontrado. Tente novamente mais tarde. Caso o problema persista contate nossa equipe."
                );
                navigate(`/relatorio`);
              }
            });
          } else {
            alert(
              "Nenhum registro encontrado. Tente novamente mais tarde. Caso o problema persista contate nossa equipe."
            );
            navigate(`/relatorio`);
          }
        });
      } else {
        alert(
          "Nenhum registro encontrado. Tente novamente mais tarde. Caso o problema persista contate nossa equipe."
        );
        navigate(`/relatorio`);
      }
    }, 500);

    return () => (unmounted = true);
  }, [navigate, id]);

  useEffect(() => {
    let unmounted = false;

    if (!unmounted) load();

    return () => (unmounted = true);
  }, [load, id]);

  // redirect
  const goList = () => {
    navigate(`/relatorio`);
  };

  return (
    <Sidebar>
      {row ? (
        <ContentMain>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="end"
            width="100%"
            mb={3}
          >
            <Tooltip title="Voltar">
              <IconButton
                onClick={goList}
                color="secondary"
                sx={{ p: "10px", mr: 1 }}
                aria-label="Voltar"
              >
                <ArrowBackIcon />
              </IconButton>
            </Tooltip>
          </Box>

          <ContentPaper>
            <Box mb={4}>
              <Box pb={4}>
                <TitleMain>Sobre</TitleMain>

                <Table sx={{ minWidth: "100%", pt: 2 }}>
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCellTitle align="left">
                        Título
                      </StyledTableCellTitle>

                      <StyledTableCell align="right">
                        {row.titulo ? row.titulo : "Não informado"}
                      </StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                      <StyledTableCellTitle align="left">
                        Descrição
                      </StyledTableCellTitle>

                      <StyledTableCell align="right">
                        {row.descricao ? row.descricao : "Não informado"}
                      </StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                      <StyledTableCellTitle align="left">
                        Responsável
                      </StyledTableCellTitle>

                      <StyledTableCell align="right">
                        {row.responsavel ? row.responsavel : "Não informado"}
                      </StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                      <StyledTableCellTitle align="left">
                        Data de criação
                      </StyledTableCellTitle>

                      <StyledTableCell align="right">
                        {row.created_at ? row.created_at : "Não informado"}
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </Box>

              <Box pb={4}>
                <TitleMain>Documento</TitleMain>

                {row.nome_arquivo ? (
                  <Box
                    sx={{
                      width: "100%",
                      minHeight: "800px",
                    }}
                    component="iframe"
                    src={baseURL + "/doc/relatorio/" + row.nome_arquivo}
                  ></Box>
                ) : (
                  <Box textAlign="center" py={5}>
                    <Typography>Nenhum arquivo foi cadastrado</Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </ContentPaper>
        </ContentMain>
      ) : (
        <Box
          px={2}
          textAlign="center"
          sx={{
            height: "calc(100vh - 65px - 38px)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoadingSquare />
        </Box>
      )}
    </Sidebar>
  );
};
