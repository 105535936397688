import { createTheme } from "@mui/material";
import { indigo } from "@mui/material/colors";

export const DarkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: indigo[400],
      dark: indigo[600],
      light: indigo[100],
    },
    secondary: {
      main: "#6c757d",
      dark: "#4b5157",
      light: "#899097",
    },
    ternary: {
      main: "#A60F4E",
      light: "#b73f71",
      dark: "#740a36",
    },
    success: {
      main: "#28a745",
      light: "#53b86a",
      dark: "#1c7430",
    },
    light: {
      main: "#f1f1f1",
      light: "#f3f3f3",
      dark: "#a8a8a8",
    },
    //   table: {
    //     active: indigo[400],
    //     background: indigo[50],
    //     selected: indigo[100],
    //   },
    bar: {
      main: "#212121",
      light: "#353535",
      dark: "",
      contrastText: "#5e5e5e",
    },
    table: {
      main: "#212121",
      light: "#353535",
      dark: "#1e1e1e",
    },
    drawer: {
      main: "#212121",
      light: "#353535",
      dark: "#1e1e1e",
      contrastText: "#5e5e5e",
    },
    background: {
      default: "#1e1e1e",
      paper: "#212121",
      document: "#212121",
    },
  },
});
