// react
import React, { useState, useEffect, useCallback } from "react";

// router
import { useParams, useNavigate } from "react-router-dom";

// functions
import util from "../../../util";

// material
import { Box, Table, TableBody, Typography, Button } from "@mui/material";

// components
import { ContentPaper } from "../../../../../../components/app/Content/Paper";
import { TitleMain } from "../../../../../../components/app/Title/Main";
import { Avatar } from "../../../../../../components/app/Avatar";
import { ContentPermission } from "../../../../../../components/app/Content/Permission";
import { LoadingSquare } from "../../../../../../components/app/Loading/Square";

// styled
import {
  StyledTableCell,
  StyledTableCellTitle,
  StyledTableRow,
} from "../../../components/styled/Table";

// assets
import apple from "../../../../../../assets/img/apple.png";
import ball from "../../../../../../assets/img/ball.png";
import music from "../../../../../../assets/img/music.png";
import pets from "../../../../../../assets/img/pets.png";
import love from "../../../../../../assets/img/love.png";
import hobby from "../../../../../../assets/img/hobby.png";

import happy from "../../../../../../assets/img/happy.png";
import sad from "../../../../../../assets/img/sad.png";
import marrying from "../../../../../../assets/img/marrying.png";
import cheeredup from "../../../../../../assets/img/cheered-up.png";
import anxious from "../../../../../../assets/img/anxious.png";
import quiet from "../../../../../../assets/img/quiet.png";

export const AffectiveRecord = () => {
  let { id } = useParams();

  // router
  const navigate = useNavigate();

  const [row, setRow] = useState(null);
  const [permissoes, setPermissoes] = useState([]);

  //   effects
  const load = useCallback(async () => {
    let unmounted = false;

    setRow(null);

    const goBackToTop = () => {
      alert(
        "Nenhum registro encontrado. Tente novamente mais tarde. Caso o problema persista contate nossa equipe."
      );
      navigate(`/residente`);
    };

    setTimeout(async () => {
      if (id) {
        await util.UserInfo().then(async (user_response) => {
          let { result } = await user_response;

          if (result.id_empresa) {
            const { id_empresa } = result;

            await util.Show(id, id_empresa).then((response) => {
              const { result } = response;

              if (result) {
                if (!unmounted) {
                  setRow(result);
                }
              } else {
                goBackToTop();
              }
            });
          } else {
            goBackToTop();
          }

          if (result.id_profissional) {
            const { id_profissional } = result;

            await util
              .PermissoesProfissional(id_profissional)
              .then(async (response) => {
                let { result } = response;

                if (!unmounted) {
                  setPermissoes(result);
                }
              });
          }
        });
      } else {
        goBackToTop();
      }
    }, 500);

    return () => (unmounted = true);
  }, [id, navigate]);

  useEffect(() => {
    let unmounted = false;

    if (!unmounted) load();

    return () => (unmounted = true);
  }, [load, id]);

  // redirect
  const goList = () => {
    navigate(`/residente`);
  };

  return (
    <Box>
      {row ? (
        <ContentPaper>
          <Box
            py={4}
            px={2}
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column",
                sm: "row",
              },
              alignItems: "center",
            }}
          >
            <Avatar
              name={row.nome}
              picture={row.foto}
              width="82px"
              height="82px"
            />

            <Box
              sx={{
                ml: {
                  xs: 0,
                  sm: 2,
                },
                my: {
                  xs: 2,
                  sm: 0,
                },
                textAlign: {
                  xs: "center",
                  sm: "end",
                },
                width: "100%",
              }}
            >
              <Typography variant="body1" fontWeight={500} component="div">
                Nome:{" "}
                <Typography variant="body2" fontWeight={400} component="span">
                  {row.nome ? row.nome : "Não informado"}
                </Typography>
              </Typography>

              <Box sx={{ my: 1.25 }} />

              <Typography variant="body1" fontWeight={500} component="div">
                Apelido:{" "}
                <Typography variant="body2" fontWeight={400} component="span">
                  {row.apelido ? row.apelido : "Não informado"}
                </Typography>
              </Typography>
            </Box>
          </Box>

          <Box mb={4}>
            <Box pb={4}>
              <TitleMain>Prontuário afetivo</TitleMain>

              <Table sx={{ minWidth: "100%", pt: 2 }}>
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCellTitle align="left">
                      Comida favorita
                    </StyledTableCellTitle>

                    <StyledTableCell align="right">
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "end",
                          width: "100%",
                        }}
                      >
                        <Box
                          src={apple}
                          component="img"
                          sx={{ height: "32px", mr: 1.5 }}
                        />{" "}
                        <Typography variant="body2" mt={0.25}>
                          Não informado
                        </Typography>
                      </Box>
                    </StyledTableCell>
                  </StyledTableRow>

                  <StyledTableRow>
                    <StyledTableCellTitle align="left">
                      Time do coração
                    </StyledTableCellTitle>

                    <StyledTableCell align="right">
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "end",
                          width: "100%",
                        }}
                      >
                        <Box
                          src={ball}
                          component="img"
                          sx={{ height: "32px", mr: 1.5 }}
                        />{" "}
                        <Typography variant="body2" mt={0.25}>
                          Não informado
                        </Typography>
                      </Box>
                    </StyledTableCell>
                  </StyledTableRow>

                  <StyledTableRow>
                    <StyledTableCellTitle align="left">
                      Estilo musical favorito
                    </StyledTableCellTitle>

                    <StyledTableCell align="right">
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "end",
                          width: "100%",
                        }}
                      >
                        <Box
                          src={music}
                          component="img"
                          sx={{ height: "32px", mr: 1.5 }}
                        />{" "}
                        <Typography variant="body2" mt={0.25}>
                          Não informado
                        </Typography>
                      </Box>
                    </StyledTableCell>
                  </StyledTableRow>

                  <StyledTableRow>
                    <StyledTableCellTitle align="left">
                      Animais de estimação
                    </StyledTableCellTitle>

                    <StyledTableCell align="right">
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "end",
                          width: "100%",
                        }}
                      >
                        <Box
                          src={pets}
                          component="img"
                          sx={{ height: "32px", mr: 1.5 }}
                        />{" "}
                        <Typography variant="body2" mt={0.25}>
                          Não informado
                        </Typography>
                      </Box>
                    </StyledTableCell>
                  </StyledTableRow>

                  <StyledTableRow>
                    <StyledTableCellTitle align="left">
                      Minha paixão
                    </StyledTableCellTitle>

                    <StyledTableCell align="right">
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "end",
                          width: "100%",
                        }}
                      >
                        <Box
                          src={love}
                          component="img"
                          sx={{ height: "32px", mr: 1.5 }}
                        />{" "}
                        <Typography variant="body2" mt={0.25}>
                          Não informado
                        </Typography>
                      </Box>
                    </StyledTableCell>
                  </StyledTableRow>

                  <StyledTableRow>
                    <StyledTableCellTitle align="left">
                      Passatempo favorito
                    </StyledTableCellTitle>

                    <StyledTableCell align="right">
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "end",
                          width: "100%",
                        }}
                      >
                        <Box
                          src={hobby}
                          component="img"
                          sx={{ height: "32px", mr: 1.5 }}
                        />{" "}
                        <Typography variant="body2" mt={0.25}>
                          Não informado
                        </Typography>
                      </Box>
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>

              <ContentPermission
                authorization="6fbf3df28c16"
                accept_manager={true}
                permissions={permissoes}
              >
                <Box textAlign="center" py={3}>
                  <Button variant="outlined">Editar</Button>
                </Box>
              </ContentPermission>
            </Box>

            <Box pb={4}>
              <TitleMain>Como está se sentindo hoje</TitleMain>

              <Box
                py={2}
                sx={{
                  display: "flex",
                  flexDirection: {
                    xs: "column",
                    md: "row",
                  },
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <Box mx={1} textAlign="center" sx={{ cursor: "pointer" }}>
                  <Box
                    src={happy}
                    component="img"
                    sx={{ height: "32px", mr: 1.5 }}
                  />
                  <Typography variant="body2" mt={0.25}>
                    Feliz
                  </Typography>
                </Box>

                <Box mx={1} textAlign="center" sx={{ cursor: "pointer" }}>
                  <Box
                    src={sad}
                    component="img"
                    sx={{ height: "32px", mr: 1.5 }}
                  />
                  <Typography variant="body2" mt={0.25}>
                    Triste
                  </Typography>
                </Box>

                <Box mx={1} textAlign="center" sx={{ cursor: "pointer" }}>
                  <Box
                    src={marrying}
                    component="img"
                    sx={{ height: "32px", mr: 1.5 }}
                  />
                  <Typography variant="body2" mt={0.25}>
                    Cansado
                  </Typography>
                </Box>

                <Box mx={1} textAlign="center" sx={{ cursor: "pointer" }}>
                  <Box
                    src={cheeredup}
                    component="img"
                    sx={{ height: "32px", mr: 1.5 }}
                  />
                  <Typography variant="body2" mt={0.25}>
                    Animado
                  </Typography>
                </Box>

                <Box mx={1} textAlign="center" sx={{ cursor: "pointer" }}>
                  <Box
                    src={anxious}
                    component="img"
                    sx={{ height: "32px", mr: 1.5 }}
                  />
                  <Typography variant="body2" mt={0.25}>
                    Ansioso
                  </Typography>
                </Box>

                <Box mx={1} textAlign="center" sx={{ cursor: "pointer" }}>
                  <Box
                    src={quiet}
                    component="img"
                    sx={{ height: "32px", mr: 1.5 }}
                  />
                  <Typography variant="body2" mt={0.25}>
                    Tranquilo
                  </Typography>
                </Box>
              </Box>

              <ContentPermission
                authorization="6fbf3df28c17"
                accept_manager={true}
                permissions={permissoes}
              >
                <Box textAlign="center" py={2}>
                  <Button variant="outlined">Ver histórico</Button>
                </Box>
              </ContentPermission>
            </Box>
          </Box>
        </ContentPaper>
      ) : (
        <Box
          py={4}
          px={2}
          textAlign="center"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoadingSquare />
        </Box>
      )}
    </Box>
  );
};
