import { oauth } from "../../../services/oauth";

import { getData } from "../../../util/oauth";

const UserData = () => {
  try {
    const user_data = getData();

    return user_data;
  } catch (error) {
    return error;
  }
};

const UserInfo = async (data) => {
  try {
    let response = await oauth.get("/oauth/token");
    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};

const PermissoesProfissional = async (id_profissional) => {
  try {
    let response = await oauth.get("/permissoes-profissional/colecao", {
      headers: { id_profissional },
    });

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};

const ListBirthday = async (page, limit, search, id_empresa) => {
  return { result: [], qtd: { count: 0 } };
};

const List = async (page, limit, search, id_empresa) => {
  try {
    let response = await oauth.get("/paginacao-residente/colecao", {
      headers: {
        startindex: page,
        limit: limit,
        search: search,
        id_empresa: id_empresa,
      },
    });

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};

const ListUnity = async (id_empresa) => {
  try {
    let response = null;
    let result = [];

    response = await oauth.get("/unidade-empresa/colecao", {
      headers: {
        id_empresa: id_empresa,
      },
    });

    if (response) result = response.data;

    return result;
  } catch (error) {
    return error;
  }
};

const ListEmployee = async (id_empresa) => {
  try {
    let response = null;
    let result = [];

    response = await oauth.get("/profissional-empresa/colecao", {
      headers: {
        id_empresa: id_empresa,
      },
    });

    if (response) result = response.data;

    return result;
  } catch (error) {
    return error;
  }
};

const ListResidentFamily = async (page, limit, search, id_pessoa_residente) => {
  try {
    let response = await oauth.get("/paginacao-familiar/colecao", {
      headers: {
        startindex: page,
        limit: limit,
        search: search,
        id_pessoa_residente: id_pessoa_residente,
      },
    });

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};

const ListResidentWorkPlan = async (
  page,
  limit,
  search,
  id_pessoa_residente
) => {
  try {
    let response = await oauth.get("/paginacao-plano-de-trabalho/colecao", {
      headers: {
        startindex: page,
        limit: limit,
        search: search,
        id_pessoa_residente: id_pessoa_residente,
      },
    });

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};

const Show = async (id, id_empresa) => {
  try {
    let response = await oauth.get("/paginacao-residente", {
      headers: {
        id,
        id_empresa,
      },
    });

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};

const ShowResidentFamily = async (id, id_pessoa_residente) => {
  try {
    let response = await oauth.get("/paginacao-familiar", {
      headers: {
        id,
        id_pessoa_residente,
      },
    });

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};

const ShowResidentWorkPlan = async (id, id_pessoa_residente) => {
  try {
    let response = await oauth.get("/paginacao-plano-de-trabalho", {
      headers: {
        id,
        id_pessoa_residente,
      },
    });

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};

const Create = async ({
  // dados pessoais
  nome,
  cpf,
  rg,
  genero,
  nacionalidade,
  estado_civil,
  dt_nascimento,
  observacoes,

  // contato
  email,
  telefone1,
  telefone2,

  // endereco
  cep,
  logradouro,
  bairro,
  numero,
  estado,
  cidade,
  complemento,

  // residencia
  id_unidade,
}) => {
  try {
    let response = await oauth.post("/residente", {
      // dados pessoais
      nome,
      cpf,
      rg,
      genero,
      nacionalidade,
      estado_civil,
      dt_nascimento,
      observacoes,

      // contato
      email,
      telefone1,
      telefone2,

      // endereco
      cep,
      logradouro,
      bairro,
      numero,
      estado,
      cidade,
      complemento,

      // residencia
      id_unidade,

      // oauth
      status: "atv",
    });

    const result = response.data;

    return result;
  } catch (error) {
    return error;
  }
};

const CreateResidentFamily = async ({
  // dados pessoais
  nome,
  cpf,
  rg,
  genero,
  nacionalidade,
  estado_civil,
  dt_nascimento,
  observacoes,

  // contato
  email,
  telefone1,
  telefone2,

  // endereco
  cep,
  logradouro,
  bairro,
  numero,
  estado,
  cidade,
  complemento,

  // parentesco
  parentesco,

  // residente
  id_pessoa_residente,
}) => {
  try {
    let response = await oauth.post("/familiar", {
      // dados pessoais
      nome,
      cpf,
      rg,
      genero,
      nacionalidade,
      estado_civil,
      dt_nascimento,
      observacoes,

      // contato
      email,
      telefone1,
      telefone2,

      // endereco
      cep,
      logradouro,
      bairro,
      numero,
      estado,
      cidade,
      complemento,

      // parentesco
      parentesco,

      // residente
      id_pessoa_residente,

      // oauth
      status: "atv",
    });

    const result = response.data;

    return result;
  } catch (error) {
    return error;
  }
};

const CreateResidentWorkPlan = async (data) => {
  try {
    let response = await oauth.post("/plano-de-trabalho", data);

    const result = response.data;

    return result;
  } catch (error) {
    return error;
  }
};

const Update = async (data) => {};

const util = {
  UserData,
  UserInfo,
  PermissoesProfissional,
  List,
  ListUnity,
  ListEmployee,
  ListResidentFamily,
  ListResidentWorkPlan,
  ListBirthday,
  Show,
  ShowResidentFamily,
  ShowResidentWorkPlan,
  Create,
  CreateResidentFamily,
  CreateResidentWorkPlan,
  Update,
};

export default util;
