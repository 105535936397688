import { oauth } from "../../../services/oauth";

const UserInfo = async (data) => {
  try {
    let response = await oauth.get("/oauth/token");
    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};

const List = async (page, limit, search, id_empresa) => {
  return { result: [], qtd: { count: 0 } };
};

const ListEmployee = async (page, limit, search, id_empresa) => {
  try {
    let response = await oauth.get("/paginacao-profissional/colecao", {
      headers: {
        startindex: page,
        limit: limit,
        search: search,
        id_empresa: id_empresa,
      },
    });

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};

const ListOccupations = async (page, limit, search, id_empresa) => {
  try {
    let response = await oauth.get("/paginacao-funcao-empresa/colecao", {
      headers: {
        startindex: page,
        limit: limit,
        search: search,
        id_empresa: id_empresa,
      },
    });

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};

const ListOccupationsPermission = async (id_ocupacao, id_empresa) => {
  try {
    let response = await oauth.get("/funcao-permissao/colecao", {
      headers: {
        id_ocupacao,
        id_empresa,
      },
    });

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};

const ListUnit = async (page, limit, search, id_empresa) => {
  try {
    let response = await oauth.get("/paginacao-unidade/colecao", {
      headers: {
        startindex: page,
        limit: limit,
        search: search,
        id_empresa: id_empresa,
      },
    });

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};

const ListMedicament = async (page, limit, search, id_empresa) => {
  return { result: [], qtd: { count: 0 } };
};

const ListProduct = async (page, limit, search, id_empresa) => {
  return { result: [], qtd: { count: 0 } };
};

const ListUnity = async (id_empresa) => {
  try {
    let response = null;
    let result = [];

    response = await oauth.get("/unidade-empresa/colecao", {
      headers: {
        id_empresa: id_empresa,
      },
    });

    if (response) result = response.data;

    return result;
  } catch (error) {
    return error;
  }
};

const ListFunctions = async (id_empresa) => {
  try {
    let response = null;
    let result = [];

    response = await oauth.get("/funcao-empresa/colecao", {
      headers: {
        id_empresa: id_empresa,
      },
    });

    if (response) result = response.data;

    return result;
  } catch (error) {
    return error;
  }
};

const Show = async (id, id_empresa) => {};

const ShowOccupations = async (id_ocupacao, id_empresa) => {
  try {
    let response = await oauth.get("/paginacao-funcao-empresa", {
      headers: {
        id_ocupacao,
        id_empresa,
      },
    });

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};

const ShowEmployee = async (id, id_empresa) => {
  try {
    let response = await oauth.get("/paginacao-profissional", {
      headers: {
        id,
        id_empresa,
      },
    });

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};

const ShowUnit = async (id, id_empresa) => {
  try {
    let response = await oauth.get("/paginacao-unidade", {
      headers: {
        id,
        id_empresa,
      },
    });

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};

const CreateEmployee = async (data) => {
  try {
    let response = await oauth.post("/profissional", data);

    const result = response.data;

    return result;
  } catch (error) {
    return error;
  }
};

const CreateOccupations = async (data) => {
  try {
    let response = await oauth.post("/funcao", data);

    const result = response.data;

    return result;
  } catch (error) {
    return error;
  }
};

const CreateUnit = async ({
  // unidade
  cnpj,
  razao_social,
  nome_fantasia,
  registro_estadual,
  registro_municipal,
  dt_fundacao,
  observacoes,

  // contato
  telefone1,
  telefone2,
  email,

  // endereco
  cep,
  logradouro,
  numero,
  bairro,
  estado,
  cidade,
  complemento,

  // empresa
  id_empresa,
}) => {
  try {
    let response = await oauth.post("/unidade", {
      // unidade
      cnpj,
      razao_social,
      nome_fantasia,
      registro_estadual,
      registro_municipal,
      dt_fundacao,
      observacoes,

      // contato
      telefone1,
      telefone2,
      email,

      // endereco
      cep,
      logradouro,
      numero,
      bairro,
      estado,
      cidade,
      complemento,

      // empresa
      id_empresa,
    });

    const result = response.data;

    return result;
  } catch (error) {
    return error;
  }
};

const CreateOccupationsPermission = async (data) => {
  try {
    let response = await oauth.post("/funcao-permissao", data);

    const result = response.data;

    return result;
  } catch (error) {
    return error;
  }
};

const Update = async (data) => {};

const DeleteOccupationsPermission = async (id) => {
  try {
    let response = await oauth.delete(`/funcao-permissao/${id}`);

    const result = response.data;

    return result;
  } catch (error) {
    return error;
  }
};

const util = {
  UserInfo,
  List,
  ListEmployee,
  ListOccupations,
  ListOccupationsPermission,
  ListUnit,
  ListMedicament,
  ListProduct,
  ListUnity,
  ListFunctions,
  Show,
  ShowOccupations,
  ShowEmployee,
  ShowUnit,
  CreateEmployee,
  CreateOccupations,
  CreateUnit,
  CreateOccupationsPermission,
  Update,
  DeleteOccupationsPermission,
};

export default util;
