// react
import React, { useState, useEffect } from "react";

// router
import { useNavigate } from "react-router-dom";

// material
import { Box, Paper, Grid, Typography, Link } from "@mui/material";

// components
import { LoadingSquare } from "../../../../components/app/Loading/Square";
import { OAuthDialog } from "../../../../components/app/Dialog/OAuth";
import { FormControl } from "../../../../components/app/Form/FormControl";
import { Controls } from "../../../../components/app/Form/Controls";
import { ButtonMain } from "../../../../components/app/Button/Main";

// contexts
import { useAuth } from "../../../../contexts/AuthProvider/useAuth";

// style
import { classes, Page } from "../../styles";

export const FamilyOAuth = () => {
  // router
  const navigate = useNavigate();

  // context
  const auth = useAuth();

  // state
  const [open, setOpen] = useState(false);
  const [messageError, setMessageError] = useState("");
  const [loading, setLoading] = useState(true);

  // state-form
  const [login, setLogin] = useState("");
  const [password, setPassWord] = useState("");

  // effects
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);

  // functions
  const handleSubmit = async (event) => {
    event.preventDefault();

    setMessageError("");

    try {
      if (validate()) {
        let personType = "familiar";

        let response = await auth.authenticate(login, password, personType);

        if (response.error_message) {
          setMessageError(response.error_message);
        } else {
          navigate("/inicio");
        }
      } else {
        setMessageError("Por favor, corrija os campos em vermelho.");
      }
    } catch (error) {
      setMessageError("Ocorreu um erro inesperado na sessão.");
    }
  };

  const validate = () => {
    return true;
  };

  const handlePersonClickOpen = () => {
    setOpen(true);
  };

  const handlePersonClose = () => {
    setOpen(false);
  };

  return (
    <Page>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <Grid
          item
          xs={false}
          sm={false}
          md={7}
          className={classes.banner}
        ></Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={5}
          component={Paper}
          elevation={6}
          square
        >
          {loading ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
              }}
            >
              <LoadingSquare />
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
                overflow: "auto",
              }}
            >
              <Box
                sx={{
                  py: 6,
                  px: 4,
                  width: "100%",
                }}
              >
                <Box textAlign="center">
                  <Typography component="h1" variant="h4" fontWeight={500}>
                    Acesso do familiar
                  </Typography>
                  <Typography component="h2" variant="subtitle1" my={1}>
                    Outro tipo de perfil?{" "}
                    <Typography
                      component="span"
                      color="primary"
                      onClick={handlePersonClickOpen}
                      sx={{
                        cursor: "pointer",
                      }}
                    >
                      Clique aqui
                    </Typography>
                  </Typography>
                </Box>

                <Box
                  py={4}
                  sx={{
                    width: "100%",
                    maxWidth: "400px",
                    margin: "auto",
                  }}
                >
                  <FormControl>
                    <Controls.Input
                      label="Login de acesso"
                      id="login"
                      name="login"
                      type="text"
                      value={login}
                      length={150}
                      placeholder="Login de acesso"
                      onChange={(event) => setLogin(event.target.value)}
                      style={{ marginBottom: "1.25rem" }}
                    />

                    <Controls.Input
                      label="Senha de acesso"
                      id="password"
                      name="password"
                      type="password"
                      value={password}
                      length={150}
                      placeholder="Senha de acesso"
                      onChange={(event) => setPassWord(event.target.value)}
                      style={{ marginBottom: "1.25rem" }}
                    />

                    {messageError ? (
                      <Typography color="error" fontSize="11pt" mt={4}>
                        * {messageError}
                      </Typography>
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}
                  </FormControl>

                  <Box my={4}>
                    <ButtonMain
                      action={handleSubmit}
                      type="button"
                      text="Acessar"
                      style={{ width: "100%" }}
                    />
                  </Box>

                  <Box textAlign="center">
                    <Link
                      href="#"
                      sx={{
                        textDecoration: "none",
                      }}
                    >
                      Esqueci minha senha
                    </Link>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>

      <OAuthDialog open={open} onClose={handlePersonClose} />
    </Page>
  );
};
