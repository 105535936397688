import { Label, SelectField } from "../styles";

import { FormControl, MenuItem } from "@mui/material/";

export function Select(props) {
  const { label, id, name, value, items, style, onChange, whiteSpace } = props;

  return (
    <FormControl sx={{ width: "100%", marginBottom: 0 }}>
      <Label htmlFor={id}>{label}</Label>

      <SelectField
        name={name}
        value={value}
        onChange={onChange}
        id={id}
        sx={style}
        displayEmpty
      >
        {items.map(function (item, index) {
          return (
            <MenuItem
              key={index}
              value={item.value}
              sx={{
                whiteSpace: whiteSpace ? whiteSpace : "nowrap",
              }}
            >
              {item.title}
            </MenuItem>
          );
        })}
      </SelectField>
    </FormControl>
  );
}
