// material
import { styled } from "@mui/material/styles";

const PREFIX = "StylesSettings";

export const classes = {
  root: `${PREFIX}-root`,
  tab: `${PREFIX}-tab`,
  containerTab: `${PREFIX}-containerTab`,
  indicatorTab: `${PREFIX}-indicatorTab`,
  tabSelected: `${PREFIX}-tabSelected`,
  titleIcon: `${PREFIX}-titleIcon`,
};

export const StylesSettings = styled("div")(({ theme }) => ({
  [`.${classes.root}`]: {
    alignItems: "start !important",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  [`.${classes.tab}`]: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    borderRadius: ".25rem",
    [theme.breakpoints.up("md")]: {
      width: "185px !important",
      minWidth: "185px !important",
    },
  },
  [`.${classes.containerTab}`]: {
    [theme.breakpoints.up("md")]: {
      display: "flex !important",
      flexDirection: "column !important",
    },
    [theme.breakpoints.down("md")]: {
      flexWrap: "wrap !important",
    },
  },
  [`.${classes.indicatorTab}`]: {
    backgroundColor: "transparent !important",
  },
  [`.${classes.tabSelected}`]: {
    backgroundColor: `${theme.palette.background.default} !important`,
    color: `  ${
      theme.palette.mode === "light"
        ? theme.palette.primary.main
        : theme.palette.text.primary
    } !important`,
    borderRadius: ".25rem !important",
  },
}));

export const StylesMenuCalendar = styled("div")(({ theme }) => ({
  [`.${classes.titleIcon}`]: {
    height: "36px",
  },
}));
