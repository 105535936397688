// material
import { Box, useTheme } from "@mui/material";

export function TabPanel(props) {
  const theme = useTheme();

  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            mt: 3,
            p: 0,
            [theme.breakpoints.up("md")]: {
              mt: 0,
              pl: 3,
            },
          }}
        >
          {children}
        </Box>
      )}
    </Box>
  );
}

export const a11yProps = (index) => {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
};
