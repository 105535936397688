// react
import React, { useState } from "react";

// router
import { useParams, useNavigate } from "react-router-dom";

// material
import {
  Box,
  Typography,
  TableRow as MuiTableRow,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  Divider,
  List,
  ListItemText,
  ListItem,
  Avatar,
  ListItemAvatar,
} from "@mui/material";

// Icons
import AppsIcon from "@mui/icons-material/Apps";
import AssignmentIcon from "@mui/icons-material/Assignment";

// components
import {
  classesTable,
  StylesMuiTableCell as MuiTableCellBody,
} from "../../../../../../../../components/app/Table";

// util
import formatted from "../../../../../../../../util/formatted";

const data = [
  {
    slug: "preview",
    action: "preview",
    label: "Ver Detalhes",
    icon: AssignmentIcon,
  },
];

export const TableRow = (props) => {
  let { id } = useParams();

  const navigate = useNavigate();

  // props
  const { row } = props;

  // states
  const [open, setOpen] = useState(false);

  // functions
  const handleChangeDialog = () => {
    setOpen(!open);
  };

  const goPage = (id_plano_de_trabalho, slug, action) => {
    let id_pessoa_residente = id;

    let url = "";

    switch (action) {
      case "preview":
        url = `/residente/plano-de-trabalho/${id_plano_de_trabalho}?residente=${id_pessoa_residente}`;
        break;

      default:
        break;
    }

    if (url !== "") {
      navigate(url);
    }
  };

  return (
    <React.Fragment>
      <MuiTableRow>
        {/* Nome */}
        <MuiTableCellBody className={classesTable.root} align="left">
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            width="100%"
          >
            <Box component="div" className={classesTable.containerCellActive}>
              <Typography
                sx={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  width: "280px",
                  display: "block",
                  overflow: "hidden",
                }}
              >
                {row.titulo}
              </Typography>
            </Box>
          </Box>
        </MuiTableCellBody>

        {/* data */}
        <MuiTableCellBody className={classesTable.root} align="left">
          <Box
            component="div"
            className={classesTable.containerCell}
            sx={{
              justifyContent: "center",
              width: 140,
            }}
          >
            {formatted.objDate(row.created_at)}
          </Box>
        </MuiTableCellBody>

        {/* Actions */}
        <MuiTableCellBody className={classesTable.root} align="left">
          <Box
            component="div"
            className={classesTable.containerCell}
            sx={{
              backgroundColor: "transparent !important",
              justifyContent: "center",
              width: 80,
            }}
          >
            <Tooltip title="Menu">
              <IconButton
                onClick={handleChangeDialog}
                color="secondary"
                sx={{ p: "10px" }}
                aria-label="Novo"
              >
                <AppsIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </MuiTableCellBody>
      </MuiTableRow>

      <Dialog onClose={handleChangeDialog} open={open}>
        <DialogTitle>
          <Box textAlign="center">Menu</Box>
        </DialogTitle>
        <Divider />
        <List sx={{ py: 2, minWidth: "280px" }}>
          {data.map(function (item, index) {
            return (
              <ListItem
                button
                autoFocus
                onClick={() =>
                  goPage(
                    row.id_plano_de_trabalho_individual_pessoa,
                    item.slug,
                    item.action
                  )
                }
                key={index}
              >
                <ListItemAvatar>
                  <Avatar
                    sx={{
                      bgcolor: (theme) => theme.palette.primary.dark,
                    }}
                  >
                    <item.icon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={item.label} />
              </ListItem>
            );
          })}
        </List>
        <Divider />
        <Box textAlign="center" my={2}>
          <Typography
            onClick={handleChangeDialog}
            variant="body1"
            sx={{ cursor: "pointer" }}
          >
            Voltar
          </Typography>
        </Box>
      </Dialog>
    </React.Fragment>
  );
};
