let baseURL = "";

switch (process.env.NODE_ENV) {
  case "production":
    baseURL = "https://api-sralerta.tmp.pixaflow.com";
    break;

  case "development":
    baseURL = "http://localhost:8686";
    // baseURL = "https://api-sralerta.tmp.pixaflow.com";
    break;

  default:
    baseURL = "https://api-sralerta.tmp.pixaflow.com";
    break;
}

export default baseURL;
