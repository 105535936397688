import { styled, AppBar as MuiAppBar } from "@mui/material";

export const AppBar = styled(MuiAppBar)(({ theme }) => ({
  backgroundColor: `${theme.palette.bar.main} !important`,
  borderBottom: `1px solid ${theme.palette.bar.light}`,
  backgroundImage: "none !important",
  height: "65px",
  boxShadow: "none",

  "& div.MuiToolbar-root": {
    height: "65px",
    paddingLeft: "24px",
    paddingRight: "24px",
  },
}));
