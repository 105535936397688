import React, { createContext } from "react";

import {
  setUserLocalStorage,
  removeUserLocalStorage,
  LoginRequest,
} from "./util";

export const AuthContext = createContext();

export const AppAuthProvider = ({ children }) => {
  async function authenticate(login, password, person) {
    const response = await LoginRequest(login, password, person);

    if (response.error_message) {
      return response;
    } else {
      let { token, user_data } = response;

      const payload = {
        authorization: { token },
      };

      setUserLocalStorage(payload, user_data);

      return response;
    }
  }

  function logout() {
    removeUserLocalStorage();
  }

  return (
    <AuthContext.Provider
      value={{
        authenticate,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
