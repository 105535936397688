// react
import React, { useState, useEffect, useCallback } from "react";

// material
import { Box, Divider, Typography } from "@mui/material";

// icons
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

// components
import { MenuDrawer } from "./components/MenuDrawer";
import { Drawer, DrawerMobile } from "./components/Drawer";
import { AddDialog } from "../Dialog/Add";
import { Avatar } from "../Avatar";
import { BottomNavigationMain } from "../BottomNavigation/Main";
import { AppBarMain } from "../AppBar/Main";

// util
import util from "./util";

// style
import { classes, ContentPage, ContentMenu } from "./styles";

// api
import baseURL from "../../../config/app";

export const Sidebar = (props) => {
  // state
  const [mobileOpen, setMobileOpen] = useState(false);
  const [menuAdd, setMenuAdd] = useState(false);
  const [user, setUser] = useState(null);

  // effects
  const load = useCallback(async () => {
    let unmounted = false;

    let result = util.UserData();
    result.foto = baseURL + result.foto;

    if (!unmounted) setUser(result);

    return () => (unmounted = true);
  }, []);

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) load();

    return () => (unmounted = true);
  }, [load]);

  // functions
  const toggleMobileMenu = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleAddClick = () => {
    setMenuAdd(true);
  };

  const handleAddClose = () => {
    setMenuAdd(false);
  };

  return user ? (
    <React.Fragment>
      <ContentPage>
        <Box className={classes.content}>
          <Box>
            <Drawer
              classes={{
                root: classes.drawerRoot,
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open={true}
            >
              <ContentMenu>
                <MenuDrawer open={true} />
              </ContentMenu>
            </Drawer>

            <DrawerMobile
              classes={{
                root: classes.drawerRoot,
                paper: classes.drawerPaper,
              }}
              variant="temporary"
              open={mobileOpen}
              onClose={toggleMobileMenu}
              ModalProps={{
                keepMounted: true,
              }}
            >
              <Box textAlign="end" my={1}>
                <IconButton
                  onClick={toggleMobileMenu}
                  edge="start"
                  sx={{ mr: 1, ml: 0 }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
              <Divider
                sx={{
                  marginBottom: 1,
                }}
              />

              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                my={3}
              >
                <Avatar
                  name={user.nome}
                  picture={user.foto}
                  width="62px"
                  height="62px"
                />
                <Typography component="h1" variant="h6" mt={2}>
                  {user.nome}
                </Typography>
              </Box>
              <ContentMenu>
                <MenuDrawer open={true} />
              </ContentMenu>
            </DrawerMobile>
          </Box>

          <Box width="100%" sx={{ overflowX: "hidden" }}>
            <AppBarMain
              action={handleAddClick}
              toggle={toggleMobileMenu}
              profile={user}
            />

            <Box className={classes.page}>
              <Box className={classes.pageContainer} id="page">
                {props.children}
              </Box>
            </Box>

            <BottomNavigationMain />
          </Box>
        </Box>

        <AddDialog open={menuAdd} onClose={handleAddClose} />
      </ContentPage>
    </React.Fragment>
  ) : (
    <React.Fragment></React.Fragment>
  );
};
