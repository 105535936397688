// react
import React, { useState, useEffect, useCallback } from "react";

// router
import { useSearchParams, useNavigate } from "react-router-dom";

// material ui
import { Box, Typography, Tooltip } from "@mui/material";

// icons
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

// components
import { Sidebar } from "../../../../../../components/app/Sidebar";
import { Table } from "../../../../../../components/app/Table";
import { LoadingSquare } from "../../../../../../components/app/Loading/Square";
import { ContentPaper } from "../../../../../../components/app/Content/Paper";
import { ContentMain } from "../../../../../../components/app/Content/Main";
import { Scrollbar } from "../../../../../../components/app/Scrollbar";
import { TitleMain } from "../../../../../../components/app/Title/Main";

// components page
import { TableRow } from "./components/TableRow";

// functions
import util from "../../../util";

// assets
import imgNoTask from "../../../../../../assets/img/no-task.png";

// table
const tableColumns = [
  { title: "Título", align: "left" },
  { title: "Grupo", align: "center", size: "200px" },
  { title: "Permitir", align: "center", size: "180px" },
];

export const OccupationPermission = () => {
  // router
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  // states
  const [occupation, setOccupation] = useState({});
  const [row, setRow] = useState(null);

  // effects
  const load = useCallback(async () => {
    let unmounted = false;

    setRow(null);

    setTimeout(async () => {
      const id_ocupacao = searchParams.get("ocupacao");

      if (id_ocupacao) {
        await util.UserInfo().then(async (user_response) => {
          let { result } = await user_response;

          if (result.id_gestor) {
            const { id_empresa } = result;

            await util
              .ShowOccupations(id_ocupacao, id_empresa)
              .then(async (response) => {
                const { result } = response;

                setOccupation(result);

                if (result) {
                  await util
                    .ListOccupationsPermission(id_ocupacao, id_empresa)
                    .then(async (response) => {
                      let { result } = response;

                      if (!unmounted) {
                        setRow(result);
                      }
                    });
                } else {
                  alert(
                    "Nenhum registro encontrado. Tente novamente mais tarde. Caso o problema persista contate nossa equipe."
                  );
                  navigate(`/residencia?type=apps-occupation`);
                }
              });
          } else {
            alert(
              "Nenhum registro encontrado. Tente novamente mais tarde. Caso o problema persista contate nossa equipe."
            );
            navigate(`/residencia?type=apps-occupation`);
          }
        });
      } else {
        alert(
          "Nenhum registro encontrado. Tente novamente mais tarde. Caso o problema persista contate nossa equipe."
        );
        navigate(`/residencia?type=apps-occupation`);
      }
    }, 500);

    return () => (unmounted = true);
  }, [navigate, searchParams]);

  useEffect(() => {
    let unmounted = false;

    if (!unmounted) load();

    return () => (unmounted = true);
  }, [load]);

  // redirect
  const goList = () => {
    navigate(`/residencia?type=apps-occupation`);
  };

  return (
    <Sidebar>
      {row ? (
        <ContentMain>
          <Box mb={3}>
            <Box
              display="flex"
              sx={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box width="100%"></Box>

              <Box
                sx={{
                  display: "flex",
                }}
              >
                <Tooltip title="Voltar">
                  <IconButton
                    onClick={goList}
                    color="secondary"
                    sx={{ p: "10px" }}
                    aria-label="Voltar"
                  >
                    <ArrowBackIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </Box>

          <ContentPaper>
            <Box>
              <TitleMain>{occupation.titulo}</TitleMain>
            </Box>

            {row.length > 0 ? (
              <Box minHeight="100vh">
                <Scrollbar>
                  <Table
                    columns={tableColumns}
                    component={TableRow}
                    rows={row}
                    slug=""
                    method=""
                    refreshList={load}
                  />
                </Scrollbar>
              </Box>
            ) : (
              <Box
                py={4}
                px={2}
                textAlign="center"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Box
                    mb={1.5}
                    component="img"
                    src={imgNoTask}
                    sx={{ maxHeight: "70px" }}
                  />

                  <Typography
                    component="h3"
                    variant="h5"
                    fontWeight={600}
                    color="secondary"
                  >
                    Nenhuma permissão encontrada
                  </Typography>
                </Box>
              </Box>
            )}
          </ContentPaper>
        </ContentMain>
      ) : (
        <Box
          py={4}
          px={2}
          textAlign="center"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoadingSquare />
        </Box>
      )}
    </Sidebar>
  );
};
