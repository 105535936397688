// react
import React from "react";

// style
import { Label, InputField, TextAreaField } from "../styles";

export function Input(props) {
  const { label, id, name, type, value, length, placeholder, onChange, style } =
    props;

  return (
    <React.Fragment>
      <Label htmlFor={id}>{label}</Label>
      <InputField
        id={id}
        name={name}
        type={type ? type : "text"}
        value={value}
        maxLength={length ? length : 255}
        placeholder={placeholder ? placeholder : ""}
        sx={style}
        onChange={onChange}
      />
    </React.Fragment>
  );
}

export function TextArea(props) {
  const { label, rows, value, name, id, placeholder, onChange, style } = props;

  return (
    <React.Fragment>
      <Label htmlFor={id}>{label}</Label>

      <TextAreaField
        multiline
        fullWidth
        rows={rows ? rows : 5}
        value={value}
        name={name}
        id={id}
        placeholder={placeholder ? placeholder : ""}
        onChange={onChange}
        sx={style}
      />
    </React.Fragment>
  );
}
