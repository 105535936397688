// react
import React from "react";

// material
import {
  Box,
  FormGroup,
  FormControlLabel,
  Checkbox as MuiCheckbox,
} from "@mui/material";

export function Checkbox(props) {
  const { name, label, value, onChange } = props;

  const convertToDefEventPara = (name, value) => ({
    target: {
      name,
      value,
    },
  });

  return (
    <Box mb={"0.75rem"}>
      <FormGroup>
        <FormControlLabel
          label={label}
          control={
            <MuiCheckbox
              name={name}
              checked={value}
              onChange={(e) =>
                onChange(convertToDefEventPara(name, e.target.checked))
              }
            />
          }
        />
      </FormGroup>
    </Box>
  );
}
