// react
import React, { useState, useEffect, useCallback } from "react";

// router
import { useNavigate } from "react-router-dom";

// material ui
import {
  Box,
  Paper,
  InputBase,
  Divider,
  Typography,
  TablePagination,
  Button,
  Tooltip,
  Table,
  TableBody,
} from "@mui/material";

// icons
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

// components
import { Avatar } from "../../../../../components/app/Avatar";
import { LoadingSquare } from "../../../../../components/app/Loading/Square";
import { ContentPaper } from "../../../../../components/app/Content/Paper";
import { Scrollbar } from "../../../../../components/app/Scrollbar";
import { TitleMain } from "../../../../../components/app/Title/Main";

// components page
import { TableRow } from "./components/TableRow";

// assets
import imgNoTask from "../../../../../assets/img/no-task.png";

// styled
import {
  StyledTableCell,
  StyledTableCellTitle,
  StyledTableRow,
} from "../../components/styled/Table";

// functions
// import util from "../../util";

// table
const tableColumns = [
  { title: "Nome", align: "left" },
  { title: "Ações", align: "center", size: "80px" },
];

export const Home = () => {
  // router
  const navigate = useNavigate();

  // states
  const [row, setRow] = useState(null);
  const [search, setSearch] = useState("");
  const [tempSearch, setTempSearch] = useState("");
  // const [messageError, setMessageError] = useState("");
  const [qtd, setQtd] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);

  // effects
  const load = useCallback(
    async () => {
      let unmounted = false;

      setRow(null);

      setTimeout(async () => {
        //   if (id_residente) {
        //     await util.UserInfo().then(async (userResponse) => {
        //       let { result } = await userResponse;

        //       if (result.id_gestor) {
        //         const { id_empresa } = result;

        //         await util.Show(id_residente, id_empresa).then(async (response) => {
        //           const { result } = response;

        //           let id_pessoa_residente = id_residente;

        //           if (result) {
        //             await util
        //               .ListResidentFamily(page, limit, search, id_pessoa_residente)
        //               .then(async (response) => {
        //                 let { result, qtd } = response;

        //                 let qtdPage = qtd ? qtd.count : 0;

        if (!unmounted) {
          // setRow(result);
          // setQtd(qtdPage);

          setRow({});
          setQtd(0);
        }
        //               });
        //           } else {
        //             alert(
        //               "Nenhum registro encontrado. Tente novamente mais tarde. Caso o problema persista contate nossa equipe."
        //             );
        //             navigate(`/residente`);
        //           }
        //         });
        //       } else {
        //         alert(
        //           "Nenhum registro encontrado. Tente novamente mais tarde. Caso o problema persista contate nossa equipe."
        //         );
        //         navigate(`/residente`);
        //       }
        //     });
        //   } else {
        //     alert(
        //       "Nenhum registro encontrado. Tente novamente mais tarde. Caso o problema persista contate nossa equipe."
        //     );
        //     navigate(`/residente`);
        //   }
      }, 500);

      return () => (unmounted = true);
    },
    [
      // navigate,
      // page,
      // limit,
      // search
    ]
  );

  useEffect(() => {
    let unmounted = false;

    if (!unmounted) load();

    return () => (unmounted = true);
  }, [load, page, limit, search]);

  // functions
  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value));

    setPage(1);
  };

  const handleChangeSearch = (event) => {
    setTempSearch(event.target.value);
  };

  const deleteSearch = () => {
    setTempSearch("");
    setSearch("");
    setPage(1);
  };

  const submitSearch = () => {
    setSearch(tempSearch);
    setPage(1);
  };

  // redirect
  const goCreate = () => {
    // navigate(`/residente/familiar/novo?residente=${id}`);
  };

  // redirect
  const goList = () => {
    navigate(`/residente`);
  };

  return (
    <Box>
      {row ? (
        <React.Fragment>
          <ContentPaper>
            <Box
              py={4}
              px={2}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar
                name={"Logoo"}
                picture={row.foto}
                width="62px"
                height="62px"
              />
            </Box>

            <Box mb={4}>
              <Box pb={4}>
                <TitleMain>Endereço</TitleMain>
                <Table sx={{ minWidth: "100%", pt: 2 }}>
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCellTitle align="left">
                        CEP
                      </StyledTableCellTitle>

                      <StyledTableCell align="right">
                        {row.cep ? row.cep : "Não informado"}
                      </StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                      <StyledTableCellTitle align="left">
                        Logradouro
                      </StyledTableCellTitle>

                      <StyledTableCell align="right">
                        {row.logradouro ? row.logradouro : "Não informado"}
                      </StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                      <StyledTableCellTitle align="left">
                        Bairro
                      </StyledTableCellTitle>

                      <StyledTableCell align="right">
                        {row.bairro ? row.bairro : "Não informado"}
                      </StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                      <StyledTableCellTitle align="left">
                        Número
                      </StyledTableCellTitle>

                      <StyledTableCell align="right">
                        {row.numero ? row.numero : "Não informado"}
                      </StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                      <StyledTableCellTitle align="left">
                        Estado
                      </StyledTableCellTitle>

                      <StyledTableCell align="right">
                        {row.estado ? row.estado : "Não informado"}
                      </StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                      <StyledTableCellTitle align="left">
                        Cidade
                      </StyledTableCellTitle>

                      <StyledTableCell align="right">
                        {row.cidade ? row.cidade : "Não informado"}
                      </StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                      <StyledTableCellTitle align="left">
                        Complemento
                      </StyledTableCellTitle>

                      <StyledTableCell align="right">
                        {row.complemento ? row.complemento : "Não informado"}
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </Box>
            </Box>
          </ContentPaper>
        </React.Fragment>
      ) : (
        <Box
          py={4}
          px={2}
          textAlign="center"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoadingSquare />
        </Box>
      )}
    </Box>
  );
};
