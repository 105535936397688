// import { api } from "../../services/api";

async function VerifyToken(token) {
  // try {
  // let response = await api.get("/verify-token", {
  //   headers: { authorization: `Authorization ${token}` },
  // });
  // const data = response.data;
  // return data;

  return { success: true };
  // } catch (error) {
  //   return error;
  // }
}

export { VerifyToken };
