// react
import React, { useState, useEffect, useCallback } from "react";

// router
import { useParams, useNavigate } from "react-router-dom";

// material
import { Box, Table, TableBody, Tooltip } from "@mui/material";

// icons
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

// components
import { Sidebar } from "../../../../components/app/Sidebar";
import { Avatar } from "../../../../components/app/Avatar";
import { ContentMain } from "../../../../components/app/Content/Main";
import { ContentPaper } from "../../../../components/app/Content/Paper";
import { LoadingSquare } from "../../../../components/app/Loading/Square";
import { TitleMain } from "../../../../components/app/Title/Main";

// functions
import util from "../util";

// data
import { estado_itens } from "../../../../util/itens";
import formatted from "../../../../util/formatted";

import {
  StyledTableCell,
  StyledTableCellTitle,
  StyledTableRow,
} from "../components/styled/Table";

export const UnitShow = () => {
  let { id } = useParams();

  // router
  const navigate = useNavigate();

  const [row, setRow] = useState(null);

  // const [messageError, setMessageError] = useState("");

  //   effects
  const load = useCallback(async () => {
    let unmounted = false;

    setRow(null);

    setTimeout(async () => {
      if (id) {
        await util.UserInfo().then(async (userResponse) => {
          let { result } = await userResponse;

          if (result.id_gestor) {
            const { id_empresa } = result;

            await util.ShowUnit(id, id_empresa).then((response) => {
              const { result } = response;

              if (result) {
                result.estado =
                  formatted.listItem(result.estado, estado_itens) || "";

                result.dt_fundacao =
                  formatted.onlyDate(result.dt_fundacao) || "";

                if (!unmounted) {
                  setRow(result);
                }
              } else {
                alert(
                  "Nenhum registro encontrado. Tente novamente mais tarde. Caso o problema persista contate nossa equipe."
                );
                navigate(`/residencia?type=apps-unit`);
              }
            });
          } else {
            alert(
              "Nenhum registro encontrado. Tente novamente mais tarde. Caso o problema persista contate nossa equipe."
            );
            navigate(`/residencia?type=apps-unit`);
          }
        });
      } else {
        alert(
          "Nenhum registro encontrado. Tente novamente mais tarde. Caso o problema persista contate nossa equipe."
        );
        navigate(`/residencia?type=apps-unit`);
      }
    }, 500);

    return () => (unmounted = true);
  }, [id, navigate]);

  useEffect(() => {
    let unmounted = false;

    if (!unmounted) load();

    return () => (unmounted = true);
  }, [load, id]);

  // redirect
  const goList = () => {
    navigate(`/residencia?type=apps-unit`);
  };

  return (
    <Sidebar>
      {row ? (
        <ContentMain>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="end"
            width="100%"
            mb={3}
          >
            <Tooltip title="Voltar">
              <IconButton
                onClick={goList}
                color="secondary"
                sx={{ p: "10px", mr: 1 }}
                aria-label="Voltar"
              >
                <ArrowBackIcon />
              </IconButton>
            </Tooltip>
          </Box>

          <ContentPaper>
            <Box mb={4}>
              <Box pb={4}>
                <TitleMain>Sobre</TitleMain>

                <Table sx={{ minWidth: "100%", pt: 2 }}>
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCellTitle align="left">
                        Título
                      </StyledTableCellTitle>

                      <StyledTableCell align="right">
                        {row.titulo ? row.titulo : "Não informado"}
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </Box>

              <Box pb={4}>
                <TitleMain>Fiscal</TitleMain>

                <Table sx={{ minWidth: "100%", pt: 2 }}>
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCellTitle align="left">
                        CNPJ
                      </StyledTableCellTitle>

                      <StyledTableCell align="right">
                        {row.cnpj ? row.cnpj : "Não informado"}
                      </StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                      <StyledTableCellTitle align="left">
                        Razão social
                      </StyledTableCellTitle>

                      <StyledTableCell align="right">
                        {row.razao_social ? row.razao_social : "Não informado"}
                      </StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                      <StyledTableCellTitle align="left">
                        Nome fantasia
                      </StyledTableCellTitle>

                      <StyledTableCell align="right">
                        {row.nome_fantasia
                          ? row.nome_fantasia
                          : "Não informado"}
                      </StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                      <StyledTableCellTitle align="left">
                        Registro estadual
                      </StyledTableCellTitle>

                      <StyledTableCell align="right">
                        {row.registro_estadual
                          ? row.registro_estadual
                          : "Não informado"}
                      </StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                      <StyledTableCellTitle align="left">
                        Registro municipal
                      </StyledTableCellTitle>

                      <StyledTableCell align="right">
                        {row.registro_municipal
                          ? row.registro_municipal
                          : "Não informado"}
                      </StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                      <StyledTableCellTitle align="left">
                        Data da fundação
                      </StyledTableCellTitle>

                      <StyledTableCell align="right">
                        {row.dt_fundacao ? row.dt_fundacao : "Não informado"}
                      </StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                      <StyledTableCellTitle align="left">
                        Observações
                      </StyledTableCellTitle>

                      <StyledTableCell align="right">
                        {row.observacoes ? row.observacoes : "Não informado"}
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </Box>

              <Box pb={4}>
                <TitleMain>Endereço</TitleMain>

                <Table sx={{ minWidth: "100%", pt: 2 }}>
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCellTitle align="left">
                        CEP
                      </StyledTableCellTitle>

                      <StyledTableCell align="right">
                        {row.cep ? row.cep : "Não informado"}
                      </StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                      <StyledTableCellTitle align="left">
                        Logradouro
                      </StyledTableCellTitle>

                      <StyledTableCell align="right">
                        {row.logradouro ? row.logradouro : "Não informado"}
                      </StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                      <StyledTableCellTitle align="left">
                        Bairro
                      </StyledTableCellTitle>

                      <StyledTableCell align="right">
                        {row.bairro ? row.bairro : "Não informado"}
                      </StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                      <StyledTableCellTitle align="left">
                        Número
                      </StyledTableCellTitle>

                      <StyledTableCell align="right">
                        {row.numero ? row.numero : "Não informado"}
                      </StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                      <StyledTableCellTitle align="left">
                        Estado
                      </StyledTableCellTitle>

                      <StyledTableCell align="right">
                        {row.estado ? row.estado : "Não informado"}
                      </StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                      <StyledTableCellTitle align="left">
                        Cidade
                      </StyledTableCellTitle>

                      <StyledTableCell align="right">
                        {row.cidade ? row.cidade : "Não informado"}
                      </StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                      <StyledTableCellTitle align="left">
                        Complemento
                      </StyledTableCellTitle>

                      <StyledTableCell align="right">
                        {row.complemento ? row.complemento : "Não informado"}
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </Box>

              <Box pb={4}>
                <TitleMain>Contato</TitleMain>

                <Table sx={{ minWidth: "100%", pt: 2 }}>
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCellTitle align="left">
                        E-mail
                      </StyledTableCellTitle>

                      <StyledTableCell align="right">
                        {row.email ? row.email : "Não informado"}
                      </StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                      <StyledTableCellTitle align="left">
                        Celular
                      </StyledTableCellTitle>

                      <StyledTableCell align="right">
                        {row.telefone1 ? row.telefone1 : "Não informado"}
                      </StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                      <StyledTableCellTitle align="left">
                        Telefone
                      </StyledTableCellTitle>

                      <StyledTableCell align="right">
                        {row.telefone2 ? row.telefone2 : "Não informado"}
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </Box>
            </Box>
          </ContentPaper>
        </ContentMain>
      ) : (
        <Box
          py={4}
          px={2}
          textAlign="center"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoadingSquare />
        </Box>
      )}
    </Sidebar>
  );
};
