// react
import React, { useState, useEffect, useCallback } from "react";

// router
import { useSearchParams } from "react-router-dom";

// material
import { Box, Tabs, Tab } from "@mui/material";

// components
import { Sidebar } from "../../../../components/app/Sidebar";
import { ContentMain } from "../../../../components/app/Content/Main";
import { a11yProps, TabPanel } from "../../../../components/app/Tabs/TabPanel";

// components page
import { Details } from "./menu/Details";
import { MedicalRecords } from "./menu/MedicalRecords";
import { AffectiveRecord } from "./menu/AffectiveRecord";
import { WorkPlan } from "./menu/WorkPlan";
import { Family } from "./menu/Family";

// styles
import { classes, ContentVerticalTabs } from "../../styles";

// util
import util from "../util";
import util_permission from "../../../../util/allowed";

export const ResidentShow = () => {
  const [searchParams] = useSearchParams();

  // states
  const [menu, setMenu] = useState(0);
  const [menuTab, setMenuTab] = useState(null);

  // effects

  const load = useCallback(async () => {
    let unmounted = false;

    let result_user_data = util.UserData();

    let menu = [];

    if (result_user_data && result_user_data.gestor) {
      menu.push(
        {
          id: 0,
          code: "apps-details",
          label: "Informações Gerais",
          element: <Details />,
        },
        {
          id: 1,
          code: "apps-medical-records",
          label: "Prontuario Médico",
          element: <MedicalRecords />,
        },
        {
          id: 2,
          code: "apps-affective-records",
          label: "Prontuario afetivo",
          element: <AffectiveRecord />,
        },
        {
          id: 3,
          code: "apps-workplan",
          label: "Plano de trabalho",
          element: <WorkPlan />,
        },
        {
          id: 4,
          code: "apps-family",
          label: "Familiares",
          element: <Family />,
        }
      );
    } else {
      await util.UserInfo().then(async (user_response) => {
        let { result } = await user_response;

        if (result.id_profissional) {
          const { id_profissional } = result;

          await util
            .PermissoesProfissional(id_profissional)
            .then(async (response) => {
              let { result } = await response;

              if (!unmounted) {
                if (util_permission.allowed("6fbf3df28cc0", true, result)) {
                  menu.push({
                    id: 0,
                    code: "apps-details",
                    label: "Informações Gerais",
                    element: <Details />,
                  });
                }

                if (util_permission.allowed("6fbf3df28cc6", true, result)) {
                  menu.push({
                    id: 1,
                    code: "apps-medical-records",
                    label: "Prontuario Médico",
                    element: <MedicalRecords />,
                  });
                }

                if (util_permission.allowed("6fbf3df28c15", true, result)) {
                  menu.push({
                    id: 2,
                    code: "apps-affective-records",
                    label: "Prontuario afetivo",
                    element: <AffectiveRecord />,
                  });
                }

                if (util_permission.allowed("6fbf3df28c19", true, result)) {
                  menu.push({
                    id: 3,
                    code: "apps-workplan",
                    label: "Plano de trabalho",
                    element: <WorkPlan />,
                  });
                }

                if (util_permission.allowed("6fbf3df28c23", true, result)) {
                  menu.push({
                    id: 4,
                    code: "apps-family",
                    label: "Familiares",
                    element: <Family />,
                  });
                }
              }
            });
        }
      });
    }

    setMenuTab(menu);

    return () => (unmounted = true);
  }, []);

  useEffect(() => {
    let unmounted = false;

    if (!unmounted) load();

    return () => (unmounted = true);
  }, [load]);

  useEffect(() => {
    const redirect = searchParams.get("type");

    if (redirect) {
      var tab = 0;

      if (menuTab) {
        menuTab.map(function (item, index) {
          if (item.code === redirect) {
            tab = index;
            return true;
          } else {
            return false;
          }
        });
      }

      handleChangeMenu(() => {}, tab);
    }
  }, [searchParams, menuTab]);

  // functions
  const handleChangeMenu = (event, newValue) => {
    setMenu(newValue);
  };

  const clearType = () => {
    window.history.pushState({}, document.title, window.location.pathname);
  };

  return (
    <Sidebar>
      <ContentMain>
        <ContentVerticalTabs>
          <Box className={classes.root}>
            <Box>
              <Box>
                {menuTab ? (
                  <Tabs
                    orientation="horizontal"
                    value={menu}
                    onChange={handleChangeMenu}
                    aria-label="Settings Menu"
                    classes={{
                      root: classes.tab,
                      flexContainer: classes.containerTab,
                      indicator: classes.indicatorTab,
                    }}
                  >
                    {menuTab.map(function (item, index) {
                      return (
                        <Tab
                          key={index}
                          classes={{
                            selected: classes.tabSelected,
                          }}
                          label={item.label}
                          {...a11yProps(item.id)}
                          onClick={clearType}
                        />
                      );
                    })}
                  </Tabs>
                ) : (
                  <React.Fragment></React.Fragment>
                )}
              </Box>
            </Box>

            <Box width="100%">
              {menuTab ? (
                menuTab.map(function (item, index) {
                  return (
                    <TabPanel key={index} value={menu} index={item.id}>
                      {item.element}
                    </TabPanel>
                  );
                })
              ) : (
                <React.Fragment></React.Fragment>
              )}
            </Box>
          </Box>
        </ContentVerticalTabs>
      </ContentMain>
    </Sidebar>
  );
};
