// material ui
import { Button as MuiButton } from "@mui/material";
import { styled } from "@mui/material/styles";

export const FormButton = styled(MuiButton)(({ theme }) => ({
  textTransform: "capitalize",
  fontSize: "12pt",
  fontWeight: 400,

  borderRadius: "30px",
  outline: "none !important",

  padding: "7px 16px",
}));
