// react
import React, { useState, useEffect, useCallback } from "react";

// router
import { useNavigate } from "react-router-dom";

// material ui
import {
  Box,
  Paper,
  InputBase,
  Divider,
  Typography,
  TablePagination,
  Button,
  Tooltip,
} from "@mui/material";

// icons
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";

// components
import { Table } from "../../../../../components/app/Table";
import { LoadingSquare } from "../../../../../components/app/Loading/Square";
import { ContentPaper } from "../../../../../components/app/Content/Paper";
import { Scrollbar } from "../../../../../components/app/Scrollbar";

// components page
import { TableRow } from "./components/TableRow";

// functions
import util from "../../util";

// assets
import imgNoTask from "../../../../../assets/img/no-task.png";

// table
const tableColumns = [
  { title: "Nome", align: "left" },
  { title: "Profissão", align: "center", size: "180px" },
  { title: "Ações", align: "center", size: "80px" },
];

export const Employee = () => {
  // router
  const navigate = useNavigate();

  // states
  const [row, setRow] = useState(null);
  const [search, setSearch] = useState("");
  const [tempSearch, setTempSearch] = useState("");
  const [qtd, setQtd] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);

  // effects
  const load = useCallback(async () => {
    let unmounted = false;

    setRow(null);

    setTimeout(async () => {
      await util.UserInfo().then(async (user_response) => {
        let { result } = await user_response;

        const { id_empresa } = result;

        await util
          .ListEmployee(page, limit, search, id_empresa)
          .then(async (response) => {
            let { result, qtd } = response;

            let qtdPage = qtd ? qtd.count : 0;

            if (!unmounted) {
              setRow(result);
              setQtd(qtdPage);
            }
          });
      });
    }, 500);

    return () => (unmounted = true);
  }, [page, limit, search]);

  useEffect(() => {
    let unmounted = false;

    if (!unmounted) load();

    return () => (unmounted = true);
  }, [load, page, limit, search]);

  // functions
  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value));

    setPage(1);
  };

  const handleChangeSearch = (event) => {
    setTempSearch(event.target.value);
  };

  const deleteSearch = () => {
    setTempSearch("");
    setSearch("");
    setPage(1);
  };

  const submitSearch = () => {
    setSearch(tempSearch);
    setPage(1);
  };

  // redirect
  const goCreate = () => {
    navigate(`/residencia/profissional/novo`);
  };

  return (
    <Box>
      {row ? (
        <React.Fragment>
          <Box mb={3}>
            <Box
              display="flex"
              sx={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box width="100%">
                <Paper
                  sx={{
                    p: "6px 4px",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Pesquisar..."
                    inputProps={{
                      "aria-label": "Pesquisar...",
                    }}
                    value={tempSearch}
                    onChange={handleChangeSearch}
                    onKeyPress={(ev) => {
                      if (ev.key === "Enter") {
                        ev.preventDefault();
                        submitSearch();
                      }
                    }}
                  />

                  <IconButton
                    onClick={submitSearch}
                    type="submit"
                    sx={{ p: "10px" }}
                    aria-label="search"
                  >
                    <SearchIcon />
                  </IconButton>

                  {tempSearch !== "" ? (
                    <React.Fragment>
                      <Divider
                        sx={{ height: 28, m: 0.5 }}
                        orientation="vertical"
                      />

                      <IconButton
                        onClick={deleteSearch}
                        color="secondary"
                        sx={{ p: "10px" }}
                        aria-label="Delete"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </React.Fragment>
                  ) : (
                    <React.Fragment></React.Fragment>
                  )}
                </Paper>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  ml: 2,
                }}
              >
                <Tooltip title="Novo profissional">
                  <IconButton
                    onClick={goCreate}
                    color="secondary"
                    sx={{ p: "10px" }}
                    aria-label="Novo"
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </Box>
          <ContentPaper>
            {row.length > 0 ? (
              <Box minHeight="100vh">
                <Scrollbar>
                  <Box mb={4}>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      component="div"
                      count={qtd}
                      rowsPerPage={limit}
                      page={page - 1}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      labelRowsPerPage={"Linhas por página"}
                      labelDisplayedRows={({ from, to, count }) =>
                        `${from} - ${to} de ${count}`
                      }
                    />
                  </Box>

                  <Table
                    columns={tableColumns}
                    component={TableRow}
                    rows={row}
                    slug="residente/preview"
                    method="redirect"
                    refreshList={load}
                  />

                  <Box mt={4}>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      component="div"
                      count={qtd}
                      rowsPerPage={limit}
                      page={page - 1}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      labelRowsPerPage={"Linhas por página"}
                      labelDisplayedRows={({ from, to, count }) =>
                        `${from} - ${to} de ${count}`
                      }
                    />
                  </Box>
                </Scrollbar>
              </Box>
            ) : (
              <Box
                py={4}
                px={2}
                textAlign="center"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Box
                    mb={1.5}
                    component="img"
                    src={imgNoTask}
                    sx={{ maxHeight: "70px" }}
                  />

                  <Typography
                    component="h3"
                    variant="h5"
                    fontWeight={600}
                    color="secondary"
                  >
                    Nenhum profissional encontrado
                  </Typography>

                  <Button
                    type="button"
                    onClick={goCreate}
                    color="primary"
                    variant="contained"
                    startIcon={<AddIcon />}
                    sx={{
                      mt: 3,
                      whiteSpace: "nowrap",
                      fontWeight: 400,
                      fontSize: "11pt",
                      textTransform: "inherit",
                      boxShadow: "none",
                    }}
                  >
                    Novo profissional
                  </Button>
                </Box>
              </Box>
            )}
          </ContentPaper>
        </React.Fragment>
      ) : (
        <Box
          py={4}
          px={2}
          textAlign="center"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoadingSquare />
        </Box>
      )}
    </Box>
  );
};
