import axios from "axios";

import baseURL from "../../config/app";
import { getToken } from "../../util/oauth";

const token = getToken();

export const oauth = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    authorization: `Authorization ${token}`,
  },
});
