import { api } from "../../services/api";

export function setUserLocalStorage(payload, user_data) {
  localStorage.setItem("us-token", JSON.stringify(payload));
  localStorage.setItem("us-data", JSON.stringify(user_data));
}

export function removeUserLocalStorage() {
  localStorage.removeItem("us-token");
  localStorage.removeItem("us-data");
}

export async function LoginRequest(login, password, person) {
  try {
    const response = await api.get(`/oauth/${person}`, {
      headers: {
        login,
        password,
      },
    });

    const data = response.data;
    return data;
  } catch (error) {
    return null;
  }
}
