// react
import React from "react";

// material
import { Box } from "@mui/material";

// style
import { FormButton } from "./styles";

export function ButtonMain(props) {
  const { text, variant, type, action, color, style, icon } = props;

  return (
    <FormButton
      color={color || "primary"}
      variant={variant || "contained"}
      type={type ? type : "submit"}
      onClick={action ? action : () => {}}
      sx={style}
    >
      {icon ? icon : <React.Fragment></React.Fragment>}
      <Box>{text}</Box>
    </FormButton>
  );
}
