// react
import React, { useEffect, useState } from "react";

// material
import { Box, Typography, Hidden } from "@mui/material";

// icons
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import SettingsIcon from "@mui/icons-material/Settings";
import AddIcon from "@mui/icons-material/Add";

// components
import { ButtonMain } from "../../Button/Main";
import { AddDialog } from "../../Dialog/Add";

const IconButton = (props) => {
  const Icon = props.icon;
  const { action } = props;

  return (
    <ButtonMain
      variant="text"
      color="primary"
      action={action}
      text=""
      type="button"
      icon={
        <Icon
          sx={{
            marginRight: {
              sx: 0,
              sm: "0.25rem",
            },
            padding: 0,
            height: "1rem",
            width: "1rem",
          }}
        />
      }
      style={{
        marginLeft: {
          xs: 0,
          sm: 2,
        },
        minWidth: {
          xs: "30px",
          sm: "64px",
        },
        height: "30px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "8pt",
        textTransform: "uppercase",
        fontWeight: 500,
        lineHeight: 0,
        padding: "0px",
        boxShadow: "none",
        borderRadius: ".25rem",
      }}
    />
  );
};

export const BottomNavigationMain = () => {
  // state
  const [menuAdd, setMenuAdd] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [currentTime, setCurrentTime] = useState(new Date());

  // effects
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDate(new Date());
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  // functions
  const formatDate = (date) => {
    let dateString = new Date(date)
      .toLocaleString("pt-BR", {
        day: "numeric",
        month: "long",
      })
      .replace(/\//g, "/");

    return dateString;
  };

  const formatTime = (date) => {
    let dateString = new Date(date)
      .toLocaleString("pt-BR", {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      })
      .replace(/\//g, "/");

    return dateString;
  };

  const handleAddClick = () => {
    setMenuAdd(true);
  };

  const handleAddClose = () => {
    setMenuAdd(false);
  };

  return (
    <Box
      px={4}
      sx={{
        width: "100vw",
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        height: "38px",
        display: "flex",
        alignItems: "center",
        backgroundColor: (theme) => `${theme.palette.bar.main} !important`,
        borderTop: (theme) => `1px solid ${theme.palette.bar.light}`,
      }}
    >
      <ButtonMain
        action={() => {}}
        color="primary"
        variant="contained"
        text="Suporte"
        type="button"
        icon={
          <QuestionAnswerIcon
            sx={{
              marginRight: "0.25rem",
              padding: 0,
              height: "1rem",
              width: "1rem",
            }}
          />
        }
        style={{
          height: "30px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "8pt",
          textTransform: "uppercase",
          fontWeight: 500,
          lineHeight: 0,
          padding: "0px 16px",
          boxShadow: "none",
          borderRadius: ".25rem",
        }}
      />
      <Box flexGrow={1} />

      <Hidden smDown>
        <Typography
          component="span"
          variant="body2"
          textTransform="uppercase"
          fontSize="10pt"
          sx={{ color: (theme) => theme.palette.bar.contrastText }}
        >
          {formatDate(currentDate)}, {formatTime(currentTime)}{" "}
          <Typography
            component="span"
            variant="body2"
            textTransform="uppercase"
            fontSize="10pt"
            sx={{ color: (theme) => theme.palette.bar.contrastText }}
          >
            (UTC-3)
          </Typography>
        </Typography>
      </Hidden>

      <Hidden smUp>
        <IconButton action={handleAddClick} icon={AddIcon} />
        <Box mr={2} />
      </Hidden>

      <IconButton action={() => {}} icon={SettingsIcon} />

      <AddDialog open={menuAdd} onClose={handleAddClose} />
    </Box>
  );
};
