// react
import React, { useState, useEffect, useCallback } from "react";

// router
import { useParams, useNavigate } from "react-router-dom";

// material
import { Box, Tooltip, Button, Typography } from "@mui/material";

// icons
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

// components
import { ContentPaper } from "../../../../../../components/app/Content/Paper";
import { LoadingSquare } from "../../../../../../components/app/Loading/Square";
import { TitleMain } from "../../../../../../components/app/Title/Main";
import { ContentPermission } from "../../../../../../components/app/Content/Permission";

// functions
import util from "../../../util";

// data
import {
  genero_itens,
  nacionalidade_itens,
  estado_civil_itens,
  estado_itens,
} from "../../../../../../util/itens";
import formatted from "../../../../../../util/formatted";

// assets
import imgNoTask from "../../../../../../assets/img/no-task.png";

export const MedicalRecords = () => {
  let { id } = useParams();

  // router
  const navigate = useNavigate();

  const [row, setRow] = useState(null);
  const [permissoes, setPermissoes] = useState([]);

  //   effects
  const load = useCallback(async () => {
    let unmounted = false;

    setRow(null);

    const goBackToTop = () => {
      alert(
        "Nenhum registro encontrado. Tente novamente mais tarde. Caso o problema persista contate nossa equipe."
      );
      navigate(`/residente`);
    };

    setTimeout(async () => {
      if (id) {
        await util.UserInfo().then(async (userResponse) => {
          let { result } = await userResponse;

          if (result.id_empresa) {
            const { id_empresa } = result;

            await util.Show(id, id_empresa).then((response) => {
              const { result } = response;

              if (result) {
                result.nacionalidade =
                  formatted.listItem(
                    result.nacionalidade,
                    nacionalidade_itens
                  ) || "";

                result.estado_civil =
                  formatted.listItem(result.estado_civil, estado_civil_itens) ||
                  "";

                result.estado =
                  formatted.listItem(result.estado, estado_itens) || "";

                result.genero =
                  formatted.listItem(result.genero, genero_itens) || "";

                result.dt_nascimento =
                  formatted.onlyDate(result.dt_nascimento) || "";

                if (!unmounted) {
                  setRow(result);
                }
              } else {
                goBackToTop();
              }
            });
          }

          if (result.id_profissional) {
            const { id_profissional } = result;

            await util
              .PermissoesProfissional(id_profissional)
              .then(async (response) => {
                let { result } = response;

                if (!unmounted) {
                  setPermissoes(result);
                }
              });
          }
        });
      } else {
        goBackToTop();
      }
    }, 500);

    return () => (unmounted = true);
  }, [id, navigate]);

  useEffect(() => {
    let unmounted = false;

    if (!unmounted) load();

    return () => (unmounted = true);
  }, [load, id]);

  // redirect
  const goList = () => {
    navigate(`/residente`);
  };

  const goPage = (slug) => {
    navigate(`/residente/${slug}/novo?residente=${id}`);
  };

  return (
    <Box>
      {row ? (
        <React.Fragment>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="end"
            width="100%"
            mb={3}
          >
            <Tooltip title="Voltar">
              <IconButton
                onClick={goList}
                color="secondary"
                sx={{ p: "10px", mr: 1 }}
                aria-label="Voltar"
              >
                <ArrowBackIcon />
              </IconButton>
            </Tooltip>
          </Box>

          <ContentPermission
            authorization="6fbf3df28cc7"
            accept_manager={true}
            permissions={permissoes}
          >
            <Box mb={4}>
              <ContentPaper height="auto">
                <Box mb={4}>
                  <Box>
                    <ContentPermission
                      authorization="6fbf3df28cc8"
                      accept_manager={false}
                      permissions={permissoes}
                    >
                      <Box textAlign="end">
                        <Button
                          variant="contained"
                          startIcon={<AddIcon />}
                          onClick={() => goPage("anamnese")}
                        >
                          Novo
                        </Button>
                      </Box>
                    </ContentPermission>

                    <TitleMain>Anamnese</TitleMain>

                    <Box textAlign="center" pt={4}>
                      <Box
                        mb={1.5}
                        component="img"
                        src={imgNoTask}
                        sx={{ maxHeight: "50px" }}
                      />

                      <Typography
                        component="h3"
                        variant="h6"
                        fontWeight={600}
                        color="secondary"
                      >
                        Nenhum registro encontrado
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </ContentPaper>
            </Box>
          </ContentPermission>

          <ContentPermission
            authorization="6fbf3df28cc9"
            accept_manager={true}
            permissions={permissoes}
          >
            <Box mb={4}>
              <ContentPaper height="auto">
                <Box mb={4}>
                  <Box pb={4}>
                    <ContentPermission
                      authorization="6fbf3df28c10"
                      accept_manager={false}
                      permissions={permissoes}
                    >
                      <Box textAlign="end">
                        <Button
                          variant="contained"
                          startIcon={<AddIcon />}
                          onClick={() => goPage("sinais-vitais")}
                        >
                          Novo
                        </Button>
                      </Box>
                    </ContentPermission>

                    <TitleMain>Sinais vitais</TitleMain>

                    <Box textAlign="center" pt={4}>
                      <Box
                        mb={1.5}
                        component="img"
                        src={imgNoTask}
                        sx={{ maxHeight: "50px" }}
                      />

                      <Typography
                        component="h3"
                        variant="h6"
                        fontWeight={600}
                        color="secondary"
                      >
                        Nenhum registro encontrado
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </ContentPaper>
            </Box>
          </ContentPermission>

          <ContentPermission
            authorization="6fbf3df28c11"
            accept_manager={true}
            permissions={permissoes}
          >
            <Box mb={4}>
              <ContentPaper height="auto">
                <Box mb={4}>
                  <Box pb={4}>
                    <ContentPermission
                      authorization="6fbf3df28c12"
                      accept_manager={false}
                      permissions={permissoes}
                    >
                      <Box textAlign="end">
                        <Button
                          variant="contained"
                          startIcon={<AddIcon />}
                          onClick={() => goPage("exame-fisico")}
                        >
                          Novo
                        </Button>
                      </Box>
                    </ContentPermission>

                    <TitleMain>Exame físico</TitleMain>

                    <Box textAlign="center" pt={4}>
                      <Box
                        mb={1.5}
                        component="img"
                        src={imgNoTask}
                        sx={{ maxHeight: "50px" }}
                      />

                      <Typography
                        component="h3"
                        variant="h6"
                        fontWeight={600}
                        color="secondary"
                      >
                        Nenhum registro encontrado
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </ContentPaper>
            </Box>
          </ContentPermission>

          <ContentPermission
            authorization="6fbf3df28c13"
            accept_manager={true}
            permissions={permissoes}
          >
            <Box mb={4}>
              <ContentPaper height="auto">
                <Box mb={4}>
                  <Box pb={4}>
                    <ContentPermission
                      authorization="6fbf3df28c14"
                      accept_manager={false}
                      permissions={permissoes}
                    >
                      <Box textAlign="end">
                        <Button
                          variant="contained"
                          startIcon={<AddIcon />}
                          onClick={() => goPage("evolucao")}
                        >
                          Novo
                        </Button>
                      </Box>
                    </ContentPermission>

                    <TitleMain>Evolução</TitleMain>

                    <Box textAlign="center" pt={4}>
                      <Box
                        mb={1.5}
                        component="img"
                        src={imgNoTask}
                        sx={{ maxHeight: "50px" }}
                      />

                      <Typography
                        component="h3"
                        variant="h6"
                        fontWeight={600}
                        color="secondary"
                      >
                        Nenhum registro encontrado
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </ContentPaper>
            </Box>
          </ContentPermission>
        </React.Fragment>
      ) : (
        <Box
          py={4}
          px={2}
          textAlign="center"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoadingSquare />
        </Box>
      )}
    </Box>
  );
};
