// react
import React, { useState, useEffect } from "react";

// router
import { useNavigate } from "react-router-dom";

// material
import { Box, Paper, Grid, Typography, Alert } from "@mui/material";

// icons
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";

// components
import { LoadingSquare } from "../../../../components/app/Loading/Square";
import { OAuthDialog } from "../../../../components/app/Dialog/OAuth";
import { FormControl } from "../../../../components/app/Form/FormControl";
import { Controls } from "../../../../components/app/Form/Controls";
import { StepForm } from "../../../../components/app/Step/Form";

// contexts
import { useAuth } from "../../../../contexts/AuthProvider/useAuth";

// style
import { classes, Page } from "../../styles";

const steps = [<AdminPanelSettingsIcon />];

export const EmployeeOAuth = () => {
  // router
  const navigate = useNavigate();

  // context
  const auth = useAuth();

  // state
  const [open, setOpen] = useState(false);
  const [messageError, setMessageError] = useState("");
  const [loading, setLoading] = useState(true);

  // state-form
  const [login, setLogin] = useState("");
  const [password, setPassWord] = useState("");

  // state-step
  const [activeStep, setActiveStep] = useState(0);
  const [actionStepButton, setActionStepButton] = useState(false);

  // effects
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);

  // functions
  const handleSubmit = async (event) => {
    event.preventDefault();

    setActionStepButton(true);
    setMessageError("");

    try {
      if (validate()) {
        let personType = "profissional";

        let response = await auth.authenticate(login, password, personType);

        if (response.error_message) {
          setActionStepButton(false);
          setMessageError(response.error_message);
        } else {
          navigate("/inicio");
        }
      } else {
        setActionStepButton(false);
        setMessageError("Por favor, corrija os campos em vermelho.");
      }
    } catch (error) {
      setActionStepButton(false);
      setMessageError("Ocorreu um erro inesperado na sessão.");
    }
  };

  const validate = () => {
    return true;
  };

  const handlePersonClickOpen = () => {
    setOpen(true);
  };

  const handlePersonClose = () => {
    setOpen(false);
  };

  return (
    <Page>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <Grid item xs={false} sm={false} md={7} className={classes.banner}>
          <Box className="sidebar-login">
            <Box component="ul" className="circles">
              <Box component="li"></Box>
              <Box component="li"></Box>
              <Box component="li"></Box>
              <Box component="li"></Box>
              <Box component="li"></Box>
              <Box component="li"></Box>
              <Box component="li"></Box>
              <Box component="li"></Box>
              <Box component="li"></Box>
              <Box component="li"></Box>
            </Box>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={5}
          component={Paper}
          elevation={6}
          square
        >
          {loading ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
              }}
            >
              <LoadingSquare />
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
                overflow: "auto",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  py: 4,
                  px: 2,
                }}
              >
                <Box
                  textAlign="center"
                  pt={4}
                  data-aos="zoom-in"
                  data-aos-duration="1000"
                >
                  <Typography component="h1" variant="h4" fontWeight={500}>
                    Acesso do Profissional
                  </Typography>
                  <Typography component="h2" variant="subtitle1" my={1}>
                    Outro tipo de perfil?{" "}
                    <Typography
                      component="span"
                      color="primary"
                      onClick={handlePersonClickOpen}
                      sx={{
                        cursor: "pointer",
                      }}
                    >
                      Clique aqui
                    </Typography>
                  </Typography>
                </Box>

                <Box
                  sx={{ width: "100%" }}
                  pt={4}
                  data-aos="zoom-in"
                  data-aos-duration="1000"
                >
                  <StepForm
                    steps={steps}
                    activeStep={activeStep}
                    setActiveStep={setActiveStep}
                    action={handleSubmit}
                    actionLabel="Fazer Login"
                    actionStepButton={actionStepButton}
                  >
                    <FormControl>
                      {activeStep === 0 ? (
                        <Grid
                          container
                          spacing={1}
                          sx={{
                            pt: 1,
                          }}
                        >
                          <Grid item xs={12}>
                            <Controls.Input
                              label="Login de acesso"
                              id="login"
                              name="login"
                              type="text"
                              value={login}
                              length={150}
                              placeholder="Login de acesso"
                              onChange={(event) => setLogin(event.target.value)}
                              style={{ marginBottom: "1.25rem" }}
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <Controls.Input
                              label="Senha de acesso"
                              id="password"
                              name="password"
                              type="password"
                              value={password}
                              length={150}
                              placeholder="Senha de acesso"
                              onChange={(event) =>
                                setPassWord(event.target.value)
                              }
                              style={{ marginBottom: "1.25rem" }}
                            />
                          </Grid>
                        </Grid>
                      ) : (
                        <React.Fragment></React.Fragment>
                      )}

                      {messageError ? (
                        <Box mt={2}>
                          <Alert severity="error">{messageError}</Alert>
                        </Box>
                      ) : (
                        <React.Fragment></React.Fragment>
                      )}
                    </FormControl>
                  </StepForm>
                </Box>
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>

      <OAuthDialog open={open} onClose={handlePersonClose} />
    </Page>
  );
};
